import './VirtualEnvironment.css';
import { Phone } from './Phone';
import { Panel } from './Panel';
import { useEffect, useState } from 'react';
import { getOnValue } from '../../data/firebase';
import {apiPost} from '../../data/apidata';
import FloorPlan from '../../components/FloorPlan/FloorPlan';

const VirtualEnvironment=({siteCode})=>{
    console.log("Virtual Environment", siteCode);
    if (siteCode === "coneygarlodge")  siteCode = "adctest";

    const [masterCalls, setMasterCalls] = useState([]);
    const [liveCalls, setLiveCalls] = useState([]);
    const [errors, setErrors] = useState([]);
    const [carer, setCarer] = useState("Room Unit");
    const [selectedRoomCode, setSelectedRoomCode] = useState("");
    
    let phoneDrag = false;
    let lastCall = "";

    useEffect(()=>{
        try { setLiveCalls(Object.values(masterCalls)); }
            catch(e){ setLiveCalls([]); }
    }, [masterCalls]);
    
    useEffect(()=>{ 
        if (siteCode!=="") getOnValue(siteCode + "PhoneLiveCalls", setMasterCalls, setErrors); 
    },[siteCode])

    function raiseCall(room, callCode, strCarer){
        if (room==="") {
            //alert("Please assign the unit to a room to raise a call.");
            return;
        }

        lastCall = room + "." + callCode;

        apiPost("/call/live/raise",
            {
                "userID":"",
                "siteID" : siteCode,
                "loginName" : "test@arquella.co.uk",
                "callType": callCode,
                "roomCode": room,
                "title": "",
                "comments": "",
                "carer": strCarer,
                "virtualCall": true
            },
            (d)=>{console.log(d)},
            (d)=>{console.log(d)}
        );
    }

    function liveCallType(roomCode){
        try {
            return liveCalls.filter(c=>c.room)[0].callType;    
        } catch(e) { return ""; }
    }

    function floorplanCallBack(staffLocationRoom, lastCall){
        if (staffLocationRoom!=="") raiseCall(staffLocationRoom, "5", carer); //raise call
        if (lastCall!=="") raiseCall(lastCall, "3", carer);
    }

    function raiseCallCallback(room, carer){
        raiseCall(room, "5", carer); //raise call
    }
    
    function clearCallCallback(room, carer){
        raiseCall(room, "3", carer);
    }

    return(
        <div style={{"display":"flex"}}>
            <div style={{"display":"flex", "flex-direction":"column", "justifyContent": "space-between", "padding": "10px"}}>
                <div className="phone landscape"><Panel calls={liveCalls} carer={carer} /></div>
                <div className="phone portrait"><Phone siteCode={siteCode} calls={liveCalls} carer={carer} /></div>
            
            </div>

            <div style={{"display":"flex", "flex-direction":"column", "padding": "10px", "width":"100%"}}>
                
                <FloorPlan 
                    //carer={carer} 
                    siteCode={siteCode}
                    raiseCall={raiseCallCallback}
                    clearCall={clearCallCallback}
                    staffMovementCallBack={floorplanCallBack} callsList={liveCalls}
                    lastCarerSelectedCallBack={setCarer}
                />

            </div>            

            {//<div className="phone portrait"><Phone siteCode={siteCode} calls={liveCalls} carer={carer} /></div>
} 

        </div>
    );
}

export default VirtualEnvironment;