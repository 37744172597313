import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import AuthProvider from './context/AuthProvider';
import { UserProvider } from './context/UserContext';
import ErrorBoundary from './ErrorHandler';

const appRoot = (process.env.NODE_ENV==="developmsent") ? "http://localhost:8180" : "https://c2api.arquel.la";
const appOrigin = (process.env.NODE_ENV==="developsment") ? "https://localhost:3000" : "https://connect.arquella.care";

  axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = "*";
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = appOrigin;
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = appRoot;
  axios.defaults.headers.post['Accepts'] = 'application/json; charset=UTF-8; text/plain; */*';
  axios.defaults.baseURL = appRoot;

  axios.interceptors.response.use(
    response => response,
    error => { 
      console.log("ResponseERRR", error);
      if ((error.code)&&(error.code==="ERR_NETWORK")) console.log("NETWORK ERROR")
      return Promise.reject(getUIMessage(error)); }
  );

  console.log("API ROOT", process.env.REACT_APP_API_ROOT);
  console.log("API ENVIRONMENT", process.env.NODE_ENV);

function getUIMessage(error){
  if (error.code == "ERR_NETWORK"){
    if (error.config.url === "/auth/login/") {
      return "Connect has been unable to authenticate you. Please contact Arquella to report this issue.";
    }
    else { 
      return "Connection error.  You are either offline or there is a problem connecting to the server."; 
    }
  }
  else { return error.toString(); }
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then(registration => {
      // Registration successful
      console.log('Service worker registered:', registration);
    })
    .catch(error => {
      // Registration failed
      console.error('Service worker registration failed:', error);
    });
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
