import React from 'react';

class ExporterCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        { name: 'John', age: 30, city: 'New York' },
        { name: 'Alice', age: 25, city: 'Los Angeles' },
        { name: 'Bob', age: 35, city: 'Chicago' },
      ],
    };
  }

  // Function to convert an array of objects to CSV format
  convertToCSV = (data) => {
    const csv = data.map((row) =>
      Object.values(row).map((value) => `"${value}"`).join(',')
    );
    return ['"Name","Age","City"', ...csv].join('\n');
  };

  // Function to trigger the CSV download
  exportToCSV = () => {
    const csvData = this.convertToCSV(this.state.data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'exported-data.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  render() {
    return (
      <div>
        <button onClick={this.exportToCSV}>Export to CSV</button>
      </div>
    );
  }
}

export default ExporterCSV;
