import React, { createContext, useState, useContext, useEffect } from 'react';

// Create the context
const UserContext = createContext();

// Create a custom hook to access the context
export const useUserContext = () => { return useContext(UserContext); };

// Create the context provider component
export const UserProvider = ({ children }) => {
  const [userSettings, setUserSettings] = useState(
    JSON.parse(localStorage.getItem('userSettings')) || defaultSiteValue
  );
  const [permissions, setPermissions] = useState(
    JSON.parse(localStorage.getItem('permissions')) || []
  );
  const [sites, setSites] = useState(
    JSON.parse(localStorage.getItem('sites')) || []
  );

  const hasPermission=(permissionName)=>{
    console.log("CHECKING FOR PERMISSION");
    if (permissions===null) return false;
    
    try {
      return permissions.filter(p=>p.permissionName===permissionName)[0].granted===1
    }
    catch(e){
      console.log("PERMISSINO ERROR");
      console.log(e);
      return false;
    }
  }

  // Update local storage whenever state changes
  useEffect(() => {
    localStorage.setItem('userSettings', JSON.stringify(userSettings));
    localStorage.setItem('permissions', JSON.stringify(permissions));
    localStorage.setItem('sites', JSON.stringify(sites));
  }, [userSettings, permissions]);

  return (
    <UserContext.Provider value={{ userSettings, setUserSettings, permissions, setPermissions, sites, setSites, hasPermission }}>
      {children}
    </UserContext.Provider>
  );
};

export const defaultSiteValue = [{
  siteID: 93,
  siteCode: "arquellacare"
}];

export default UserContext;
