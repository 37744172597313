import './VirtualEnvironment.css';
import IMG_ROOMUNIT from '../../images/Room unit.jpg';
import { raiseCall } from '../../functions/callHandler';
import SenseController from './SenseController';
import { Unit } from './Unit';
import { useEffect } from 'react';
import { select, drag, selectAll } from 'd3';
import IMG_NFC_PHONE from '../../images/Virtual/nfc_phone.png';

const RoomUnitSingle=({siteCode, selectedRoomCode, carer})=>{

    let phoneDrag = false;
    var width = 350;
    var height = 190;

    function raiseCalls(callType){
        raiseCall(siteCode, selectedRoomCode, callType, carer);
    }

    function callObject(svg, x, y, r, colour, callCode) {
        return svg.append("circle")
                    .attr("cx", x)       // x-coordinate of the center of the circle
                    .attr("cy", y)       // y-coordinate of the center of the circle
                    .attr("r", r)        // radius of the circle
                    .attr("fill", "none")//colour
                    .attr("pointer-events", "all") // Allow pointer events on this transparent circle
                    .on("click", function() { raiseCalls(callCode); });
    }


    useEffect(()=>{

        var svg = select("#svg_unit")
                    .attr("width", width)
                    .attr("height", height);

            svg.append("svg:image")
                .attr("xlink:href", IMG_ROOMUNIT)
                .attr("width", width)
                .attr("height", height);

            callObject(svg, 93, 93, 65, "orange", "2");
            callObject(svg, 207, 47, 18, "green", "5");
            callObject(svg, 207, 94, 18, "yellow", "4");
            callObject(svg, 207, 140, 18, "grey", "3");
            callObject(svg, 275, 71, 42, "orange", "1");
                    
    },[])
    return (
        <div style={{"display":"flex", "flex-direction":"row", "justifyContent": "space-around"}}>
            <SenseController siteCode={siteCode} room={selectedRoomCode} />
            <div className="space-y-6 m-5">
                
                <svg 
                    id="svg_unit" 
                    useMap="#workmap"
                    onDragOver={(e)=>{
                        if (phoneDrag===false) return;
                        phoneDrag = false;
                        raiseCalls("5");
                        console.log("dragOverEvent");
                        console.log(e);
                    }} 
                ></svg>
                
            </div>
            <img 
                src={IMG_NFC_PHONE}
                style={{height:"100px", width:"50px"}} 
                onDragStart={(d)=>{ phoneDrag = true;}}
                
            />
        </div>
    
    );

}


export default RoomUnitSingle;