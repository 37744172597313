import { useEffect, useState } from "react";
import { getSysSiteCallCount } from "../../functions/apiActions";
import SpinnerLoader from "../../components/SpinnerLoader";

export const Sys=()=>{

    const [callCount, setCallCount] = useState(null);
    const [callCountLoading, setCallCountLoading] = useState(false);

    useEffect(()=>{ 
        if (!callCountLoading) setCallCountLoading(true);
        getSysSiteCallCount({}, (d)=>{ 
            setCallCount(d) }, (e)=>{ console.log(e); });
    },[]);
    
    useEffect(()=>{ if (callCountLoading) setCallCountLoading(false); }, [callCount]);

    return(
      <div>
        {
        (callCountLoading) 
        ? <SpinnerLoader isLoading={callCountLoading} />
        : (callCount!==null)&&<ObjectArrayToTable objArr={callCount} />
        }
      </div>
    );
}

const ObjectArrayToTable=({objArr})=>{
    return(
        <table>
            <thead>
                <tr>
                 {Object.keys(objArr[0]).map((d,i)=><th>{d}</th>)}   
                </tr>
            </thead>
            <tbody>
                {objArr.map((d,i)=>
                {
                    
                    return(
                        <tr key={i}>
                            {Object.values(d).map((f,i)=><td>{f}</td>)}   
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}