import React, { useState } from "react";
import { SiteIntegrations } from "./SiteIntegrations";
import { PanelsAdmin } from "./PanelsAdmin";
import { useEffect } from "react";
import { getOnceValue } from "../../data/firebase";
import Settings from "../Settings";

export const SiteDetails = ({ site, siteID, siteCode, siteName }) => {
  console.log("sitedetails refresh", site);
  const tabs = ["Panels", "Room Units", "Phones", "Integrations"];
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div class="border-b border-gray-300">
{/*
      <div class="flex">
        {tabs.map((tab, i) => {
          return <button
            class={`py-2 px-4 text-gray-600 border-b-2 border-transparent hover:border-blue-500 focus:outline-none focus:border-blue-500 ${i === selectedTab ? "border-blue-500" : ""}`}
            onClick={() => { setSelectedTab(tab); }}
          >{tab}</button>
        })}
      </div>
*/}
      <Settings siteCode={siteCode} siteID={siteID} siteName={siteName} site={site}  />
      {/*}
      <div class="p-4">
        <div class="">
          {(selectedTab==="Panels")&&<PanelsAdmin site={site} />}
          {(selectedTab==="Integrations")&&<SiteIntegrations siteID={site.id} />}

        </div>
      </div>
      */}
    </div>

  );
};


