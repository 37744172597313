import React, { useEffect, useState } from 'react';
import { Flex, Grid, Icon } from '@tremor/react';
import { ResidentAvatar } from './components/ResidentAvatar';
import { FormInput } from './components/Input';
import { raiseError } from './functions/error_handling';
import { addEditResident, getResidentBasic } from './functions/apiActions';
import { LoadingWait } from './LoadingWait';
import { Icon_edit_circle } from './components/svgIcons';

export const BasicDetails = ({ resID, callBack, nameCallBack }) => {

  const [loading, setLoading] = useState(true);
  const [formData, setformData] = useState({
    firstName: "",
    surname: ""
  });

  const [isEditing, setIsEditing] = useState((resID === "-1") ? true : false);

  const handleEditClick = () => { setIsEditing(true); };
  
  const handleSaveClick = () => {
    updateResident();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setformData({
      ...formData,
      [name]: value,
    });
  };

  function updateResident() {
    const sendData =       {
      "userID": "me",
      "siteID": "93",
      "residentID": resID,
      "firstName": formData.firstName,
      "surname": formData.surname
    };
    console.log({sendData});
    addEditResident(sendData,
      (d) => {
        try {
          console.log(d);
          if (callBack) callBack(d[0].id);
          setIsEditing(false);
          //if (nameCallBack) nameCallBack(`${formData.firstName} ${formData.surname}`);
        } catch (e) {
          raiseError("", "updateResident", "Error in response", e.toString());
        }
      }
    );
  }

  
  useEffect(() => {
    
    if (resID !== "-1"){
      setIsEditing(false);
    } 
    else {
      setLoading(true);
    }
    getResidentBasic({"residentID" : resID}, 
      (d)=>{
        console.log("got the data");
        console.log(d);
        try {
          setformData({
            firstName: d[0].firstName,
            surname: d[0].surname
          });
        } catch(e){raiseError(e);}

        setLoading(false);
      }
    );
  }, [resID]);
  
  if (loading===true) return <LoadingWait />;

  return (
    <>
      <Flex justifyContent='start' className='mr-5'>
        <div className='mr-5'>
          {(resID !== "-1") && <ResidentAvatar firstName={formData.firstName} surname={formData.surname} resID={resID} size="m" />}
        </div>

        <Flex
          flexDirection='col'
          justifyContent='between'
        >
          {isEditing ? (
            <>
              <Grid numItemsMd={2} numItemsLg={2} className="gap-1 mt-6">
                <label>Name:</label>
                <FormInput
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  placeholder='First Name'
                  onChange={handleInputChange} />

                <label>Surname:</label>
                <FormInput
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="surname"
                  value={formData.surname}
                  placeholder="Surname"
                  onChange={handleInputChange} />
              </Grid>
              <Grid numItemsMd={2} numItemsLg={2} className="gap-1 mt-6">
                <button
                className='mt-auto'
                onClick={handleSaveClick}>Save</button>
              </Grid>
              
              
            </>
          ) : (
            <Flex justifyContent='start'>
              <div className="text-center">
                <h1 className="text-2xl font-semibold mt-4">{formData.firstName} {formData.surname}</h1>
                {/*<p className="text-gray-600">Web Developer</p>*/}
              </div>
              <button className='mt-auto' onClick={handleEditClick}><Icon_edit_circle /></button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );

};
