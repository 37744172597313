export function groupBy (xs, key) {
  try {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  catch (e) { return xs; }
};


export function groupKeysBySubKey(obj, subKey) {
  return Object.entries(obj).reduce((result, [key, value]) => {
    const subKeyValue = value[subKey];
  
    if (!result[subKeyValue]) {
      result[subKeyValue] = {};
    }
  
    result[subKeyValue][key] = value;
    return result;
  }, {});
}