import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Card, Title as MainTitle } from "@tremor/react";
import { useState, useEffect } from "react";
//import { LineChart } from "@tremor/react";
import { displayDateFromUTC, displayDateOrTime, orderObjectArrayByDate, secondsToHHMMSS, updateObjectArrayDateFieldFormating } from "../../functions/dataHandler";
import { placeAverageResponseTime } from "../../functions/apiActions";
import { externalTooltipHandler } from '../externalTooltipHandler';
import SpinnerLoader from '../SpinnerLoader';
import { DataBreakDown } from '../DataBreakDown';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);




const AverageResponseTime=({postFilters})=> {

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dataBreakDown, setDataBreakDown] = useState(null);

  function processData(data){
    //data.labels = data.labels.map(l=>displayDateFromUTC(l));
    if (data.labels.length<=72) {
      data.labels = data.labels.map((l, counter)=>{
        if (counter===0) return displayDateFromUTC(l);
        return displayDateOrTime(l);
      }); 
    } 
    else {
      data.labels = data.labels.map(l=>displayDateFromUTC(l)); 
    }

    setIsLoading(false);
    setData(data);
  }
  
  function updateBreakdown(){ setDataBreakDown(null);  }

  function handleBarClick(elements) {
    try {
      const dataSetIndex = elements[0].datasetIndex;
      const elementIndex = elements[0].index;
      const dataset = data.datasets;
      console.log(dataset);
      console.log(dataSetIndex);
      console.log(elementIndex);
      console.log(dataset[dataSetIndex].rawData);
      const rawData = dataset[dataSetIndex].rawData[elementIndex];
      console.log(rawData);
      setDataBreakDown(rawData);
    }
    catch (e) { console.log(e); }
  }

  const options = {
    onClick: (_, elements) => {
      handleBarClick(elements);
    },
    plugins: {
      title: { display: false },
      legend: { display: false},
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler
      },
    },

    scales: {
      y: {
        beginAtZero: true ,
        ticks: {
            callback: function(value, index, ticks) {
                return  secondsToHHMMSS(value);
            }
        }
      },
      x: {
        grid: {
          display:false
        },
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function(val, index) {
            // Hide every 2nd tick label
            if (data.labels.length<=24) return data.labels[index];
            if (data.labels.length<=72) {
              if (index % 6 === 0) return data.labels[index];
            }
            if (index % 6 === 0) return data.labels[index];

          },
          //color: 'red',
        }
      },
    }
  };

  useEffect(()=>{ 
    setIsLoading(true);
    placeAverageResponseTime(postFilters, (d)=>{
      processData(d)
    });
  }, [ postFilters ]);

  console.log("AverageResponseTime", dataBreakDown);
  return (
    <Card>
      <MainTitle>Average Response Time</MainTitle>
      {(dataBreakDown!==null) && <DataBreakDown preData={dataBreakDown} onClose={updateBreakdown} />}
      {
      (isLoading)
      ? <SpinnerLoader isLoading={isLoading}/>
      : (data!==null) && <Line options={options} data={data} />
      }
    </Card>
  )
}

export default AverageResponseTime;
