export const FormInput=({id, name, type, value, placeholder, onChange, })=>{
    return(
        <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
              id={id}
              type={type}
              name={name}
              value={value}
              placeholder={placeholder}
              onChange={onChange}
            />
    );
}