import { createElement } from "react";
import { displayDateFromUTC, secondsToHHMMSS } from "../functions/dataHandler";

export const externalTooltipHandler = (context) => {
  
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    
    
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map(b => b.lines);
    const tableHead = document.createElement('thead');

    titleLines.forEach(title => {
      const tr = document.createElement('tr');
      tr.style.borderWidth = 0;

      const th = document.createElement('th');
      th.style.borderWidth = 0;
      const text = document.createTextNode(title);
      
      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });
    

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '10px';
      span.style.width = '10px';
      span.style.display = 'inline-block';

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const td = document.createElement('td');
      td.style.borderWidth = 0;

      function getTitleValue(val){
        const [strTitle, strVal] = val.toString().split(":");
        if ((strTitle==="Average response time")||(strTitle==="Average")) {
          val = secondsToHHMMSS(parseInt(strVal));
        }
        else {
          val = strVal;
        }
        return `${strTitle} ${val}`
      }
      
      const text = document.createTextNode(getTitleValue(body));

      td.appendChild(span);
      td.appendChild(text);
      tr.appendChild(td);
      
      tableBody.appendChild(tr);
    });

    //const tableRoot = tooltipEl.querySelector('table');
    const tableRoot = document.createElement('table');
    tableRoot.style.margin = '0px';

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }
    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
    
    tooltipEl.appendChild(tableRoot);

  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

export const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    //tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    //tooltipEl.style.color = 'white';
    tooltipEl.style.border = '1px';
    tooltipEl.style.borderColor = "red";
    tooltipEl.style.border = "1px";
    tooltipEl.style.borderStyle = "solid";
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.background = 'white';
    tooltipEl.style.color = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.zIndex = 10;  
    //tooltipEl.className = 'tooltipChart';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};