import React, { useEffect, useState } from "react";

const Senseman=()=>{

    return(
        <div>SenseMan
        <Stickman />
        </div>
    );
}



const Stickman = () => {
    const [stickmanData, setStickmanData] = useState([
      { x: 200, y: 100 }, // Head
      { x: 200, y: 150 }, // Body
      { x: 200, y: 200 }, // Left arm
      { x: 200, y: 200 }, // Right arm
      { x: 175, y: 250 }, // Left leg
      { x: 225, y: 250 }, // Right leg
      { x: 175, y: 300 }, // Left foot
      { x: 225, y: 300 }  // Right foot
    ]);
  
    useEffect(() => {
      stand();
      setTimeout(sitInChair, 3000);
      setTimeout(lieOnFloor, 6000);
      setTimeout(walkAway, 9000);
    }, []);
  
    const stand = () => {
      const updatedStickmanData = stickmanData.map(part => {
        if (part.y === 150) {
          return { ...part, y: part.y - 50 };
        }
        return part;
      });
      setStickmanData(updatedStickmanData);
    };
  
    const sitInChair = () => {
      const updatedStickmanData = stickmanData.map(part => {
        if (part.y === 150 || part.y === 200) {
          return { ...part, y: part.y + 50 };
        }
        return part;
      });
      setStickmanData(updatedStickmanData);
    };
  
    const lieOnFloor = () => {
      const updatedStickmanData = stickmanData.map(part => {
        if (part.y === 150 || part.y === 200) {
          return { ...part, y: part.y + 100 };
        }
        return part;
      });
      setStickmanData(updatedStickmanData);
    };
  
    const walkAway = () => {
      const updatedStickmanData = stickmanData.map(part => {
        if (part.y === 100 || part.y === 150 || part.y === 200) {
          return { ...part, x: part.x + 100 };
        }
        return part;
      });
      setStickmanData(updatedStickmanData);
    };
  
    return (
      <svg width={400} height={400}>
        {stickmanData.map((part, index) => (
          <circle key={index} cx={part.x} cy={part.y} r={5} />
        ))}
        <line
          x1={stickmanData[1].x}
          y1={stickmanData[1].y}
          x2={stickmanData[2].x}
          y2={stickmanData[2].y}
          stroke="black"
        />
        <line
          x1={stickmanData[1].x}
          y1={stickmanData[1].y}
          x2={stickmanData[3].x}
          y2={stickmanData[3].y}
          stroke="black"
        />
        <line
          x1={stickmanData[1].x}
          y1={stickmanData[1].y}
          x2={stickmanData[4].x}
          y2={stickmanData[4].y}
          stroke="black"
        />
        <line
          x1={stickmanData[4].x}
          y1={stickmanData[4].y}
          x2={stickmanData[6].x}
          y2={stickmanData[6].y}
          stroke="black"
        />
        <line
          x1={stickmanData[5].x}
          y1={stickmanData[5].y}
          x2={stickmanData[7].x}
          y2={stickmanData[7].y}
          stroke="black"
        />
      </svg>
    );
  };
  
  
  
  

export default Senseman;