import { useEffect, useState } from "react";
import { Card, Title, Text, Grid, Col, Divider, TextInput } from "@tremor/react";
import { moveConfigAdd, moveConfigGet, moveConfigRemove, moveConfigTagData } from "../functions/apiActions";
import { getOnceValue } from "../data/firebase";


export const MoveSiteSettings = ({siteID, siteCode}) => {

  const [apiKey, setAPIKey] = useState(null);

  function getAPIKey(){
    if ((siteID===null)||(siteID==="")) return;
    moveConfigGet({"siteID" : siteID}, (d)=>{
      if (d.length===0) {
        setAPIKey("");
      }
      else {
        setAPIKey(d);
      }
    }, (e)=>{console.log(e);});
  }

  useEffect(()=>{ getAPIKey();  }, [siteID]);

  function removeConfig(){
    moveConfigRemove({"siteID" : siteID}, (d)=>{
      if (d[0].TRUE === 1) setAPIKey("");
    }, (e)=>{console.log(e);});
  }

  const AddConfig=()=>{

    const [form, setForm] = useState({});

    function updateInput(e){
      setForm({
        ...form,
        [e.target.name]: e.target.value
      });
    }

    function submitForm(){
      const submitData = {...form, "siteID": siteID };
      moveConfigAdd(submitData, (d)=>{
        getAPIKey();
        console.log(d);
      }, (e)=>{console.log(e);});
      
    }

    return (
      <div>
        <TextInput name="apiKey" placeholder="API Key" onChange={(d)=>updateInput(d)} />
        <TextInput name="userToken" placeholder="User Token" onChange={(d)=>updateInput(d)} />
        <button className="button-3" onClick={()=>submitForm()}>Add Config</button>
      </div>
    );
  }

  const HasConfig=()=>{
    console.log("apiKey", apiKey);
    const details = (apiKey.length>0) ? apiKey[0] : null;
    if (details===null) return (<div>No config found</div>);
    return (
      <div>
        <p>API key: &nbsp; &nbsp;  {details.move_guid}</p>
        <p>User token: &nbsp; &nbsp;  {details.user_token}</p>
        <button onClick={()=>removeConfig()} className="button-3">Remove config</button>
        <SiteUsers siteID={siteID} siteCode={siteCode} />
      </div>
    );
  }

  console.log("apiKey", apiKey);
  return (
    <div className="flex-column">
    <div></div>
    <br />
    <div className="integration_infomation">
      <Card>
        
        {
        (apiKey===null) 
        ? <div>Loading...</div>
        : (typeof(apiKey)==="object") 
            ? <HasConfig />
            : (apiKey==="") ? <AddConfig /> : <div>ERROR</div>}     

        <br />
      </Card>
      <br />
    </div>
  </div>
  );
};


const SiteUsers = ({siteID, siteCode}) => {
  
  const [tags, setTags] = useState(null);
  const [config, setConfig] = useState([]);

  function getTags(){
    moveConfigTagData({"siteID" : siteID}, (d)=>{
      if (d.length===0) {
        setTags([]);
      }
      else {
        setTags(d);
      }
    }, 
    (e)=>{console.log(e);});
  }

  function getConfg(){
    getOnceValue(siteCode + "config", (d)=>{
      if (d===null) {
        setConfig([]);
      }
      else {
        const dta = [];
        Object.keys(d).forEach((k)=>{
          dta.push({ ...d[k], ["dbPath"]: k });
        });
        setConfig(dta);
      }
    } , (e)=>{console.log(e);});
  }

  function getKeyFromTag(tag){
    const f = config.filter((c)=>c.move_id===tag);
    if (f.length===0) return "";
    return f[0].unitName;
  }

  useEffect(()=>{ 
    if ((siteID===null)||(siteID==="")) return;
    getTags();
  }, [siteID]);
  
  useEffect(()=>{ 
    if ((siteCode===null)||(siteCode==="")) return;
    getConfg();
  }, [siteCode]);
  

  if (tags===null) return (<div>Loading...</div>);

  return(
    <div>
      <Divider />
      <h2>Room assigment</h2>
      <div>
        <div className="grid-split-3-container">
          <div className="grid-split-3-item"><b>Move Floor</b></div>
          <div className="grid-split-3-item"><b>Move Room</b></div>
          <div className="grid-split-3-item"><b>Room Unit</b></div>
        </div>

        {
          tags.map((tag, i)=>{
            return (
              <div key={i} className="grid-split-3-container">
                <div className="grid-split-3-item">{tag.floor}{/*<br /><small>{tag.id}</small>*/}</div>
                <div className="grid-split-3-item">{tag.name}<br /><small>{tag.id}</small></div>
                <div className="grid-split-3-item">{getKeyFromTag(tag.id)}</div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}