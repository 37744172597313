import axios from "axios";
import { useState, useEffect } from "react";
import { ReactMediaRecorder } from "react-media-recorder";

export default function TextViewPage() {

    const [blob, setBlob] = useState("");
    const [audio, setAudio] = useState(null);
 
    useEffect(()=>{
        if (blob==="") return;
        //console.log(new Blob(blob));
        setAudio(window.URL.createObjectURL(blob));

    }, [blob]);
    
    function record(){
        
        const key = "f8e4c2f145e1b63630fce76c4d2060f0";
        const content = {
              "text": "Testing",
              "model_id": "eleven_monolingual_v1",
              "voice_settings": {
                "stability": 0,
                "similarity_boost": 0
              }
            }; 
  
        
            axios.create().post("https://api.elevenlabs.io/v1/text-to-speech/21m00Tcm4TlvDq8ikWAM/stream?optimize_streaming_latency=0", content)
            .then((d)=>{
              console.log(d);
            })
            .catch((err)=>{
              console.log(err);
            });
      
        
        return;
        console.log("posting");
        axios.post("/text/tospeech", {
            "userID": "",
            "text": "Testing testing"
        })
        .then((d)=>{
            setBlob(d);
        })
        .catch((e)=>{
            console.log(e);
        })
    }

    return(
        <div>

            <audio src={audio} controls></audio>
            <button onClick={record}>Record</button>

        </div>
    );

}