import './VirtualEnvironment.css';
import { Phone } from './Phone';
import { Panel } from './Panel';
import { useEffect, useState } from 'react';
import { getOnValue } from '../../data/firebase';
import {apiPost} from '../../data/apidata';
import FloorPlan from '../../components/FloorPlan/FloorPlan';

const VirtualPanel=({siteCode})=>{
    console.log("Virtual Environment", siteCode);
    if (siteCode === "coneygarlodge")  siteCode = "adctest";

    const [masterCalls, setMasterCalls] = useState([]);
    const [liveCalls, setLiveCalls] = useState([]);
    const [errors, setErrors] = useState([]);
    const [carer, setCarer] = useState("Room Unit");
    const [selectedRoomCode, setSelectedRoomCode] = useState("");
    
    let phoneDrag = false;
    let lastCall = "";

    useEffect(()=>{

        if (masterCalls===null) {
            setLiveCalls([]);
            return;
        }
        console.log({masterCalls});
        
        const calls = Object.values(masterCalls);
        const newCalls = [];

        calls.filter((cc)=>(cc.callType==="Emergency")).forEach((c)=>{newCalls.push(c)});
        calls.filter((cc)=>(cc.callType==="Accessory")).forEach((c)=>{newCalls.push(c)});
        calls.filter((cc)=>(cc.callType==="Assistance")).forEach((c)=>{newCalls.push(c)});
        calls.filter((cc)=>(cc.callType==="Call")).forEach((c)=>{newCalls.push(c)});
        calls.filter((cc)=>(cc.callType==="Attendance")).forEach((c)=>{newCalls.push(c)});
        calls.filter((cc)=>(cc.callType==="CareCall")).forEach((c)=>{newCalls.push(c)});

        try { setLiveCalls(newCalls); }
            catch(e){ setLiveCalls([]); }
    }, [masterCalls]);
    
    useEffect(()=>{ 
        if (siteCode!=="") getOnValue(siteCode + "PhoneLiveCalls", setMasterCalls, setErrors); 
    },[siteCode])

    function raiseCall(room, callCode, strCarer){
        if (room==="") {
            //alert("Please assign the unit to a room to raise a call.");
            return;
        }

        lastCall = room + "." + callCode;

        apiPost("/call/live/raise",
            {
                "userID":"",
                "siteID" : siteCode,
                "loginName" : "test@arquella.co.uk",
                "callType": callCode,
                "roomCode": room,
                "title": "",
                "comments": "",
                "carer": strCarer,
                "virtualCall": true
            },
            (d)=>{console.log(d)},
            (d)=>{console.log(d)}
        );
    }

    return(
        <div style={{"display":"flex"}}>

            <div style={{
                "display":"flex", 
                "flex-direction":"column", 
                "justifyContent": 
                "space-between", 
                /*"padding": "10px", */
                "alignSelf": "center"
                }}>

                <div className="phone landscape"><Panel calls={liveCalls} carer={carer} /></div>
                <div className="phone portrait"><Phone siteCode={siteCode} calls={liveCalls} carer={carer} /></div>
            
            </div>

        </div>
    );
}

export default VirtualPanel;