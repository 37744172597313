import { apiPost } from "../../data/apidata";
import { defaultSiteValue } from "../../context/UserContext";

export default function getUserDetailsFromAPI(username, token){

    return new Promise((resolve,reject)=>{
        const postObject = {
            userID: username,
            userToken: token
        };

        apiPost(userPostUrl, postObject,
            (d)=>{ 
                console.log({d});
                const userData = d[0].UserData;
                console.log({userData});
                const userJSON = userData; //JSON.parse(userData);
                console.log({userJSON});
                const sites = JSON.parse(userJSON["Sites"]);
                const permissions = JSON.parse(userJSON["Permissions"]);
                
                const userDetails = {
                    "username" : "me",
                    "displayName" : "me",
                    "token" : token,
                    "auth_stamp": new Date(),
                    "sites" : sites, //(sites===null) ? defaultSiteValue : sites,
                    "permissions" : permissions 
                };  
                console.log({userDetails})
                resolve(userDetails); 
                
            },
            (e)=>{ reject(e) } 
        );
        

    });
}


const userPostUrl = "/auth/login/";