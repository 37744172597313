import { useState } from 'react';
import './App.css';
import Live from './Pages/Live';
import UserMenu from './components/UserMenu';
import IMG_LOGO from './images/logo.png';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import React from 'react';
import Move from './Pages/Move';
import TextViewPage from './Pages/TextView';
import People from './Pages/People';
import Place from './Pages/Place';
import ShowRoom from './Pages/ShowRoom';
import VayyarMonitor from './Pages/VayyarMonitor';
import Settings from './Pages/Settings';
import LoginScreen from './Pages/Login/Login';
import { useAuth } from './context/AuthProvider';
import VirtualEnvironment from './Pages/VirtualEnvironment/VirtualEnvironment';
import FloorPlan from './components/FloorPlan/FloorPlan';
import Senseman from './components/Senseman';
import { IMAP } from './Pages/Move/imap';
import Onboarding from './Pages/Onboarding/Onboarding';
import { SiteForm } from './Pages/Onboarding/NewSite';
import { useUserContext } from './context/UserContext';
import Welcome from './Pages/Welcome';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ResidentScreen } from './ResidentScreen';
import { Sys } from './Pages/Sys/sys';
import { RoomUnitCard } from './Pages/RoomUnitConfig/RoomUnitCard';
import { Phone } from './Pages/VirtualEnvironment/Phone';
import PhoneDBMonitor from './Pages/system/monitor/phone_db_monitor';
import CallMonitor from './Pages/system/monitor/call_monitor';


function App() {

  const auth = useAuth();
  const { 
    //userSettings, permissions, 
    sites
    //, hasPermission 
  } = useUserContext();

  //const handleLogout = () => {  auth.logout();  };

  if (!auth.isLoggedIn()) return <LoginScreen />;

  function getRoutes(){

    const siteCode = sites[0].siteDBCode;
    const senseCode = sites[0].siteDBCode;
    const siteID = sites[0].id;

    return(
      <BrowserRouter>
      <Routes>
        <React.Fragment>
          { 
            //<Route path='/' element={Detail} />
            //<Route path="/people/resident/:id" element={<ResidentScreen />} />
          }
            <Route path="/settings" element={<Settings siteCode={siteCode} site={{
              siteCode: siteCode,
              id: siteID
            }} />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/onboarding/groups/:id" element={<SiteForm />} />
            <Route path='/' element={<Live siteCode={siteCode} senseCode={senseCode} />} />
            <Route path="/move" element={<Move siteCode={siteCode} />} />
            <Route path="/sense" element={<SenseView />} />
            <Route path="/care" element={<CareView />} />
            <Route path="/text" element={<TextView />} />
            <Route path="/live" element={<Live siteCode={siteCode} senseCode={senseCode} />} />
            <Route path="/people" element={<People siteID={siteID} siteCode={siteCode} />} />
            <Route path="/place" element={<Place siteID={siteID} siteCode={siteCode} />} />
            <Route path="/showroom" element={<ShowRoom />} />
            <Route path="/vayyarmonitor" element={<VayyarMonitor />} />
            <Route path="/virtual" element={<VirtualEnvironment siteCode={siteCode} />} />
            <Route path="/floorplan" element={<FloorPlan />} />
            <Route path="/senseman" element={<Senseman />} />
            <Route path="/imap" element={<IMAP siteCode={siteCode} moveSiteID={"018290c0-4a7b-9f03-18bb-64cba6e030a5"} />} />
            <Route path='/managetags'element={<ManageTag siteID={siteID} />} />
            <Route path='/sys/'element={<Sys />} />
            <Route path='/map/'element={<IMAP siteCode={"adctest"} moveSiteID={"01861232-864d-460e-2931-bfc95ddd4753"} />} />

            <Route path="/sitemonitor" element={<PhoneDBMonitor site="" />} />
            {/*<Route path="/system/callmonitor" element={<CallMonitor site={siteCode} />} />
  */}
        </React.Fragment>
            
        {
        /*
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        */
        }
      </Routes>
    </BrowserRouter>

    );

  }

  return (
    <div 
      className="overflow-hidden"
    //className="App"
    >
    <HeaderView />
    <br />
    <br />
    {((sites===undefined)||(sites===null)||(sites.length===0)) 
    ? <Welcome />
    : getRoutes()
    }
    <ToastContainer />
    </div>
  );
}

//const Signin=()=>{ return (<>You are not signed in.</>); }


const HeaderView=()=>{ 

const [showToggle, setShowToggle] = useState(false);

return(
<header className="App-header flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full border-b border-white/[.5] text-sm py-3 sm:py-0">
<nav className="relative max-w-[100rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8" aria-label="Global">
  <div className="flex items-center justify-between">
    {//<a className="flex-none text-xl font-semibold text-white" href="" aria-label="">
}
      <div className='header_logo_container'><img alt="Arquella Connect" src={IMG_LOGO} className='header_logo' /><div>Connect</div></div>
 {//}   </a>
}

    <div className="sm:hidden">
      <button 
        onClick={()=>{ setShowToggle(!showToggle); }}
      type="button" 
      className="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border border-white/[.5] font-medium text-white/[.5] shadow-sm align-middle hover:bg-white/[.1] hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm" 
      data-hs-collapse="#navbar-collapse-with-animation" 
      aria-controls="navbar-collapse-with-animation" 
      aria-label="Toggle navigation">
        
        <svg className={"hs-collapse-open:block " + ((showToggle===true) ? "open" : "hidden") + " w-4 h-4"} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
        <svg className={"hs-collapse-open:block " + ((showToggle===false) ? "" : "hidden") + " w-4 h-4"} width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>
      </button>
    </div>
  </div>
  <div id="navbar-collapse-with-animation" className={"hs-collapse " + ((showToggle===false) ? "" : "hidden") + " overflow-hidden transition-all duration-300 basis-full grow sm:block"}>
    <div className="flex flex-col gap-y-4 gap-x-0 mt-5 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:pl-7">
      <UserMenu />
    </div>
  </div>
</nav>
</header>
);  

}


const SenseView=()=>{ return (<></>); }

const CareView=()=>{ return (<></>); }

const TextView=()=>{ return (
<>
  <HeaderView />
  <TextViewPage />
</>
); 
}

const ManageTag=()=>{
  return(
    <div></div>
  );
}


export default App;

