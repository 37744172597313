import { apiPost } from "../data/apidata";
import { raiseError } from "./error_handling";

function runAPI(path, data, onSuccess, onError){
    console.log(data);
    const submitData = {
      ...data,
      "userID": "adc"
    };
  
    apiPost(path, submitData, (d)=>{
        onSuccess(d);
    }, (e)=> {
        console.log("RUNAPI ERROR");
        console.log(e);
        (onError) ? onError(e) : raiseError(e)
    });
}

export function addEditResident(data, onSuccess, onError){ apiPost("/residents/add_resident", data, onSuccess, onError); }
export function getResidentsList(data, onSuccess, onError){ apiPost("/residents/list", data, onSuccess, onError); }
export function getResidentsListAll(data, onSuccess, onError){ apiPost("/residents/listall", data, onSuccess, onError); }
export function getResidentBasic(data, onSuccess, onError){ runAPI("/residents/get_resident", data, onSuccess, onError); }
export function getResidentDescription(data, onSuccess, onError){ runAPI("/residents/get_resident_description", data, onSuccess, onError); }
export function setResidentDescription(data, onSuccess, onError){ runAPI("/residents/set_resident_description", data, onSuccess, onError); }
export function raiseCall(data, onSuccess, onError){ runAPI("/call/live/raise", data, onSuccess, onError); }
export function nfcCall(data, onSuccess, onError){ runAPI("/call/live/nfcCall", data, onSuccess, onError); }
export function placeKPICards(data, onSuccess, onError){ runAPI("/dashboard/place/kpicards", data, onSuccess, onError); }
export function placeTotalCallsReport(data, onSuccess, onError){ runAPI("/dashboard/place/totalcalls", data, onSuccess, onError); }
export function placeAverageResponseTime(data, onSuccess, onError){ runAPI("/dashboard/place/averageresponsetime", data, onSuccess, onError); }
export function placeAverageResponseTimePerZone(data, onSuccess, onError){ runAPI("/dashboard/place/averageresponsetimeperzone", data, onSuccess, onError); }
export function placeCareByType(data, onSuccess, onError){ runAPI("dashboard/chart/caredeliveredbytype", data, onSuccess, onError); }
export function placeCallsByType(data, onSuccess, onError){ runAPI("/dashboard/place/totalcallsbytype", data, onSuccess, onError); }
export function placeCallsByCarer(data, onSuccess, onError){ runAPI("/dashboard/place/totalcallsbycarer", data, onSuccess, onError); }
export function getLastRefresh(data, onSuccess, onError){ runAPI("/dashboard/settings/getlastrefresh", data, onSuccess, onError); }
export function getSysSiteCallCount(data, onSuccess, onError){ runAPI("/sys/site/allsitecallcount", data, onSuccess, onError); }
export function getProactiveVReactive(data, onSuccess, onError){ runAPI("/dashboard/place/proactivevreactive", data, onSuccess, onError); }
export function getSitesList(data, onSuccess, onError){ runAPI("/sites/list", data, onSuccess, onError); }
export function addNewSite(data, onSuccess, onError){ runAPI("/onboarding/site/addSite", data, onSuccess, onError); }
export function addIntegrationUnit(data, onSuccess, onError){ runAPI("/integrations/nourish/resident_assign_unit", data, onSuccess, onError); }
export function moveConfigGet(data, onSuccess, onError){ runAPI("/move/config/get", data, onSuccess, onError); }
export function moveConfigAdd(data, onSuccess, onError){ runAPI("/move/config/add", data, onSuccess, onError); }
export function moveConfigRemove(data, onSuccess, onError){ runAPI("/move/config/remove", data, onSuccess, onError); }
export function moveConfigTagData(data, onSuccess, onError){ runAPI("/move/config/data", data, onSuccess, onError); }

