import { BarList, Card, Title, Bold, Flex, Text } from "@tremor/react";
import { apiPost } from "../../data/apidata";
import { useState, useEffect } from "react";

export default function CallsByType(){

  const [data, setData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState([]);
  const [error, setError] = useState("");
  
  useEffect(()=>{ apiPost("dashboard/chart/callsbytype", {
    "userID" : "",
    "siteID" : "" 

  }, setData, setError); },[]);

  function getColour(type){
    if (type==="Emergency") return "red";
    if (type==="Call") return "orange";
    if (type==="Accessory") return "purple";
    if (type==="Assistance") return "yellow";
    if (type==="Attendance") return "green";
    if (type==="Emergency") return "red";
    return "red";
  }

  useEffect(()=>{

    const tmp = data.map((d)=>{
      return{
      name: d.name,
      value: d.value,
      color: getColour(d.name)
    }
    });

    setDataUpdated(tmp);

  }, [data]);

  console.log("ByType", dataUpdated);
  
  return (
    <Card className="max-w-sm">
      <Title>Calls By Type</Title>
      <Flex className="mt-4">
        <Text>
          <Bold>Source</Bold>
        </Text>
        <Text>
          <Bold>Calls</Bold>
        </Text>
      </Flex>
      <BarList data={dataUpdated} className="mt-2" />
    </Card>
  );
}