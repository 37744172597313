import './ToolTip.css';

const AQToolTip = (props) => {
    return <span className="aqtooltiptext">{props.tooltip}</span>;
    return (
        <div className="aqtooltip" style={{ "width": props.width + "%" }} >
            {props.children}
            <span className="aqtooltiptext">{props.tooltip}</span>
        </div>
    );
}

export default AQToolTip;