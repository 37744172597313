import { useState } from "react";
import { Link } from "react-router-dom";
import { AQModal } from "../components/Modal/Modal";
import { ResidentScreen } from "../ResidentScreen";
import { BasicDetails } from "../BasicDetails";

export const ResidentLink=(props)=>{
    const [openModal, setOpenModal] =  useState(false);
    //  <Link to={`resident/${props.id}`}>{props.children}</Link>;
    const [title, setTitle] = useState((props.id==="-1") ? "New Resident" : "");
    function setResID(r){

    }
    function setResName(r){

    }
    /*
    const details = (props.id!=="-1") 
    ? <BasicDetails resID={props.id} callBack={setResID} nameCallBack={setResName} />
    : <div>New Resident</div>
    */
    console.log("RESIDENT LINK", props);
    return(
        <>
        
            {
            (openModal)
            &&
            <AQModal show={true} title={title} onClose={()=>{setOpenModal(false)}}>
                <ResidentScreen id={props.id} residentObject={props.residentObject} siteID={props.siteID}/>
            </AQModal>
            }


            <div 
                onClick={()=>{setOpenModal(true)}}
                //to={`resident/${props.id}`}
            >{props.children}</div>
        </>
    );
}
