export const Icon_plus_circle = ()=>
<svg 
xmlns="http://www.w3.org/2000/svg" 
fill="none" 
viewBox="0 0 24 24" 
strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
    <path 
    strokeLinecap="round" 
    strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>;

export const Icon_x_circle = ()=>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
<path strokeLinecap="round" 
strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>;

export const Icon_information_circle = ()=>
<svg 
  xmlns="http://www.w3.org/2000/svg" 
  fill="none" 
  viewBox="0 0 24 24" 
  strokeWidth="1.5" 
  stroke="currentColor" 
  className="w-6 h-6">
  <path 
    strokeLinecap="round" 
    strokeLinejoin="round" 
    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" 
  />
</svg>

export const Icon_edit_circle = ()=><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
<path 
  strokeLinecap="round" 
  strokeLinejoin="round" 
  d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
/>
</svg>

export const Icon_refresh_circle = ()=>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path 
  strokeLinecap="round" 
  strokeLinejoin="round" 
  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
</svg>
