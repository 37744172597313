import { useState, useEffect } from "react";
import { Accordion, AccordionHeader, AccordionBody, AccordionList, List, ListItem, Card, Title } from "@tremor/react";
import { GetSenseImage } from "../GetImage";
import { getOnValue, getOnceValue } from "../../data/firebase";
import { groupBy } from "../../functions/objects_arrays";

export default function SenseLive({sitesList}){

  const [calls, setCalls] = useState([]);
  const [masterCalls, setMasterCalls] = useState({});
  const [config, setConfig] = useState({});
  
  function updateValues(data){
    //console.log({data});
    setMasterCalls(data);
  }

  function handlError(error){
    console.log(error);
  }

  function processMasterCalls(){
    const tmpsCalls = [];
    console.log({config});
    try {
    Object.keys(masterCalls).forEach((k)=>{ 
      Object.keys(masterCalls[k]).forEach(c=>{
        if (masterCalls[k][c]===false) return;
        tmpsCalls.push(
          {
            "location": k,
            "room": getVayyarRoomFromID(c)
          }
        );
      });
    });
    } catch(e) { console.log(e); }
    setCalls(tmpsCalls);
  }

  function getVayyarRoomFromID(id){
    const matches = config.filter((c)=>(c.vayyarUnit===id));
    if (matches.length===0)return id;
    return matches[0].roomName;
  }

  function trimVayyarRef(ref){
    ref = ref.replace("/devices/","");
    ref = ref.replace("/events","");
    return ref;
  }

  function getConfig(site){
    if (site==="") return;
    //getOnceValue(site + "_site/unitconfig", (d)=>{
    const configPath = site + "_unitconfig";
    console.log("sense lookup val", configPath);

    getOnceValue(configPath, (d)=>{
      const tmpValue = [];
      d.map((val)=>{
        tmpValue.push(
          {
            "vayyarUnit": trimVayyarRef(val.vayyarId),
            "roomName": val.vayyarName,
            "zoneName": val.vayyarZone
          }
        );
      });
      setConfig(tmpValue);
      getSiteData(site);
    },
    (e)=>{ console.log(e); }
    )
  }

  function getSiteData(site){
    console.log("sense site Data lookup val", site);
    //getOnValue(site + "_site/config/Vayyar/presence/Main", updateValues, handlError);
    getOnValue(site + "_site/config/Vayyar/presence/Main Panel/", updateValues, handlError);
  }

  function resetSitesList(){
    if (sitesList.length===0) return;
    sitesList.map(site=>{
      if (site==="") return;
      getConfig(site);
    });
  }

  useEffect(()=>resetSitesList(),     [sitesList]);
  useEffect(()=>processMasterCalls(), [masterCalls]);

 

  return(
  <Card 
    //className="max-w-sm"
    className="split_card_bottom"
  >
    <Title className="upper_case">Sense</Title>
    <br />
    <div className="dashboard_frame">
      <AccordionList className="max-w-md mx-auto">
        

        {
        (calls.length>0) 
        ? 
          <>
            <CheckLocation location={"fall"} calls={calls} />
            <CheckLocation location={"manOnGround"} calls={calls} />
            <CheckLocation location={"bathroom"} calls={calls} />
            <CheckLocation location={"chair"} calls={calls} />
            <CheckLocation location={"bed"} calls={calls} />
            <CheckLocation location={"room"} calls={calls} />
          </>
        : <></>}
              





        {//
}
        {//calls.map((c)=><CallCard data={c} />)
        }
      </AccordionList>
    </div>
    
    
  </Card>
)};


const CheckLocation=({location, calls})=>{
  const filteredCalls = calls.filter(c=>(c.location===location));
  
  if (filteredCalls.length===0) return <></>;
  console.log({filteredCalls})
  
  return(
    <>
      <CallCard data={filteredCalls} location={location} />
    </>
  );
}

const CallCard=({data, location})=>{
  console.log("callCardData", data);
  return (
    <Accordion>

      <AccordionHeader className={"bg_sense_" + location + " colour-white"}>
      
      <CallHeader data={location} />
      <div>
       <List>
          {data.map((d) => (
            <ListItem className="colour-white" key={d.room}>
              <span>{d.room}</span>
              {//<span>{item.rating}</span>
}
            </ListItem>
          ))
          }
        </List>
      </div>
      </AccordionHeader>
    </Accordion>
  );
}

function getLocationDisplay(location){
  if (location==="bed") return "In bed";
  if (location==="fall") return "Fall";
  if (location==="manOnGround") return "On the ground";
  if (location==="bathroom") return "In bathroom";
  if (location==="chair") return "In Chair";
  if (location==="room") return "In Room";

  return location;
}

const CallHeader=({data})=>{
  return (
    <div className="sense_call_header_container">
        <GetSenseImage imgString={data.toLowerCase()} />
        <div className="align-left left-padding-10">{getLocationDisplay(data)}</div>
    </div>
  );
}


const CallBody=({data})=>{

  return (
    <div>
      Call Details
    </div>
  );
}
