import { useState } from "react";
import { Text, Grid, Title } from "@tremor/react";

export const GroupFormBasic = ({ groupID }) => {

  const [group, setGroup] = useState({
    id: groupID,
    name: ""
  });

  function getTitle(companyName) {
    return (companyName === "") ? "New Group" : companyName;
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setGroup((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(group);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Title>{getTitle(group.name)}</Title>
      <Grid numItemsSm={2} numItemsMd={2} className="mt-6 gap-6">
        <Text>Group Name:</Text>
        <input
          type="text"
          name="name"
          placeholder="Care Group Name"
          value={group.name}
          onChange={handleInputChange} />
      </Grid>

      <button type="submit">{((groupID === "-1") || (groupID === -1)) ? "Create New Group" : "Update"}</button>

    </form>

  );
};
