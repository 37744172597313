import { useState } from "react";
import { TextInput, Card } from "@tremor/react";
import { getOnceValue, setValue } from "../data/firebase";
import SpinnerLoader from "../components/SpinnerLoader";
import "./table_settings.css";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableBody,
} from "@tremor/react";
import { groupBy } from "../functions/objects_arrays";
import { useEffect } from "react";


export const PanelSettings = ({ siteCode }) => {
  
  const [addNew, setAddNew] = useState(false);
  const [config, setConfig] = useState(null);
  const [groupedConfig, setGroupedConfig] = useState(null);
 
  console.log("configTop", config);
  useEffect(()=>{
    setConfig(null);
    if ((siteCode===null)||(siteCode===undefined)||(siteCode==="")) return;
    const sitePath = `${siteCode}config`;
    getOnceValue(sitePath, 
      (d)=>{ 
        
        if ((d===null)||(d===undefined)) setConfig([]);
        const groupedData = groupBy(d, "groupid");
        setConfig(groupedData); 
      }, 
      (e)=>{ console.log(e); });
    
  },[siteCode]);

  /*useState(()=>{
    console.log("configChange", config);
    if ((config===null)||(config===undefined)||(config==="")) return;
    
    console.log("deeed", d);
    setGroupedConfig(d);

  },[config]);
  */

  if ((config===null)||(config===undefined)) return (<SpinnerLoader isLoading={true} />);

  return (
    <Card>

        <RoomUnitGroup config={config} siteCode={siteCode} />
      
    </Card>
  );

};

const GroupRow = ({ title }) => {
  return <TableRow><TableCell colSpan={3} className="text-center">{title}</TableCell></TableRow>;
}

const RoomUnitGroup = ({ config, siteCode }) => {
  console.log("config", config);
  return (
    <Table className="settings_table">

        <TableHead>
          <TableRow>
            <TableHeaderCell>Unit ID</TableHeaderCell>
            <TableHeaderCell>Room Name</TableHeaderCell>
            <TableHeaderCell>Zone</TableHeaderCell>
          </TableRow>
        </TableHead>

        
        
        
        {
        (config!==null)&&
        Object.keys(config).map((key)=>{
          return(
            <TableBody>
            <GroupRow title={(key!=="undefined")?config[key][0].unitName:"Units not Grouped"} />
            {
              config[key].map((item) => <RoomUnit siteCode={siteCode} data={item} />)
            }
            </TableBody>
          )
        })
        }


      </Table>
  );
  
}

/*

          {config.map((item) => <RoomUnit siteCode={siteCode} data={item} />)}
        
          <TableRow>
            <TableCell colSpan={4} className="text-right">Group</TableCell>
          </TableRow>
        
          {config.map((item) => <RoomUnit siteCode={siteCode} data={item} />)}


*/

const RoomUnit = ({ data, siteCode }) => {

  const [formData, setFormData] = useState((data));
  
  function handleEvent(ev) {
    /*
    const { name, value } = ev.target;
    setFormData((prevFormValues) => ({
      ...prevFormValues, [name]: value
    }));
    */
  }
/*
  function update() {
    console.log("submitting", formData);
    console.log(`${siteCode}config/${formData.unitId}` );


    if (siteCode==="") return;
    if (formData.unitId==="") return;
    setValue(`${siteCode}config/${formData.unitId}`, formData,
    ()=>{
      console.log("Updated");
    },
    (e)=>{console.log(e);});

  }
*/



  return (
      <TableRow key={formData.unitName}>
        {//<TableCell>{formData.unitName}</TableCell>
}
        <TableCell className="text-right"><GetTextInput name="unitId" placeholder="ID" value={formData.unitId} onChange={handleEvent} /></TableCell>
        <TableCell className="text-right"><GetTextInput name="unitName" placeholder="Name" value={formData.unitName} onChange={handleEvent} /></TableCell>
        <TableCell className="text-right"><GetTextInput name="unitZone" placeholder="Zone" value={formData.unitZone} onChange={handleEvent} /></TableCell>
        {/*
        <TableCell className="text-right"><GetTextInput name="groupid" placeholder="Set the Group ID" value={formData.groupid} onChange={handleEvent} /></TableCell>
        <TableCell className="text-right"><button className="basic" onClick={() => update()}>Update</button></TableCell>
        */}
      </TableRow>
   
  );
  
  
};

const GetTextInput=({name, value, placeholder, onChange})=>{
  return(
    <TextInput 
      name={name}
      disabled={true}
      placeholder={placeholder} 
      value={value} 
      //onChange={onChange} 
      />
  );
}

/*
import { useState } from "react";
import { Divider, Text, TextInput, Grid, Card, Flex } from "@tremor/react";
import { AQModal } from "../components/Modal/Modal";
import { AQTag } from "../AQTag";
import { Icon_plus_circle } from "../components/svgIcons";
import { Form } from "react-router-dom";
import { FormInput } from "../components/Input";
import { getOnceValue, pushValue, setValue } from "../data/firebase";
import { raiseError } from "../functions/error_handling";

export const PanelSettings = ({ siteCode, config }) => {

  const [addNew, setAddNew] = useState(false);

  console.log({addNew});
  return (
    <>
      {(addNew===true)&&
        <AQModal title="New Room Unit" show={true} onClose={()=>setAddNew(false)}>
          <NewRoomUnit siteCode={siteCode} />
        </AQModal>
      }
      <Flex className="mb-5" justifyContent="end">
        <div
        onClick={()=>setAddNew(true)}>
          <AQTag>New Room Unit <Icon_plus_circle /></AQTag>
        </div>
      </Flex>
      <Grid numItems={1} numItemsSm={2} numItemsLg={4} className="gap-6">
        {((config !== undefined) && (config !== null))
          && Object.keys(config).map(k => <RoomUnit key={"ru" + k} data={config[k]} />)}
      </Grid>
    </>
  );
};

const RoomUnit = ({ data }) => {

  const [formData, setFormData] = useState((data));
  
  function handleEvent(ev) {
    const { name, value } = ev.target;
    setFormData((prevFormValues) => ({
      ...prevFormValues, [name]: value
    }));
  }

  function update() {

    console.log("submitting", formData);

  }

  return (
    <Card>
      <Text>{formData.unitName}</Text>
      <Divider />
      <Grid numItems={1} numItemsSm={2} numItemsLg={2} className="gap-1">
        <Text>Unit ID</Text>
        <TextInput name="unitId" placeholder="ID" value={formData.unitId} onChange={handleEvent} />
        <Text>Unit Name</Text>
        <TextInput name="unitName" placeholder="Name" value={formData.unitName} onChange={handleEvent} />
        <Text>Zone</Text>
        <TextInput name="unitZone" placeholder="Zone" value={formData.unitZone} onChange={handleEvent} />
        
      </Grid>

      <button className="basic" onClick={() => update()}>Update</button>
      {//Object.keys(data).map(k=><div>{k}</div>)
      }
    </Card>
  );
};


const NewRoomUnit=({siteCode})=>{

  const [formData, setFormData] = useState({

  });

  function handleEvent(ev) {
    const { name, value } = ev.target;
    setFormData((prevFormValues) => ({
      ...prevFormValues, [name]: value
    }));
  }

  function update() {

    console.log("submitting", formData);

    if (siteCode==="") return;
    if (formData.unitId==="") return;
    pushValue(`${siteCode}config/`, formData, 
      ()=>{ console.log("Updated"); },
      (e)=>raiseError("a", "Add Room Unit", "Add Room Unit", e.toString(), true));

  }

  function convertCodeToInt(code) {
    // Use parseInt to convert the string to an integer, which will automatically trim leading zeros
    return parseInt(code, 10);
  }

  return(
    <form onSubmit={()=>{}}>
      <Grid numItems={1} numItemsSm={2} numItemsLg={2} className="gap-1">
        <Text>Unit ID</Text>
        <TextInput name="unitId" placeholder="ID" value={formData.unitId} onChange={handleEvent} />
        <Text>Unit Name</Text>
        <TextInput name="unitName" placeholder="Name" value={formData.unitName} onChange={handleEvent} />
        <Text>Zone</Text>
        <TextInput name="unitZone" placeholder="Zone" value={formData.unitZone} onChange={handleEvent} />
      </Grid>

      <button className="basic" onClick={() => update()}>Add Unit</button>
   
    </form>
  )
}
*/