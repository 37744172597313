import { useState, useEffect } from "react";
import { getOnceValue } from "../../../data/firebase";
import { get } from "firebase/database";


function flattenObjectArrays(obj) {

    let result = [];

    for (const key in obj) {
        for (const key2 in obj[key]) {
            result.push(obj[key][key2].Details);
        }
    }

    return result;

}
  
function countDataSync(dt){
    let count = 0;
    dt.forEach((item)=>{
//        console.log("item", item);
        if (Object.keys(item).length===1) count++;
    });
    return count;
}

function mapToFailType(dt){
     return dt.map((item)=>{ 
        if (Object.keys(item).length===1) return {"reason": "sync"};
        if ((item.txCode) && (item.txCode.includes("}"))) return {"reason": "bracket"};
        if ((item.txCode) && (item.txCode.includes(".0"))) return {"reason": "bracket"};
        return item;
    })
}

function arrayGroupBy(arr, key) {
    return arr.reduce((acc, item) => {
      (acc[item[key]] = acc[item[key]] || []).push(item);
      return acc;
    }, {});
}

function getBracketData(dt){

    const data = flattenObjectArrays(dt);
    console.log("data", data);

    const totals = arrayGroupBy(mapToFailType(data), "reason");
    console.log("totals", totals);

    return totals;
}

function getTotalFromDate(dt){

    const data = [];
    
    if ((dt===undefined)||(dt===null)) return data;

    Object.keys(dt).map((key)=>{
        console.log(key);
        console.log(dt[key]);
        data.push(
            {
                date: key,
                sites: Object.keys(dt[key]).length,
                recs: Object.keys(dt[key]).reduce((acc, site)=>{
                    console.log("site", site);
                    console.log("dt[key][site]", dt[key][site]);
                    return acc + Object.keys(dt[key][site]).length;
                }, 0),
                breakdown: getBracketData(dt[key])
            });
    });

    return data;
}


const PhoneDBMonitor=({site})=>{

    const [data, setData] =  useState(null);
    const [breakdownValue, setBreakdownValue] =  useState(null);

    useEffect(()=>{

        getOnceValue("adcmonitor", setData, (err)=>{console.log(err)});

    }, [site]);

    const dataData = getTotalFromDate(data);

    
    if (data===null) return (<div>Loading...</div>);
    if (dataData!==null) 
    return (
    <div className="margin_default">
        {
        dataData.map((d)=>{
            const style = { width: `${(d.recs*10)}px` };
            return (
                <button
                    onClick={()=>setBreakdownValue(d.date)}
                    className="barlist_container"
                    >            
                    <div className="title">{d.date}</div>
                    <div className="recs" style={style}>{d.recs}</div>
                </button>
            );
        })
        }    
        <br/><br/><br/>
        <div className="margin_default">
            {
            (breakdownValue!==null) 
            &&
            <div >
                <h1><b>Breakdown by site:</b></h1><br/>
                {
                Object.keys(data[breakdownValue]).map((d)=>{
                    
                    const count = Object.keys(d).length;
                    const style = { width: `${(count*10)}px` };
                    //const style2 = { width: `${(d.recs*10)}px` };
                    
                    
                    return (
                        <button
                            //onClick={()=>setBreakdownValue(d)}
                            className="barlist_container">            
                            <div className="title">{d}</div>
                            <div className="recs" style={style}>{count}</div>
                        </button>
                    );
                })
                }    


            </div>
            }
        </div>

    </div>
    );



    return(
        <div>
            {
                Object.keys(data).map((key)=>{
                    return(
                        <div className="flex-row m-2" key={key}>
                            <div className="m-2">{key}</div>
                            <div className="m-2"><ShowSites dt={data[key]}/></div>
                        </div>
                    )
                })
            }
        </div>
    );
}

const ShowSites=({dt})=>{
    return(
        <div>
     {
     Object.keys(dt).map((key)=>{
                    return(
                        <div className="flex-row" key={key}>
                            <div>{key}: &nbsp;&nbsp;&nbsp;</div>
                            <ShowRecs dt={dt[key]}/>
                        </div>
                    )
                })
        }
        </div>
    )
}

const ShowRecs=({dt})=>{
    
    return (<div>
        {Object.keys(dt).length}

    </div>);

    return(
        <div>
     {
     Object.keys(dt).map((key)=>{
                    return(
                        <div key={key}>
                            <div>{key}</div>
                            {
                                
                            }
                        </div>
                    )
                })
        }
        </div>
    )
}

export default PhoneDBMonitor;