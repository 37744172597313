import { useEffect, useState } from 'react';
import './JourneyBar.css';
import AQToolTip from '../../ToolTip/ToolTip';
import { lCase } from '../../../functions/string';
import { getMinMax, getDifferenceInSeconds, calculatePercentageRoundUp, displayTimeFromUTC} from '../../../functions/dataHandler';


export const JourneyBar = ({ journey }) => {

  let start = journey.start;
  let end = journey.end;
  let totalTime = journey.duration;

  return (
    <>
    <div className="journeybar_main_container">
      <div className="journeybar_container">

        {journey.calls.map((j, i)=>{
          console.log("jrn", j);
            const getDif = getDifferenceInSeconds(j.start, j.end_date_time_utc);
            const per = calculatePercentageRoundUp(getDif, totalTime);
            return (
              <JourneyBarSection 
                  width={per}
                  record={j}
                  callType={lCase(j.callType)}
                  accType={j.AccessoryType}
              />
            );
        })}
    </div>
   
  </div>
  </>
  );
};



function getDurationDisplay(start, end){
  const diff = getDifferenceInSeconds(start, end);
  if (diff<60) return `${diff} seconds`;
  
  if (typeof diff !== 'number') {
    return "Invalid input. Please provide a number of seconds.";
  }

  const minutes = Math.floor(diff / 60);
  const remainingSeconds = diff % 60;

  return `${minutes} minute${(minutes>1)?"s":""} and ${remainingSeconds} second${(remainingSeconds>1)?"s":""}`;
}


const JourneyBarSection = ({width, callType, record, accType})=>{
 
  return(
    <>
       
      <div 
          style={{ "width": width + "%" }} 
          className={`aqtooltip bg_${callType}`}>
            <AQToolTip width={width} tooltip={
            <div>
              <div className='uppercasefirst'>{((accType)&&(accType!==""))? accType : callType}</div>
              <div>{getDurationDisplay(record.start, record.end_date_time_utc)}</div>
              {/*<div>{displayTimeFromUTC(record.start)} - {displayTimeFromUTC(record.end_date_time_utc)}</div>*/}
              <div>{(record.carer!=="Room Unit") ? `${record.carer} attended.`: ""}</div>
            </div>
          }></AQToolTip>
              &nbsp;
      </div>

      
    </>
  );

}

