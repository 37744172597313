
import { useEffect, useState } from "react";
import { Grid, Col, Metric, Divider, Text, TextInput,  Tab,  TabList,  TabGroup,  TabPanel,  TabPanels, Card, Flex } from "@tremor/react";
import Nourish from "../integrations/Nourish";
import { getOnceValue } from "../../data/firebase";
import { groupBy } from "../../functions/objects_arrays";
import RoomUnitConfig from "../RoomUnitConfig/RoomUnitConfig";
import { IMAP } from "../Move/imap";
import { OnboardingSites } from "./OnboardingSites";
import { SiteOwners } from "./SiteOwners";
import { AQModal } from "../../components/Modal/Modal";
import SpinnerLoader from "../../components/SpinnerLoader";
import { addNewSite, getSitesList } from "../../functions/apiActions";
import { FormInput } from "../../components/Input";
import { SiteDetails } from "./SiteDetails";

export default function Onboarding({siteCode}) {

  /*
  const [siteConfig, setSiteConfig] = useState(null);
  
  useEffect(()=>{
    if (siteCode==="") return;
    getOnceValue(
      siteCode + "config",
      (d)=>setSiteConfig(d),
      (e)=>{console.log(e);}
    )
  },[siteCode]);
*/

return (
  <main className="px-12 py-12">

    <Text>Sites</Text>
    
    <Sites />
    
    {//<SiteOwners />
    }

  </main>
);
  }


const Sites=()=>{

  const [viewSiteForm, setViewSiteForm] = useState(null);
  const [sitesList, setSitesList] = useState([]);

  function closeForm(){ setViewSiteForm(null); }

  useEffect(()=>{ getSitesList({}, (d)=>setSitesList(d)); },[]);
  console.log({viewSiteForm});
  return(
    <div>
      {/*<button
      onClick={()=>{
        setViewSiteForm({
          id: -1,
          siteName: "",
          siteCode: ""
        });
      }}
    ><NewSiteSVG /> New Site</button>*/}
      
    
      <div class="flex">
        
        <div class="w-1/4 bg-gray-200 p-4">
          <ul>
            {sitesList.map((site)=>{
              
              return <li 
              onClick={()=>{
                setViewSiteForm(new Object({
                  id: site.s_ID,
                  siteName:site.s_Name,
                  siteCode:site.s_db_code
                }));
              }}
              class="mb-2 cursor-pointer">{site.s_Name}</li>
            
            })}
              
          </ul>

        </div>
        
        <div class="w-3/4 bg-white p-4">
        {viewSiteForm!==null && <SiteForm siteDetails={viewSiteForm} />}
        </div>
      </div>
    </div>
  )

}

const SiteForm=({siteDetails})=>{

  const [site, setSite] = useState(siteDetails);
  console.log("SSSIIIITE", site);
  function addSite(){
    console.log(site);
    if (site.id!==-1) return;
    addNewSite(site, (d)=>{
      console.log("Site return", d);
      if (parseInt(d).toString()==="NaN") { 
        return;
      }
      else {
        setSite((prevState) => ({ ...prevState, id: d }));
      }
    }
    );
  }
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSite((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addSite();
    console.log(site);
  };
  
  useEffect(()=>{ setSite(siteDetails); },[siteDetails]);

  if ((site===null)||(site===undefined)) return <SpinnerLoader isLoading={true} />;
 

  return (
    <div className="mt-6">
      <Card>
        <h1>{site.siteName}</h1>
        <h2>({site.siteCode})</h2>

        {(site.id!==-1)&&<SiteDetails siteCode={site.siteCode} siteID={siteDetails} siteName={site.siteName} site={site} />}

      </Card>

      

    </div>

  );
}


const NewSiteSVG=()=>{

  return(
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );
}

/*


  return (
    <main className="px-12 py-12">

      <Text>Onboarding</Text>

      <TabGroup className="mt-6">
        <TabList>
          <Tab>Site Owners</Tab>
          <Tab>Sites</Tab>
        </TabList>
        <TabPanels>

          <TabPanel><SiteOwners /></TabPanel>
          <TabPanel><OnboardingSites /></TabPanel>
          
        </TabPanels>
      </TabGroup>
    </main>
  );
}
*/


