import { useEffect, useState } from 'react';
import './Phone.css';
import IMG_LOGO from '../../images/logo_white_background.jpg';
import { lCase } from '../../functions/string';
import {GetCallTypeImagePhone} from "../../components/GetImage";
import CallTimer from '../../components/CallTimer';
import IMG_PERSONAL from '../../images/care_clicks/personal_care.png';
import IMG_CONTINENCE from '../../images/care_clicks/continence.png';
import IMG_NUTHYDRATION from '../../images/care_clicks/nutrition_hydration.png';
import IMG_SOCIAL from '../../images/care_clicks/social.png';
import IMG_MOVING from '../../images/care_clicks/moving.png';
import IMG_SLEEP from '../../images/care_clicks/sleep_rest.png';
import { pushValue } from '../../data/firebase';


export const Phone=({siteCode, calls, carer})=>{
    
    const [careClickRoom, setCareClickRoom] = useState("");
    

    function getCareClickObject(siteCode, data, callType, callvalue){
        return {
            "CareID": "id",
            "CareDateTime": new Date().toISOString(),
            "CallType": callType,
            "CallValue": callvalue, 
            "Carer": carer,
            "Device": "Virtual Panel",
            "Site": siteCode,
            "Zone": data.zone,
            "Room": data.room,
            "Resident": "Resident",
            "aqRef": data.aqRef
          };
    }


    function getCodeFromText(text){
        if (text==="Personal Care") return "personal";
        if (text==="Continence") return "continence";
        if (text==="Nutrition & Hydration") return "nutandhyd";
        if (text==="Sleeping & Rest") return "sleep";
        if (text==="Social Life") return "social";
        if (text==="Moving & Handling") return "moving";
        return text;
        return "Comments";
    }
    
    const getCareCallPath=(site, room)=>`${site}PhoneLiveCalls/${room}/Care Delivered/`;
    
    function getRoomCodeFromTX(tx){
        try { return tx.split(".")[0]; } catch(e){ return ""; }
    }

    function addCareClick(ccText){
        const matchedCall = calls.filter(c=>(c.room===careClickRoom));
        if (matchedCall.length===0) return;
        const call = matchedCall[0];
        const callType = getCodeFromText(ccText);
        const callValue = (callType==="Comments") ? ccText : "callValue";
        const clickRecord = getCareClickObject(siteCode, call, callType, callValue);
        const roomCode = getRoomCodeFromTX(call.txCode);
        if (roomCode==="") return;
        const callPath = getCareCallPath(siteCode, roomCode);

        pushValue(callPath, clickRecord,(d)=>{console.log(d)}, (e)=>{console.log(e)});

    }

    const ShowCareClick=({IMG, text})=>{
        return(
            <div className='careclick_card'>
                <div>{text}</div>
                <img 
                    onClick={()=>addCareClick(text)}
                className='careclick_icon' src={IMG} />
            </div>
        );
    }



    const CareClicksScreen=({clearClicks})=>{

        const [comments, setComments] = useState("");

        function addCommentAndClose(){
            addCareClick(comments);
            clearClicks("");
        }

        return(
            <div className='careclicks_modal'>
                <div className='careclicks_modal_title'></div>
                <div style={{width:"100%"}}>
                    
                    <div className='careclicks_row'>
                        <ShowCareClick IMG={IMG_PERSONAL} text={"Personal Care"} />
                        <ShowCareClick IMG={IMG_CONTINENCE} text={"Continence"} />
                        <ShowCareClick IMG={IMG_NUTHYDRATION} text={"Nutrition & Hydration"} />
                    </div>
                    <div className='careclicks_row'>
                        <ShowCareClick IMG={IMG_SOCIAL} text={"Social Life"} />
                        <ShowCareClick IMG={IMG_MOVING} text={"Moving & Handling"} />
                        <ShowCareClick IMG={IMG_SLEEP} text={"Sleeping & Rest"} />
                    </div>
                    <div style={{width:"75%"}}>
                        <div style={{fontSize: "12px"}}>Comments</div>
                        <textarea 
                            id="cc_comments"  
                            onChange={(e)=>setComments(e.target.value)}
                            style={{fontSize: "10px",width:"100%", backgroundColor: "grey", color: "white", padding: "2px"}} 
                        />
                    </div>
                    <button  style={{fontSize: "12px"}}
                        onClick={()=>addCommentAndClose()}
                >Submit</button>
                </div>
            </div>
        );

    }

    const MainPhoneBody=({calls})=>{
        return <div>{calls.map((c,i)=><DisplayCall index={i} callDetails={c} careClickRoom={setCareClickRoom} />)}</div>
    }
    
    return (
        <div className='virtualPhone'>
            {(careClickRoom!=="") && <CareClicksScreen clearClicks={setCareClickRoom} />}
            <div className='virtualPhoneHeader'>
                Mobile Handset
            </div>
            <div className='virtualPhoneMain'>
                {
                ((calls===undefined)||(calls.length===0))
                ? <LogoScreen />
                : <MainPhoneBody calls={calls} />
                }
            </div>
            <div className='virtualPhoneFooter'>&nbsp;</div>
        </div>
    );
}


const LogoScreen=()=>{
    return <div><br/><img src={IMG_LOGO} /></div>;
}


const DisplayCall=({index, callDetails, careClickRoom})=>{
    return (
        <>
        <div 
            onClick={()=>{
                if (lCase(callDetails.callType)==="attendance"){
                    careClickRoom(callDetails.room);
                }
            }}
            className={`bg_${lCase(callDetails.callType)} phone_call_container`}>
            <div><GetCallTypeImagePhone imgString={lCase(callDetails.callType)} /></div>
            <div className='phone_content'>
                <div className='phone_calltype'>{callDetails.callType}</div>
                <div className='phone_call_body'>
                    <div>
                        <div className=''>{callDetails.room}</div>
                        <div className=''>{callDetails.zone}</div>
                    </div>
                    <div className='phone_timer'><CallTimer startTime={callDetails.start} /></div>
                </div>     
            </div>

            
{/*
            <div className='panelCallFooter'>
                <div className='panel_call_body_content_txcode'>{callDetails.txCode}</div>
                <div className='panel_call_body_content_timer'>{callDetails.start}</div>
            </div>
*/}
        
        </div>
        </>
    );
}