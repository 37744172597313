import React, { useEffect, useState } from 'react';
import { Card, Divider, Flex, Grid, Icon } from '@tremor/react';
import { ResidentAvatar } from './components/ResidentAvatar';
import { FormInput } from './components/Input';
import { raiseError } from './functions/error_handling';
import { addEditResident, getResidentBasic } from './functions/apiActions';
import { LoadingWait } from './LoadingWait';
import { Icon_edit_circle } from './components/svgIcons';
import SpinnerLoader from './components/SpinnerLoader';

export const IntegrationDetails = ({ residentObject }) => {

  if (residentObject===undefined) return <SpinnerLoader isLoading={true} />;

  return (
    <Card>
      <Grid numItems={1} numItemsSm={1} numItemsLg={2} className="gap-1">
        <div>Title:</div>
        <div>{residentObject.title}</div>
        <div>First name:</div>
        <div>{residentObject.firstName}</div>
        <div>Surname:</div>
        <div>{residentObject.surname}</div>
        <div>Preferred Name:</div>
        <div>{residentObject.preferredName}</div>
        <div>Date of birth:</div>
        <div>{residentObject.dob}</div>
        <div>Gender:</div>
        <div>{residentObject.gender}</div>
        <div>NHS Number:</div>
        <div>{residentObject.nhsNumber}</div>

      </Grid>
      {
      ((residentObject.clientSystemUrl !== undefined)&&(residentObject.clientSystemUrl !== ""))
      &&
      <>
        <Divider />
        <button
          onClick={()=>{
            window.open(residentObject.clientSystemUrl, '_blank');
          }}
        >View in {residentObject.source}</button>
      </>
      }

    </Card>
  );
};
