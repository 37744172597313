export function secondsToHHMMSS(varSeconds){
  if (parseInt(varSeconds)===0) return "00:00";
  if (isNaN(parseInt(varSeconds))) return "00:00";
  if ((varSeconds===undefined)||(varSeconds===null)) return "00:00";
  const hours = Math.floor(varSeconds / 3600);
  const minutes = Math.floor((varSeconds % 3600) / 60);
  const remainingSeconds = varSeconds % 60;

  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  if (formattedHours==="00") return `${formattedMinutes}:${formattedSeconds}`;
  let rtnString = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  console.log({rtnString});
  return (rtnString.length>8) ? "00:00" : rtnString;  
}

export function splitTXCode(txCode){ 
  if ((txCode===null) || (txCode===undefined)) return ["", ""];
  const split = txCode.split(".");
  if (split.length===1) return [split[0], ""];
  return [split[0], split[1]];
}

export function displayDateFromUTC(utcDate){
  if (((utcDate)===undefined)||((utcDate)===null)||((utcDate)==="")||((utcDate)==="invalid-date")) return "";
  let dt = (new Date(utcDate).toLocaleString("en-gb"));
  const dSplit = dt.split(", ");
  return `${dSplit[0]}`;
}


export function displayDateOrTime(utcDate){
  if (((utcDate)===undefined)||((utcDate)===null)||((utcDate)==="")||((utcDate)==="invalid-date")) return "";
  const rtn = displayHHMMFromUTC(utcDate);
  return (rtn==="00:00") ? displayDateFromUTC(utcDate) : rtn;  
}

export function displayDateTimeFromUTC(utcDate){
  let dt = (new Date(utcDate).toLocaleString("en-gb"));
  const dSplit = dt.split(", ");
  return `${dSplit[1]}`;
}

export function displayHHMMFromUTC(utcDate){
  try { return new Date(utcDate).toLocaleTimeString("en-gb").substring(0,5) }
    catch(e){ return ""; }
}

export function displayTimeFromUTC(utcDate){
  try { return new Date(utcDate).toLocaleTimeString("en-gb") }
    catch(e){ return ""; }
}


export function orderArrayByDate(dataArray, fieldVal, oldestFirst){

  //function orderByUTCDateValue(objectArray, dateKey) {
    return dataArray.sort((a, b) => {
      const dateA = new Date(a[fieldVal]);
      const dateB = new Date(b[fieldVal]);
      if (oldestFirst===true) return dateB.getTime() - dateA.getTime();
      return dateA.getTime() - dateB.getTime();
    });
  //}
  //  dataArray.sort((a, b) => a[fieldVal] - b[fieldVal]);
  //  return dataArray;
}


export const roundUp=(val)=>Math.ceil(val);
export const roundDown=(val)=>Math.floor(val);


export function getDifferenceInSeconds(date1, date2, rDown) {
  const timestamp1 = date1 instanceof Date ? date1.getTime() : new Date(date1).getTime();
  const timestamp2 = date2 instanceof Date ? date2.getTime() : new Date(date2).getTime();
  
  const differenceInMilliseconds = Math.abs(timestamp2 - timestamp1);
  const differenceInSeconds = differenceInMilliseconds / 1000;
  return (rDown===true) ? roundDown(differenceInSeconds) : roundUp(differenceInSeconds);
}


function validateDataArray(dataArray){
  if ((dataArray===undefined)||(dataArray.length===0)) return [];
  dataArray = dataArray.filter((d)=>(d!==undefined));
  dataArray = dataArray.filter((d)=>(((d.end_date_time_utc!==undefined)&&(d.end_date_time_utc!=="undefined"))));
  return dataArray;
}

export function getMinMax(callsList){
  callsList = validateDataArray(callsList);
  if (callsList.length===0) return [null, null];  //problem because the call should not have a null start or end date
                                                  //need to investigate the panel code to see why this is happening
  const startObjects = callsList.map(obj => new Date(obj.start));
  let endObjects = null;

  endObjects = callsList.map(obj => new Date(obj.end_date_time_utc));
  
  if (endObjects.length===0) endObjects = callsList.map(obj => new Date(obj.end_date_time));

  const minDate = new Date(Math.min(...startObjects));
  const maxDate = new Date(Math.max(...endObjects));

  const minDateUTC = minDate.toISOString();
  const maxDateUTC = maxDate.toISOString();

  return [minDateUTC, maxDateUTC];
}

export const getCallCount=(arrCalls)=>(arrCalls) ? arrCalls.length : 0;

export function calculatePercentageRoundUp(value, total) {
  const percentage = (value / total) * 100;
  const roundedPercentage = Math.ceil(percentage);
  return roundedPercentage;
}

export function getLastObjectID(obj){
  const keys = Object.keys(obj);
  return keys[keys.length-1];
}

export function getNextObjectID(obj) { 
  const last = getLastObjectID(obj);
  try { return parseInt(last) + 1; }
  catch(e){ return last + 1; }
}

export const hasRoomUnitHasMoveConfig=(unitsList, val)=>(getMoveMatchingRoomUnit(unitsList, val).length>0);

export const getMoveMatchingRoomUnit=(unitsList, val)=>(unitsList.filter(u=>(u.move_id===val)));

export function getEndOfDay(date) {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
}

export function getStartOfDay(date) {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
}

export function getComparison(start, end){
  const date1 = new Date(start);
  const date2 = new Date(end);
  const timeDifference = date2 - date1;
  end = new Date(date1.getTime() - 1);
  start = new Date(date1.getTime() - timeDifference);
  return [start, end];
}


export function getHoursBetweenDates(startDate, endDate) {
  const hoursArray = [];
  const currentHour = new Date(startDate);

  while (currentHour <= endDate) {
    hoursArray.push(new Date(currentHour));
    currentHour.setHours(currentHour.getHours() + 1);
  }

  return hoursArray;
}

export function orderObjectArrayByDate(arr, dateField){
  arr.sort((a, b) => b[dateField] - a[dateField]);
  return arr;
}

export function updateObjectArrayDateFieldFormating(arr, dateField){
  arr = arr.map(v=>{return new Object({
            ...v,
            [dateField] : displayDateFromUTC(v[dateField])
          })
        })
  return arr;
}

export function getInitialsFromName(name){
  if (name===undefined) return ""; 
  const words = name.split(' ');
  let initials = '';
  for (let i = 0; i < words.length; i++) {
    if (words[i].length > 0) {
      initials += words[i][0].toUpperCase();
    }
  }
  return initials;
}

