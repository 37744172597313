
import { useEffect, useState } from "react";
import { Grid, Flex } from "@tremor/react";
import PeopleResidentCard from "../components/Residents/PeopleResidentCard";
import { TextInput } from "@tremor/react";
import { SearchIcon } from "@heroicons/react/solid";
import { ResidentLink } from "../functions/links";
import { getResidentsListAll, getResidentsList } from "../functions/apiActions";
import { AQTag } from "../AQTag";
import { Icon_plus_circle } from "../components/svgIcons";

export default function People({siteID}) {
  
  const [peopleList, setPeopleList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [filterVal, setFilterVal] = useState("");

  useEffect(()=>{
    if (siteID===undefined) return;
    if ((siteID==="")||(siteID===null)) return;
    if (1==1) {
    getResidentsListAll({
      userID:"1",
      siteID: siteID
      }, (d)=>setPeopleList(d));
    }
    else {
      getResidentsList(
        {
        userID:"1",
        siteID: "93"
        },
        (d)=>setPeopleList(d));
      }
  }, [siteID]);

  useEffect(()=>{
    if (filterVal==="") {
      setFilteredList(peopleList);
    }
    else {
      if (peopleList.length===0) setFilterVal([]);
      setFilteredList(
        peopleList.filter(r=>
          (
            (r.firstName.toLowerCase().includes(filterVal.toLowerCase()))
            ||
            (r.surname.toLowerCase().includes(filterVal.toLowerCase()))
          )
        )
      );

    }
  }, [peopleList, filterVal])
  
  return (
    <main className="px-12 py-12">
      <Flex>
        <div></div>  
        <Flex className="flex w-1/2">

          <TextInput icon={SearchIcon} placeholder="Search..." width="100px" onChange={(d)=>setFilterVal(d.target.value)} />

        </Flex>  
        <ResidentLink id="-1"><AQTag textSize="text-sm"><Icon_plus_circle />New Resident</AQTag></ResidentLink>

      </Flex>
  
      <div style={{width:"100%", textAlign:"-webkit-center"}}>
      <div style={{
          width:"600px",
          display:"flex",
          flexDirection:"row"
        }}>

      </div>
      </div>
            
            <PeopleSummaryList list={filteredList} siteID={siteID} />
            <div className="mt-6 gap-6">
            </div>
  
    </main>
  );
}



const PeopleSummaryList=({list, siteID})=>{
  
  if (Array.isArray(list)===false) return(<></>);
  
  return(
    <Grid numItemsLg={3} className="mt-6 gap-6">
      {list.map((r) => <PeopleResidentCard resident={r} siteID={siteID} />)}
    </Grid>
  );
}

