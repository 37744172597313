import {
    Card,
    Text,
    Flex,
} from "@tremor/react";
import { apiPost } from "../../data/apidata";
import { getOnValue, getOnceValue } from '../../data/firebase';
import { useEffect, useState } from "react";

const SenseController=({siteCode, room})=>{

    const [dataObject, setDataObject] = useState(null);

    useEffect(()=>{
        if ((room!=="001")&&(room!=="002")) return;
        getOnValue(
            `${siteCode}_site/config/Vayyar/presence/Main Panel/`,
            (d)=>{ setDataObject(d); },
            (e)=>{console.log("senseErrror", e)}
        );
    }, [room]);

    return (
        <div className="m-5">
            {(room==="001") && <RoomController siteCode={siteCode} room={"Bedroom 1"} code={"id_MDg6M0E6RjI6Mjk6MkY6RTQ"} aqRoomCode={"001"} data={dataObject} status={""} />}
            {(room==="002") && <RoomController siteCode={siteCode} room={"Bedroom 2"} code={"id_Nzg6RTM6NkQ6REY6RTE6QjA"} aqRoomCode={"002"} data={dataObject} status={""} />}
        </div>
    );
}

const RoomController=({siteCode, room, code, status, data, aqRoomCode})=>{

    function updateFirebase(status, roomID){

        apiPost("/sense/status/setstatus",
            {
                "userID" : "adc",
                "siteCode": siteCode,
                "roomRef" : roomID,
                "value" : status
            },
            (d)=>{console.log(d)},
            (e)=>{console.log(e)}
        );
        
        if (status==="fall"){
            setTimeout(()=>raiseCall(aqRoomCode), 5000);
        }

    }    

    function raiseCall(roomID){

        apiPost("/call/live/raise",
            {
                "userID":"",
                "siteID" : siteCode,
                "loginName" : "test@arquella.co.uk",
                "callType": "1",
                "roomCode": roomID,
                "title": "",
                "comments": "",
                "carer": "",
                "virtualCall": true
            },
            (d)=>{console.log(d)},
            (d)=>{console.log(d)}
        );
    }

    function getSelectedClass(deviceCode, status, dataObject){
        if (dataObject===null) return;
        try {
            const val = dataObject[status][deviceCode];
            if (val === true) return "sense_status_selected";
        } catch (e){ console.log(e); }
        return "sense_status_deselected";
    }

    return (
        <Card className="max-w-xl">
            <Text>Sense</Text>
            <Flex flexDirection="col" justifyContent="evenly">
                <button 
                    onClick={()=>updateFirebase("fall", code)}
                    className={getSelectedClass(code, "fall", data)}
                >Fall</button>
{/*
                <button 
                    className={getSelectedClass(code, "manOnGround", data)}
                    onClick={()=>updateFirebase("manOnGround", code)}>On floor</button>
                <button 
                    className={getSelectedClass(code, "bathroom", data)}
                    onClick={()=>updateFirebase("bathroom", code)}>Bathroom</button>
*/}
                <button 
                    className={getSelectedClass(code, "chair", data)}
                    onClick={()=>updateFirebase("chair", code)}>Chair</button>
                <button 
                    className={getSelectedClass(code, "bed", data)}
                    onClick={()=>updateFirebase("bed", code)}>Bed</button>
                <button 
                    className={getSelectedClass(code, "room", data)}
                    onClick={()=>updateFirebase("room", code)}>Room</button>
                <button 
                    className={getSelectedClass(code, "", data)}
                    onClick={()=>updateFirebase("", code)}>Leave Room</button>
            </Flex>
        </Card>
    );
}


export default SenseController;