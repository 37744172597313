import { useState, useEffect } from "react";
import { getOnValue, getOnceValue, getDBRouting } from "../../../data/firebase";
import './monitor.css';
import { removePath } from "../../../data/firebase";


const CallMonitor=({site})=>{

    const [data, setData] =  useState("Loading...");

    useEffect(()=>{

        getDBRouting(site, (db)=>{

            getOnValue(`${site}PhoneLiveCalls`, setData, (err)=>{console.log(err)}, db);

        },
        (e)=>{console.log(e)}
        )

    }, [site]);

    function getKey(dt, key){
        return (dt[key]) ? dt[key] : "" ;
    }


    function removeCall(key){
        const remPath = `${site}PhoneLiveCalls/${key}`;
        console.log("remove", remPath);
        removePath(remPath, (d)=>{console.log(d)}, (e)=>{console.log(e)});
        //remove(`${site}PhoneLiveCalls/${key}`)
    }

    if (data==="Loading...") return (<div>{data}</div>);
    if (data===null) return (<div>No live calls in the database.</div>);
<div>{}</div>
    return(
        <div>
            <div className="callmonitor_call_container m-2">
                <div className="m-2 bold">Code</div>
                <div className="m-2 bold">Room&nbsp;</div>
                <div className="m-2 bold">Start&nbsp;</div>
                <div className="m-2 bold">TX Code&nbsp;</div>
                <div className="m-2 bold">Call Type&nbsp;</div>
                <div className="m-2 bold">&nbsp;</div>
            </div>

            {
                Object.keys(data).map((key)=>{
                    return(
                        <div className="callmonitor_call_container m-2" 
                            onClick={()=>{console.log(data[key])}}
                        key={key}>
                            <div className="m-2">{key}</div>
                            <div className="m-2">{getKey(data[key], "room")}</div>
                            <div className="m-2">{getKey(data[key], "start")}</div>
                            <div className="m-2">{getKey(data[key], "txCode")}</div>
                            <div className="m-2">{getKey(data[key], "callType")}</div>
                            {
//                                <div className="m-2"><ShowSites dt={data[key]}/></div>
                            }
                            <button 
                                onClick={()=>removeCall(key)}
                            >Remove</button>
                        </div>
                    )
                })
            }
        </div>
    );
}



const ShowSites=({dt})=>{
    return(
        <div>
     {
     Object.keys(dt).map((key)=>{
                    return(
                        <div className="flex-row" key={key}>
                            <div>{key}: &nbsp;&nbsp;&nbsp;</div>
                            <div>{dt[key]}</div>
                        </div>
                    )
                })
        }
        </div>
    )
}

const ShowRecs=({dt})=>{
    
    return (<div>
        {Object.keys(dt).length}

    </div>);

    return(
        <div>
     {
     Object.keys(dt).map((key)=>{
                    return(
                        <div key={key}>
                            <div>{key}</div>
                            {
                                
                            }
                        </div>
                    )
                })
        }
        </div>
    )
}

export default CallMonitor;