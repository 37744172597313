import { initializeApp, getApp } from 'firebase/app';
import { getDatabase, onValue, ref, get, set, push, remove} from "firebase/database";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyCOXJtROQlSTEJwcB7WTOx97znL4HswvUg",
    authDomain: "arquella-cc76e.firebaseapp.com",
    databaseURL: "https://arquella-cc76e.firebaseio.com",
    projectId: "arquella-cc76e",
    storageBucket: "arquella-cc76e.appspot.com",
    messagingSenderId: "673271376643",
    appId: "1:673271376643:web:b55b0f03b976e5d0c21736",
    measurementId: "G-10LF2R4MV2"
  };


// Initialize Firebase

const routingPath = "https://arquella-cc76e-b7352.europe-west1.firebasedatabase.app";
const newDbPath =   "https://arquella-cc76e-afe91.europe-west1.firebasedatabase.app";
                     

export const app = initializeApp(firebaseConfig);
export const dbDefault = getDatabase(app);
export const dbNew = getDatabase(app, newDbPath);
export const dbRouting = getDatabase(app, routingPath);


export const auth = getAuth(app);

export const login=(userName, password)=>signInWithEmailAndPassword(auth, userName, password);

export function getDBRouting(site, returnOnValue, returnOnError) {
    const dbRef = ref(dbRouting, "panels");
    onValue(dbRef, 
        (snapshot)=>{ 
            let rtnVal = "https://arquella-cc76e-b7352.europe-west1.firebasedatabase.app/";
            const d = snapshot.val();
            console.log("routing snapshot", d);
            if (d===undefined) returnOnValue(rtnVal);
            if (d===null) returnOnValue(rtnVal);
        
            const filtered = Object.values(d).filter(st=>(st.siteRef===site));
            if (filtered.length>0){
                returnOnValue(filtered[0].db);
            }
            else {
                returnOnValue(rtnVal);
            }
        },
        (error)=>{ returnOnError(error); }
    );
}

function removeTrailingSlash(inputString) {
    if (inputString===undefined) return "";
    //console.log({inputString})
    // Check if the last character is a forward slash
    if (inputString.charAt(inputString.length - 1) === '/') {
        // Remove the last character
        return inputString.slice(0, -1);
    } else {
        // Return the original string if the last character is not a forward slash
        return inputString;
    }
}

function getDBObject(db) {
    db = removeTrailingSlash(db).toString(); 
    console.log("getdbOBKECT");
    console.log(`:: ${db}`);
    console.log(`:: ${newDbPath}`);
    console.log(removeTrailingSlash(db)===newDbPath);
    if (removeTrailingSlash(db)===newDbPath) return dbNew;
    if (removeTrailingSlash(db)===newDbPath) console.log("NEW DB MATCH");
    return dbDefault;
}


export function getOnValue(strDBRef, returnOnValue, returnOnError, db){
    const database = getDBObject(db);
    const dbRef = ref(database, strDBRef);
    onValue(dbRef, 
        (snapshot)=>{ returnOnValue(snapshot.val()); },
        (error)=>{ returnOnError(error); }
    );
}


export function getOnceValue(strDBRef, returnOnValue, returnOnError){
    const database = getDatabase(app);
    const dbRef = ref(database, strDBRef);

    get(dbRef)
        .then((snapshot)=>{ returnOnValue(snapshot.val()); })
        .catch((error)=>{ returnOnError(error); });
}

export function removePath(strDBRef, returnOnValue, returnOnError){
    const database = getDatabase(app);
    const dbRef = ref(database, strDBRef)
    if (strDBRef==="") return;
    if (strDBRef==="/") return;
    remove(dbRef);
}


export function setValue(strDBRef, value, returnOnValue, returnOnError){
    const database = getDatabase(app);
    const dbRef = ref(database, strDBRef);
    if (strDBRef==="") return;
    if (strDBRef==="/") return;
    set(dbRef, value)
        .then(()=>{returnOnValue("Complete")})
        .catch((error)=>{ returnOnError(error); });
}

export function pushValue(strDBRef, value, returnOnValue, returnOnError){
    const database = getDatabase(app);
    const dbRef = ref(database, strDBRef);
    if (strDBRef==="") return;
    if (strDBRef==="/") return;
    push(dbRef, value)
        .then(()=>{ returnOnValue("Success"); })
        .catch((error)=>{ returnOnError(error); });
}