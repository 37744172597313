import { 
//  BarList, 
  Card, Title, Bold, Flex, Text } from "@tremor/react";
import { apiPost } from "../../data/apidata";
import { useState, useEffect } from "react";
import IMG_HYDRATION from "../../images/care_clicks/nutrition_hydration.png";
import IMG_CONTINENCE from "../../images/care_clicks/continence.png";
import IMG_MOVING from "../../images/care_clicks/moving.png";
import IMG_PERSONAL from "../../images/care_clicks/personal_care.png";
import IMG_SLEEP from "../../images/care_clicks/sleep_rest.png";
import IMG_SOCIAL from "../../images/care_clicks/social.png";
import SpinnerLoader from "../SpinnerLoader";
import BarList from "../charts/BarList";

export default function TotalCallsByRoom({postFilters}){

  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const postUrl = "dashboard/place/totalcallsbyroom";


  useEffect(()=>{ 
    if (!isLoading) setIsLoading(true);
    if (Object.keys(postFilters).length===0) return;
    apiPost(postUrl, postFilters, setData, setError); 
  },[postFilters]);


  useEffect(()=>{ 
    if (typeof(data)==="string") return; /// ADC return error later on when have error handler 
    const newData = data.map((d)=>{
      return({
        name: d.name,
        value: d["Total Calls"],
        //      href: "https://twitter.com/tremorlabs",
        /*icon: function () {
          return (<img src={IMG_HYDRATION} className="reports_careclicks" />);        
        }
        */
        
      })
    });
    setIsLoading(false);
    setChartData(newData);
   },[data]);


  return (
    <Card className="max-w-lg">
      <Title>Total Calls By Room</Title>
      <Flex className="mt-4">
        <Text>
          <Bold>Source</Bold>
        </Text>
        <Text className="pr-6">
          <Bold>Calls</Bold>
        </Text>
      </Flex>
      {
        isLoading 
        ? <SpinnerLoader isLoading={isLoading} />
        :
        <div className="h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-200 scrollbar-thumb-rounded-full pr-6">
          <BarList data={chartData} className="mt-2" />
        </div>
      }
      
    </Card>
  );
}