import { Card, Title } from "@tremor/react";
import { useState, useEffect } from "react";
import { Accordion, AccordionHeader, AccordionBody, AccordionList } from "@tremor/react";
import { GetSenseImage } from "../GetImage";
import { getOnValue, getOnceValue } from "../../data/firebase";

export default function DavyMonitor({sitesList}){

  const [calls, setCalls] = useState([]);
  const [masterCalls, setMasterCalls] = useState({});
  const [config, setConfig] = useState({});

  function updateValues(data){
    //console.log({data});
    setMasterCalls(data);
  }

  function handlError(error){
    console.log(error);
  }

  function processMasterCalls(){
    const tmpsCalls = [];
    console.log({config});
    Object.keys(masterCalls).forEach((k)=>{ 
      Object.keys(masterCalls[k]).forEach(c=>{
        tmpsCalls.push(
          {
            "vID": c,
            "location": k,
            "room": getVayyarRoomFromID(c),
            "value": (masterCalls[k][c]===true) ? "TRUE" : "FALSE"
          }
        );
      });
    });
    setCalls(tmpsCalls);
  }

  function getVayyarRoomFromID(id){
    const matches = config.filter((c)=>(c.vayyarUnit===id));
    if (matches.length===0)return id;
    return matches[0].roomName;
  }

  function trimVayyarRef(ref){
    ref = ref.replace("/devices/","");
    ref = ref.replace("/events","");
    return ref;
  }

  function getConfig(site){
    if (site==="") return;
    getOnceValue(site + "_unitconfig", (d)=>{
      const tmpValue = [];
      d.map((val)=>{
        tmpValue.push(
          {
            "vayyarUnit": trimVayyarRef(val.vayyarId),
            "roomName": val.vayyarName,
            "zoneName": val.vayyarZone
          }
        );
      });
      setConfig(tmpValue);
      getSiteData(site);
    },
    (e)=>{ console.log(e); }
    )
  }

  function getSiteData(site){
    getOnValue(site + "_site/config/Vayyar/presence/Main Panel/", updateValues, handlError);
  }

  function resetSitesList(){
    if (sitesList.length===0) return;
    sitesList.map(site=>{
      if (site==="") return;
      getConfig(site);
    });
  }

  useEffect(()=>resetSitesList(),     [sitesList]);
  useEffect(()=>processMasterCalls(), [masterCalls]);

  return(
  <div>
    <Title className="upper_case">Davy Monitor</Title>
    <br />
    {calls.map((c)=><CallCard data={c} />)}
  </div>
)};

const CallCard=({data})=>{
  console.log({data});
  return (
    <div>

        <CallHeader data={data} />
    
    </div>
  );
}

const CallHeader=({data})=>{
  
  return (
    <div 
      className="davy_monitor"
    >
        <div>{data.location}</div>  
        <div>{data.room}</div>
        <div>{data.vID}</div>
        <div>{data.value}</div>
    </div>
  );
}
