import React, { useEffect, useState } from 'react';
import { select, drag, selectAll } from 'd3';
import { lCase } from '../../functions/string';
import './FloorPlan.css';
import { AQModal } from '../Modal/Modal';
import RoomUnitSingle from '../../Pages/VirtualEnvironment/RoomUnit';
import IMG_MOVE from '../../images/move/Move.png';
import { getOnceValue } from '../../data/firebase';
import { raiseError } from '../../functions/error_handling';

const FloorPlan=({siteCode, staffMovementCallBack, carer, callsList, raiseCall, clearCall, lastCarerSelectedCallBack})=>{
  
  let mousePointerRoom = "";
  const [lastRoom, setLastRoom] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [carerMenu, setCarerMenu] = useState(null);
  const [floorUpdated, setFloorUpdated] = useState(new Date())
  const [carersList, setCarersList] = useState({});
  const [roomConfig, setRoomConfig] = useState([
      { code: "001", name: "Bedroom 1", x:18, y:154, w:113, h:65, tx:72, ty:184},
      { code: "002", name: "Bedroom 2", x:18, y:219, w:113, h:70, tx:72, ty:249 },
      { code: "003", name: "Bedroom 3", x:18, y:298, w:112, h:67, tx:72, ty:328 },
      { code: "004", name: "Bedroom 4", x:18, y:366, w:136, h:89, tx:84, ty:406 },
      { code: "005", name: "Bedroom 5", x:157, y:366, w:75, h:89, tx:193, ty:406 },
      { code: "006", name: "Bedroom 6", x:185, y:154, w:105, h:67, tx:235, ty:184 }      
    ]);
  

  function updateCarerPos(carerID, carerName, x, y){
    const carerRoom = carersList[carerID].room;
    const o3 = {...carersList, [carerID]: {name: carerName, room: carerRoom,  x: x, y: y}};
    setCarersList(o3);
  }

  function roomHasCarer(roomCode){
    return Object.values(carersList).filter(c=>c.room===roomCode).length>0;
  }

  function updateCarerRoom(carerID, room, x, y){
    
    const carerObj = new Object(carersList[carerID]);
    const oldRoom = carerObj["room"].toString();

    if (oldRoom !== room) {
      
      carerObj["room"] = room;
      carerObj["x"] = x;
      carerObj["y"] = y;
      
      const o3 = {...carersList, [carerID]: carerObj};
      setCarersList(o3);
      if (room=="") {
        if (roomHasCarer(oldRoom)) return;
        clearCall(oldRoom, carerObj["name"]);
      }
      else {
        raiseCall(room, carerObj["name"]);
        if (oldRoom!==""){
          if (roomHasCarer(oldRoom)) return;
          clearCall(oldRoom, carerObj["name"]);
        }
      }
    } 
  }

  function handleDragStart(event) {
    event.dataTransfer.setData('text/plain', event.target.id);
  }

  function handleDragOver(event) {
    event.preventDefault();
  }

  function getRoomPositions(index){

    if (index===0) return { x:18, y:154, w:113, h:65, tx:72, ty:184};
    if (index===1) return { x:18, y:219, w:113, h:70, tx:72, ty:249 };
    if (index===2) return { x:18, y:298, w:112, h:67, tx:72, ty:328 };
    if (index===3) return { x:18, y:366, w:136, h:89, tx:84, ty:406 };
    if (index===4) return { x:157, y:366, w:75, h:89, tx:193, ty:406 };
    if (index===5) return { x:185, y:154, w:105, h:67, tx:235, ty:184 }; 

  }

  function buildRoomsFromConfig(){
    
    getOnceValue(`${siteCode}config`, 
    (d)=>{
      const tmpVals = [];

      Object.values(d).forEach((v,i)=>{
        if (i>5) return; 
        let pushVal = {
          code: v.unitId,
          name: v.unitName,
          ...getRoomPositions(i)
        };  
        tmpVals.push(pushVal);
      });
      setRoomConfig(tmpVals);
    }, 
    (e)=>raiseError("d", "buildRoomsFromConfig", "buildRoomsFromConfig", e.toString()));
  }


  function handleClick(event){
    if (carerMenu!==null) {
      setCarerMenu(null);
      return;
    }
    event.preventDefault();
    let room = event.target.getAttribute("room");
    if (room!==null) setSelectedRoom(room);
  }

  function handleDrop(event) {
    event.preventDefault();
    const personId = event.dataTransfer.getData('text/plain');
    const svg = select('.floor-plan-svg');
    
    const { offsetX, offsetY } = event.nativeEvent;
    svg
      .append('circle')
      .attr('cx', offsetX)
      .attr('cy', offsetY)
      .attr('r', 10)
      .attr('fill', 'blue')
      .attr('class', 'person');
  }

  function getConfigRooms(){
    return roomConfig;
  }


  function renderFloorPlan() {

    const svg = select('.floor-plan-svg');
    svg.selectAll('*').remove();
    
    svg.append("svg:image")
    .attr("xlink:href", IMG_MOVE)
    //.attr("width", width)
    //.attr("height", height);

    
    const rooms = getConfigRooms();

    function getColVal(index){
      if ((index==0)||(index==3)) return 5;
      if ((index==1)||(index==4)) return 250;
      if ((index==2)||(index==5)) return 495;
    }
    

    
    
/*  svg.append("polygon")
  .attr("points", function(d) {
    return rooms[0].shape.map(function(point) {
      return [point.x, point.y].join(",");
    }).join(" ");
  })
  .attr("fill", "blue"); 
*/

    
    rooms.forEach((r,i)=>{
      const row = (i > 2) ? 275 : 5;
      const col = getColVal(i);
      const room = drawRoomObject(svg, r.x, r.y, r.w, r.h, r.tx, r.ty, r.code, r.name);
    });
    
    Object.keys(carersList).forEach(k=>{
      const staff1 = drawStaff(svg, carersList[k].x, carersList[k].y, 10, k, carersList[k]);
      staff1.call(dragStaffHandler);
    })
  }
  
  function dragEndCheckForRoom(carerID){
    if (mousePointerRoom!=="") { setLastRoom(mousePointerRoom); }
    staffMovementCallBack(mousePointerRoom, lastRoom);
  }

  useEffect(()=>{ 
    console.log("CallsList");
    console.log({callsList});  
    renderFloorPlan(); 
  },[carer, callsList, floorUpdated, roomConfig])

  useEffect(()=>{ buildRoomsFromConfig(); },[siteCode]);

  const dragStaffHandler = drag()
  .on("drag", function(e) {
    
    const newX = e.x;
    const newY = e.y;
    const carerID = this.getAttribute("carerID");
    const carerName = this.getAttribute("carerName");
       
    select(this)
      .attr("cx", newX)
      .attr("cy", newY)
      .attr('pointer-events', 'none');
    
    updateCarerPos(carerID, carerName, (newX), newY);
    
    const carerTextID = "#carer_text_" + carerID;
    
    select(carerTextID)
      .attr("x", (newX +38))
      .attr("y", newY)
      .attr('pointer-events', 'none');

  })
  .on("end", function(e) {

    select(this).attr('pointer-events', null);
    const carerID = this.getAttribute("carerID");
    updateCarerRoom(carerID, mousePointerRoom, e.x, e.y);
    
  });



const drawRoomObject=(svg, x, y, w, h, tx, ty, id, roomText)=>{
    const g = svg;//.append("g")

      drawRoom(g, x, y, w, h, id);
      drawText(g, tx, ty, roomText);

}

const drawRoom=(obj, x, y, w, h, id)=>{
  obj.append("rect")
    .attr("x", x)
    .attr("y", y)
    .attr("width", w)
    .attr("height", h)
    .attr("fill", getRoomColour(id))
    .attr("id", id)
    .attr("room", id)
    .on('mouseenter', d => { mousePointerRoom = id; })
    .on('mouseleave', d => { mousePointerRoom = ""; })
    ;
}

function checkCode(code){
  if (code.length===3) return code;
  if (code.includes(".")) return code.split(".")[0];
  return code;
}

function getRoomColour(roomCode){

  roomCode = checkCode(roomCode);
  console.log("roomCode",roomCode);
  try {
    const callT = lCase(callsList.filter(c=>(checkCode(c.unitId)===roomCode))[0].callType);
    
    if (callT==="emergency") return "red";
    if (callT==="call") return "rgb(245, 129, 78)";
    if (callT==="sense") return "#914397";
    if (callT==="attendance") return "rgb(148, 202, 102)";
    if (callT==="accessory") return "#914397";
    if (callT==="assistance") return "#F8DA3C";
    if (callT==="carecall") return "rgb(225, 21, 131)";
  }
  catch(e){ 
    /* console.log(e); */
  }
  
  return "lightgrey";
}

const drawText=(obj, x, y, text, id)=>{
  let txt = obj.append("text")
      .attr("x", x)
      .attr("y", y)
      .attr("dy", ".35em")
      .attr("id", id)
      .attr("text-anchor", "middle")
      .attr('pointer-events', 'none')
      .attr("font-size", "11px")
      .text(text);

//      txt.raise();

}

const drawStaff=(svg, x, y, radius, carerID, carerObject)=>{
  
    let staff = svg.append('circle')
                  .attr('cx', x)
                  .attr('cy', y)
                  .attr('r', radius)
                  .attr('fill', 'red')
                  .attr('class', 'floorplan_staff')
                  .attr('carerID', carerID)
                  .attr('carerName', carerObject.name)
                //.attr('draggable', true)
                //.attr('id', (d, i) => `person-${i}`)
                //.on('dragstart', this.handleDragStart)
                ;
            drawText(svg, (x+38), y, carerObject.name, "carer_text_"+ carerID);
          
  return staff;
}



  function handleRightClick(e){
    e.preventDefault();
    setCarerMenu({ x: (e.clientX), y: (e.clientY)});
  }

  function onClose(){setCarerMenu(null)}

  function getSVGPostion(){
    const svg = document.getElementById("vr_floor_plan");
    const rect = svg.getBoundingClientRect();
    return [rect.left, rect.top];
  }

  function carerAdded(name, x, y){
    const [backX, backY] = getSVGPostion();
    const count = Object.keys(carersList).length;
    const o3 = {...carersList, [count.toString()]: {name: name, room: "",  x: (x-backX), y: (y-backY)}};
    setCarersList(o3);
    setFloorUpdated(new Date());
  }
  console.log({carersList});
  if (carerMenu!==null)console.log(carerMenu.x);
  return (
    <div className="floor-plan-container">
      {(selectedRoom!=="")
      &&
      <AQModal show={true} onClose={()=>{ setSelectedRoom("") }} title="Room Unit">
        <RoomUnitSingle siteCode={siteCode} selectedRoomCode={selectedRoom} carer={"ADC"} />
      </AQModal>
      }
      {carerMenu!==null&&<AddCarerWindow onClose={onClose} newCarer={carerAdded} x={carerMenu.x} y={carerMenu.y} ></AddCarerWindow>}
      <svg
        id="vr_floor_plan"
        //width="800px"
        className="floor-plan-svg"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleClick}
        onContextMenu={(e)=>handleRightClick(e)}
      ></svg>
      
    </div>
  );
  
}


const AddCarerWindow=({x, y, onClose, newCarer})=>{
  const [carerName, setCarerName] = useState("");
  const style = {
    left:`${x}px`,
    top:`${y}px`,
    position:"fixed"
  }

  return (
    <div 
    style={style}
    className="hs-tooltip-content hs-tooltip-shown:opacity-100 z-10 max-w-xs hs-tooltip-shown:visible transition-opacity bg-white border border-gray-100 text-left rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700" role="tooltip"
    >
      {/*<button onClick={()=>onClose()}>X</button>*/}
      {/*<span class="pt-3 px-4 block text-lg font-bold text-gray-800 dark:text-white">New Carer</span>*/}
      <div className="py-3 px-4 text-sm text-gray-600 dark:text-gray-400">
        <input onChange={(e)=>setCarerName(e.target.value)} placeholder='Carer Name'></input>
        <button
          onClick={()=>{
            if (carerName==="") return;
            newCarer(carerName, x, y);
            onClose();
          }}
        >Create Carer</button>
      </div>
    </div>
  );

}


const configFloorPlan = {
  roomSelectionRadius: 5,
  roomHeight: 200,
  roomWidth: 150
}


export default FloorPlan;



