import { useEffect } from "react";
import { Grid, Card, Flex } from "@tremor/react";
import { AQModal } from "../../components/Modal/Modal";
import { useParams } from 'react-router-dom';
import { GroupFormBasic } from "./GroupFormBasic";


export const SiteForm = () => {
  const { id } = useParams();
  
  if (id==="-1") return( 
    <div style={{width:"50%", alignItems:"center"}}>
      <Card><GroupFormBasic groupID={id} /></Card>
    </div>
  );

  return (  
    <div className="mt-6">
      
      <Grid numItemsMd={2} className="mt-6 gap-6">
        <Card>
          <GroupFormBasic groupID={id} />
        </Card>
        {(id>0) ? <Card> <div className="h-28" /></Card> : <NewRecordText />}
      </Grid>
      {
      (id>0)
        && 
        <Grid numItemsMd={2} className="mt-6 gap-6">
          <Card>
            <div></div>
          </Card>
          <Card>
            <div className="h-28" />
          </Card>
        </Grid>
      }
    </div>

  );

};


const NewRecordText =()=><div className="h-28">
                          <p>Create a new Care Group.</p>
                          <p>A Care Group can have a single or multiple sites added to them.</p>
                          <p>This can be used for the initial contact with the care group and to demo the system to them.</p>
                          <p>This will also be used to continue their journey with Arquella if they wish to continue.</p>
                        </div>;