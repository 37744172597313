import {
  Card,
  Text,
  BadgeDelta,
  Flex,
  Metric,
  ProgressBar,
  DeltaBar
} from "@tremor/react";
import { secondsToHHMMSS } from "../functions/dataHandler";
import { useState } from "react";
import { DataBreakDown } from "./DataBreakDown";


export default function DashboardCard({item, postFilters}) {
  
  const [callDataVal, setCallDataVal] = useState(false);

  const GetItem=()=>{
    //if (item.title==="Emergency") return <EmergencyCard item={item} />;
    //if (item.title==="Attendance") return <AttendanceCard onClick={updateBreakdown} item={item} />;
    return <AttendanceCard onClick={updateBreakdown} item={item} />;
  }
  
  function updateBreakdown(){ setCallDataVal(!callDataVal); }

  
  return (
    <div 
      //onClick={()=>updateBreakdown(item.title)}
      className="card_click_listener">
      {(callDataVal===true) && <DataBreakDown 
      preData={(item.rawData)?item.rawData:[]}
      //filters={{ ...postFilters, ["callType"]: item.title }} 
      onClose={updateBreakdown} />}
      <GetItem />
    </div>
  );
  
  
  //<EmergencyCard item={item} />;
}

const EmergencyCard=({item})=>{

  return(

    <Card key={item.title}>
              <Flex alignItems="start"> 
                <div className="truncate">
                  <Text>{item.title}</Text>
       {//}           <Metric className="truncate">{item.metric}</Metric>
}
</div>
         {//       <BadgeDelta deltaType={item.deltaType}>{item.delta}</BadgeDelta>
}
         </Flex>
              <Flex className="mt-4 space-x-2">
                <Text className="truncate">{`${secondsToHHMMSS(item.progress)}`}</Text>
                <Text>{secondToMin(item.target)}</Text>
              </Flex>
{/*              <DeltaBar value={(item.progress-item.target)} isIncreasePositive={false} className="mt-3" /> */}
              <ProgressBar 
                value={calculatePercentage(item.progress, item.target)} 
                target={item.target} 
                color="teal" 
                className="mt-3" 
              />
              {/* <ProgressBar value={item.progress} className="mt-2" /> */}
            </Card>

  );
}


function calculatePercentage(top, bottom){
  return (top/bottom)*100;
}

function increaseIsPositive(callType){
  if (callType=="Attendance") return true;
  return false;
}

const AttendanceCard=({item, onClick})=>{
  return(

    <Card
    onClick={onClick}
    key={item.title}>
              <Flex alignItems="start"> 
                <div className="truncate">
                  <Text>{item.title}</Text>
                  <Metric className="truncate">{item.metric}</Metric>

  </div>
{//}                <BadgeDelta isIncreasePositive={increaseIsPositive(item.title)} deltaType={item.deltaType}>{item.delta}</BadgeDelta>
}
  </Flex>
              <Flex className="mt-4 space-x-2">
                <Text className="truncate">{`${secondToMin(item.progress)}`}</Text>
                <Text>{secondToMin(item.target)}</Text>
              </Flex>
              {/*<DeltaBar value={(item.progress-item.target)} isIncreasePositive={true} className="mt-3" />*/}
              <ProgressBar 
                value={calculatePercentage(item.progress, item.target)} 
                target={item.target} 
                color="teal" 
                className="mt-3" 
              />
              {/* <ProgressBar value={item.progress} className="mt-2" /> */}
            </Card>

  );
}

function secondToMin(secs){
  var date = new Date(1970,0,1);
  date.setSeconds(secs);
  return date.toTimeString().replace(/.*(\d{2}:\d{2}).*/, "$1");
}


