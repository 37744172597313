import {
  Card,
  Text,
  BadgeDelta,
  Flex,
  ProgressBar
} from "@tremor/react";

function getName(preferredName, firstName, surname) {
  if ((preferredName===null)|| (preferredName==="")|| (preferredName==="null")) {
    return firstName + " " + surname;
  } 
  return preferredName;
  
}

export default function ResidentCard({item, setActiveResident}) {
  console.log("residentdata", item);
    return(

        <Card key={item.title}
          onClick={()=>setActiveResident(item.id)}
        >
          <Flex alignItems="start">
                    <div className="truncate">
                      <Text>{getName(item.preferredName, item.firstName, item.surname)}</Text>
                      
{/*
                      <Text>Calls: {item.metric}</Text>
                      <Text>Carescore: {item.metric}</Text>
*/}
                      <br />
                      <Text>{item.title}</Text>
                    </div>
                    {/*
                    <div className="truncate">
                      <BadgeDelta deltaType={item.deltaType}>{item.delta}</BadgeDelta>
                      <BadgeDelta deltaType={"moderateDecrease"}>DNR</BadgeDelta>
                    </div>
    */}
          </Flex>
          {/*
                  <Flex className="mt-4 space-x-2">
                    <Text className="truncate">Care delivered: {`${item.progress}% (${item.metric})`}</Text>
                    <Text>{item.target}</Text>
                  </Flex>
                  <ProgressBar value={item.progress} className="mt-2" />
  */}
{/*                  <Callout
                    className="h-12 mt-4"
                    title="Critical speed limit reached"
                    //icon={ExclamationIcon}
                    color="rose"
                  >
                    Turbine reached critical speed. Immediately reduce turbine speed.
                  </Callout>
*/}
                  <div><small>[edit]</small></div>
                </Card>

    );
}