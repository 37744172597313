import { 
//  BarList, 
  Card, Title, Bold, Flex, Text } from "@tremor/react";
import { apiPost } from "../../data/apidata";
import { useState, useEffect } from "react";
import { raiseError } from "../../functions/error_handling";
import { placeCallsByType } from "../../functions/apiActions";
import SpinnerLoader from "../SpinnerLoader";
import BarList from "../charts/BarList";
import { DataBreakDown } from "../DataBreakDown";

export default function TotalCallsByType({postFilters}){

  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [breakdata, setBreakData] = useState(null);

  useEffect(()=>{ 
    if (!isLoading) setIsLoading(true);
    if (Object.keys(postFilters).length===0) return;
    placeCallsByType(postFilters, setData); 
  
  },[postFilters]);

  useEffect(()=>{setIsLoading(false)},[data]);

  useEffect(()=>{ 
    if (typeof(data)==="string") return; /// ADC return error later on when have error handler 
    const newData = data.map((d)=>{
      return({
        name: d.name,
        value: d["Total Calls"],
        rawData: d.rawData        
      })
    });
    setChartData(newData);
   },[data]);

  function setBreakDownData(data){ setBreakData(data); }
  function clearBreakDownData(){ setBreakData(null); }

  return (
    <Card className="max-w-lg">
      {(breakdata!==null) && <DataBreakDown data={breakdata} onClose={clearBreakDownData} />}
      <Title>Total Calls By Type</Title>
      {
        isLoading 
        ? <SpinnerLoader isLoading={isLoading} />
        :
      <div className="h-80 overflow-y-auto pr-6">
        <Flex className="mt-4">
          <Text>
            <Bold>Source</Bold>
          </Text>
          <Text className="pr-6">
            <Bold>Calls</Bold>
          </Text>
        </Flex>
        <BarList showAnimation={true} data={chartData} dataBreakDownClick={setBreakDownData} className="mt-2" /> 
      </div>
      }
    </Card>
  );
}