
import { useEffect, useState } from "react";
import { apiPost } from "../../data/apidata";
//import { DateRangePicker, DateRangePickerItem, DateRangePickerValue } from "@tremor/react";
import {
    Card,
    Grid,
    Title,
    Text,
    Tab,
    TabList,
    TabGroup,
    TabPanel,
    TabPanels,
    TextInput
  } from "@tremor/react";
import { raiseError } from "../../functions/error_handling";

export default function Resident({siteID, residentID, onUpdated}) {

    const [residentDetails, setResidentDetails] = useState(null);

    
    function setError(err){
      raiseError("", "Resident", "", err.toString());
    }

    useEffect(()=>{

      if (residentID<=0) return;
      apiPost(
        "/residents/get_resident",
        {
          "userID": "your arquella email address",
          "siteID": `${siteID}`,
          "residentID": residentID
        }
        ,
        (d)=>setResidentDetails(d[0]),
        (e)=>setError(e)
      );


    }, [residentID]);

    /*
  function getResidentDetails(){

    apiPost(
      "/residents/list", 
      {
        "userID": "your arquella email address",
        "siteID": "93"
      },
      (d)=>setResidentList(d),
      (e)=>setErrors(e.toString()),
    )

  }

  function newResident(){
    setOpenResident(-1);
  }

  function closeResident(){
    setOpenResident(-2);
  }

  useEffect(()=>{
    return;
    if ((residentID===null)||(residentID==="")) return;
    getResidents();
  }, [residentID]);
  */

  function getHeaderDetails(name, residenID){
    if (residenID===-1) return "New resident";
    return name;
  }


  return (
    <main>
        <Title>{getHeaderDetails("", residentID)}</Title>
        {(residentID===-1) && <ResidentDetailsEdit siteID={siteID} residentDetails={{}} onSubmitted={onUpdated} residenID={residentID} />}
        {(residentDetails!==null) && <ResidentDetailsEdit siteID={siteID} residentDetails={residentDetails} onSubmitted={onUpdated} residenID={residentID} />}
   </main>
  );
}

const ResidentDetailsEdit=({siteID, residentDetails, onSubmitted, residenID})=>{

    const [formData, setFormData] = useState(
        {
            residentID: residenID,
            firstName: (residenID===-1) ? "" : residentDetails.firstName,
            firstNameError: "",
            surname: (residenID===-1) ? "" : residentDetails.surname,
            surnameError: "",
            preferredName: (residenID===-1) ? "" : residentDetails.preferredName,
            preferredNameError: "",
            dateOfBirth: (residenID===-1) ? "" : residentDetails.dateOfBirth,
            dateOfBirthError: "",
            nhsNumber: (residenID===-1) ? "" : residentDetails.nhsNumber,
            nhsNumberError: "",
            roomCode: (residenID===-1) ? "" : residentDetails.roomCode,
            roomCodeError: ""
        });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };
        /*
    const handleSubmit = (event) => {
        event.preventDefault();
        alert(`Name: ${formData.name}, Email: ${formData.email}, Message: ${formData.message}`
    );
    */
    
/*
<TextInput placeholder="Disabled" disabled={true} />
*/

    const GetFormText=({formField})=>{
        return(
            <TextInput
                id={formField}
                name={formField}
                error={(formData[formField + "Error"]!=="")} 
                errorMessage={formData[formField + "Error"]}
                value={formData[formField]}
                onChange={handleChange} 
                placeholder={(formData[formField]==="") ? "..." : ""} />
        );
    }

    function handleForm(){
        //add new resident

        console.log("handleform", {
          "userID": "your arquella email address",
          "siteID": `${siteID}`,
          "residentID" : formData.residentID,
          "firstName" : formData.firstName,
          "surname" : formData.surname,
          "preferredName" : formData.preferredName,
          "dateOfBirth" : formData.dateOfBirth,
          "nhsNumber" : formData.nhsNumber
      });
        apiPost("/residents/add_resident",
            {
                "userID": "your arquella email address",
                "siteID": `${siteID}`,
                "residentID" : formData.residentID,
                "firstName" : formData.firstName,
                "surname" : formData.surname,
                "preferredName" : formData.preferredName,
                "dateOfBirth" : formData.dateOfBirth,
                "nhsNumber" : formData.nhsNumber,
                "roomCode" : formData.roomCode
            },
            (d)=>{
              console.log("submittedres", d);
              onSubmitted();
            },
            (e)=>()=>{console.log("error poset", e)}
        )
        
    }

    function updateDOB(val){
      console.log(val);
      setFormData((prevFormData) => ({ ...prevFormData, ["dateOfBirth"]: val }));
    }

    return(
        <div className="flex">
            
            <Card>

                <div>
                    
                    <TextInput
                        id="firstName" 
                        name="firstName" 
                        error={(formData.firstNameError!=="")} 
                        errorMessage={formData.firstNameError}
                        value={formData.firstName}
                        onChange={handleChange} 
                        placeholder={(formData.firstName==="") ? "First name..." : ""} />
    
                    <TextInput 
                        id="surname" 
                        name="surname" 
                        error={(formData.surnameError!=="")} 
                        errorMessage={formData.surnameError} 
                        value={formData.surname}
                        onChange={handleChange}
                        placeholder={(formData.surname==="") ? "Surname..." : ""} />

                    <TextInput 
                        id="preferredName" 
                        name="preferredName" 
                        error={(formData.preferredNameError!=="")} 
                        errorMessage={formData.preferredNameError} 
                        value={formData.preferredName}
                        onChange={handleChange}
                        placeholder={(formData.preferredName==="") ? "Preferred name..." : ""} />


                    <TextInput 
                        id="dateOfBirth" 
                        name="dateOfBirth" 
                        type="date" 
                        error={(formData.dateOfBirthError!=="")} 
                        errorMessage={formData.dateOfBirthError} 
                        value={formData.dateOfBirth}
                        onChange={handleChange}
                        placeholder={(formData.dateOfBirth==="") ? "Date of birth..." : ""}
                    />
                <span>{
                formData.dateOfBirth
                }</span>

                    <TextInput 
                        id="nhsNumber" 
                        name="nhsNumber" 
                        error={(formData.nhsNumberError!=="")} 
                        errorMessage={formData.nhsNumberError} 
                        value={formData.nhsNumber}
                        onChange={handleChange}
                        placeholder={(formData.nhsNumber==="") ? "NHS Number..." : ""} />

                    <TextInput 
                        id="roomCode" 
                        name="roomCode" 
                        error={(formData.roomCodeError!=="")} 
                        errorMessage={formData.roomCodeError} 
                        value={formData.roomCode}
                        onChange={handleChange}
                        placeholder={(formData.roomCode==="") ? "Room code..." : ""} />

                </div>
                
                <button
                    onClick={()=>handleForm()}
                >Create User</button>
            </Card>
            
        </div>
    );
}

const ResidentPages=()=>{

    return (
        <TabGroup className="mt-6">
        <TabList>
          <Tab>Resident</Tab>
          <Tab>Room Configuration</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid numItemsMd={2} numItemsLg={3} className="gap-6 mt-6">
              <Card>
                {/* Placeholder to set height */}
                <div className="h-28" />
              </Card>
              <Card>
                {/* Placeholder to set height */}
                <div className="h-28" />
              </Card>
              <Card>
                {/* Placeholder to set height */}
                <div className="h-28" />
              </Card>
            </Grid>
            <div className="mt-6">
              <Card>
                <div className="h-80" />
              </Card>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="mt-6">
              <Card>
                <div className="h-96" />
              </Card>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
 
    );

}