import { useEffect, useState, useRef } from "react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import {
  Card,
  Title as TTitle,
  Text,
  Flex,
  BarChart
} from "@tremor/react";
import { displayDateFromUTC, getHoursBetweenDates, secondsToHHMMSS } from "../../functions/dataHandler";
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import {
  Chart,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from 'react-chartjs-2';
import { placeAverageResponseTimePerZone } from "../../functions/apiActions";
import { externalTooltipHandler } from "../externalTooltipHandler";
import { set } from "firebase/database";
import SpinnerLoader from "../SpinnerLoader";
import { DataBreakDown } from "../DataBreakDown";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function AverageResponseTimeByZone({ postFilters, dateSelections, zonesSelections, roomsSelections }){
    
    const [cData, setCData] = useState(null);
    const chartRef = useRef<ChartJS>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [dataBreakDown, setDataBreakDown] = useState(null);

    useEffect(()=>{
      if (!isLoading) setIsLoading(true);
      placeAverageResponseTimePerZone(postFilters,
        (d)=>{
          setIsLoading(false);
          setCData(d);
        }
      );

    },[postFilters, dateSelections, zonesSelections, roomsSelections]);

        
    function dataFormatter(val){
      return val.toString();
    }
    


    function handleBarClick(elements) {
      try {
        const dataSetIndex = elements[0].datasetIndex;
        const rawData = cData.rawData[dataSetIndex];
        setDataBreakDown(rawData);
      }
      catch (e) { console.log(e); }
    }

    const options = {
      onClick: (_, elements) => {
        handleBarClick(elements);
      },
      plugins: {
        title: { display: false },
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler
        },
      },
      responsive: true,
      scales: {
        x: {
          type: 'category',
          stacked: true,
          grid: {
            display:false
          },
          ticks: {
            callback: function(value, index, ticks) {
              return cData.labels[value];
            }
          }
        },
        y: {
          stacked: true,
          grid: {
            display:true
          },
          ticks: {
            beginAtZero: true,
            callback: function(value) {if (value % 1 === 0) {
              return secondsToHHMMSS(value);
            }}
          }   
        },
      },
    };
    
    function updateBreakdown(){ setDataBreakDown(null);  }


    return (
      <Card className="max-h-50">
        {(dataBreakDown!==null) && <DataBreakDown preData={dataBreakDown} onClose={updateBreakdown} />}
        <div className="md:flex justify-between">
            <div>
              <Flex className="space-x-0.5" justifyContent="start" alignItems="center">
                <TTitle>Average Response Time by Zone</TTitle>
              </Flex>
              <Text></Text>
            </div>
          </div>
          <div>
            {
            (isLoading) ?
            <SpinnerLoader isLoading={isLoading}/>
            :
            (cData!==null)&&
                <Bar
                options={options}
                data={cData}
              />            
            }
        </div>
    </Card>
  );
}
