
import { useState } from "react";
import { Card, Title, Text, Grid, Col } from "@tremor/react";
import NourishSite from "./nourish/NourishSite";
import { useEffect } from "react";


export default function Nourish({site}) {
  
  const [selectedSite, setSelectedSite] = useState(null);
  const [siteDetails, setSiteDetails] = useState(null);

  useEffect(()=>{
    setSiteDetails({
      siteID: site.id,
      displayName: site.siteName
    });
  },[site]);

  function setSelectedSiteFromID({id}){
    const selection = siteDetails.filter(s=>(s.siteID===id));

    if (selection.length>0) {
      setSelectedSite(selection[0]);
    }
    else {
      setSelectedSite(null);
    }
  }
  return <div>No</div>
  return (
    <div className="px-12 py-12">
      {(siteDetails!==null) && <NourishSite siteID={site.id} />}
      
    </div>
  );
}


const SiteCard=({siteDetails, onClickRun})=>{
  
  const id = siteDetails.siteID;

  return(
    <div 
      className="h-24"
      onClick={()=>onClickRun({id})}
      >
      {siteDetails.displayName}
    </div>
  )
}
