import {
    Card,
    LineChart,
    Title,
    Text,
    Metric,
    Table,
    TableHead,
    TableRow,
    TableHeaderCell,
    TableBody,
    TableCell,
} from '@tremor/react';

const Welcome=()=>{

    return(
        <div className="max-w-2xl mx-auto">
            <Card className="p-0 h-full">
                <div className="pt-6 px-6 pb-4">
                    
                    <Title className="mt-2">Welcome to Arquella Connect</Title>
                    <Text className="mt-1">The Arquella team will begin your journey by creating you a site to begin the journey.</Text>
                    <Text className="mt-1">This site will allow you to explore Arquella Connect.</Text>
                    
                    <br /><br />
                    <br /><br />
                </div>
            </Card>
        </div>
    
        )
}

export default Welcome;
