import { Grid, Flex, Text, Bold} from "@tremor/react";
import IMG_HYDRATION from "../../images/care_clicks/nutrition_hydration.png";
import IMG_CONTINENCE from "../../images/care_clicks/continence.png";
import IMG_MOVING from "../../images/care_clicks/moving.png";
import IMG_PERSONAL from "../../images/care_clicks/personal_care.png";
import IMG_SLEEP from "../../images/care_clicks/sleep_rest.png";
import IMG_SOCIAL from "../../images/care_clicks/social.png";

const BarList = ({ data, dataBreakDownClick }) => {
    console.log("BarList data", data);
    return (  
      <div>
            <div className="tremor-BarList-bars relative w-full">
        {
          data.map(d=>{
            return(
                <Flex 
                //className="mt-4"
                className="mt-4"onClick={() => {
                    console.log("dataBreakDownClick", d);
                    if (!dataBreakDownClick) return;
                    const rtn = d.rawData; 
                    console.log("RawData", d);
                    console.log("rtn", rtn);
                    if (rtn===undefined) return;
                    dataBreakDownClick(rtn);

                }}
                >
                    <div
                    //bg-tremor-brand-muted dark:bg-dark-tremor-brand-muted
                        className="tremor-BarList-bar flex items-center rounded-tremor-small h-9 bar_connect "
                        style={{ width: `${d.value}%`, transition: "all 1s ease 0s" }}
                        >
                        <div className="absolute max-w-full flex left-2">
                            <GetImage nme={d.name} />
                            <p className="tremor-BarList-barText whitespace-nowrap truncate text-tremor-default ">{d.name}</p>
                        </div>
                    </div>
                
                    <Text className="pr-6">{d.value}</Text>
                </Flex>
            );
            })
        }
        </div>
        </div>
    );
  };
  
  const GetImage=({nme})=>{
    const img = getImageForType(nme);
    if (img===null) return <></>;
    return( <img src={img} className="reports_careclicks" /> );
  };

  function getImageForType(cType){
    if (cType==="Hydration") return IMG_HYDRATION;
    if (cType==="Continence") return IMG_CONTINENCE;
    if (cType==="Nutrition") return IMG_HYDRATION;
    if (cType==="Moving") return IMG_MOVING;
    if (cType==="Personal") return IMG_PERSONAL;
    if (cType==="Sleep") return IMG_SLEEP;
    if (cType==="Social") return IMG_SOCIAL;
    return null;
  }


  export default BarList;


  /*        
<Grid numItemsMd={2} className="mt-6 gap-6">
                    <div>{d.value}</div>
                    </Grid>
            )

            return(
                <Grid numItemsMd={2} className="mt-6 gap-6">
                    <div
                    className="tremor-BarList-bar flex items-center rounded-tremor-small h-9 bg-tremor-brand-muted dark:bg-dark-tremor-brand-muted mb-2"
                    style={{ width: `${d.value}%`, transition: "all 1s ease 0s" }}
                    >
                    <div className="absolute max-w-full flex left-2">
                        <p className="tremor-BarList-barText whitespace-nowrap truncate text-tremor-default text-tremor-content-emphasis dark:text-dark-tremor-content-emphasis">{d.name}</p>
                    </div>
                    </div>
                    <div>{d.value}</div>
                    </Grid>
            
                 //   )
          })
        } 

    
    </div>
    </div>
    
    );
    */