import { Card, Title, Bold, Flex, Text } from "@tremor/react";
import { useState, useEffect } from "react";
import IMG_HYDRATION from "../../images/care_clicks/nutrition_hydration.png";
import IMG_CONTINENCE from "../../images/care_clicks/continence.png";
import IMG_MOVING from "../../images/care_clicks/moving.png";
import IMG_PERSONAL from "../../images/care_clicks/personal_care.png";
import IMG_SLEEP from "../../images/care_clicks/sleep_rest.png";
import IMG_SOCIAL from "../../images/care_clicks/social.png";
import { placeCareByType } from "../../functions/apiActions";
import SpinnerLoader from "../SpinnerLoader";
import BarList from "../charts/BarList";
import { DataBreakDown } from "../DataBreakDown";

export default function CareDeliveredByType({postFilters}){

  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dataBreakDown, setDataBreakDown] = useState(null);

  function getImageForType(cType){
    if (cType==="Hydration") return IMG_HYDRATION;
    if (cType==="Continence") return IMG_CONTINENCE;
    if (cType==="Nutrition") return IMG_HYDRATION;
    if (cType==="Moving") return IMG_MOVING;
    if (cType==="Personal") return IMG_PERSONAL;
    if (cType==="Sleep") return IMG_SLEEP;
    if (cType==="Social") return IMG_SOCIAL;
    return IMG_SOCIAL;
  }

  function getDisplayText(cType){
    if (cType==="nutandhyd") return "Hydration";
    if (cType==="continence") return "Continence";
    if (cType==="nutandhyd") return "Nutrition";
    if (cType==="moving") return "Moving";
    if (cType==="personal") return "Personal";
    if (cType==="sleep") return "Sleep";
    if (cType==="social") return "Social";
    return "";
  }

  function processData(data){

    const datas = Object.keys(data).map(d=>{
      const nme = getDisplayText(d);
      return {
        name: nme,
        value: data[d].length,
        icon: function () {
          return (
            <img src={getImageForType(nme)} className="reports_careclicks" />
          );
        }
      };
    });
    setIsLoading(false); 
    setData(datas);
  }

  useEffect(()=>{ 
    setIsLoading(true);
    placeCareByType(postFilters, processData); 
  },[postFilters]);

  function updateDataBreakdown(data){
    setDataBreakDown(data);
  }

  function clearData(){ setDataBreakDown(null); }

  return (
    <Card className="max-w-lg">
      {(dataBreakDown!==null)&& <DataBreakDown data={dataBreakDown} clearData={clearData} />}
      <Title>Care Delivered By Type</Title>
      <Flex className="mt-4">
        <Text>
          <Bold>Source</Bold>
        </Text>
        <Text>
          <Bold>Calls</Bold>
        </Text>
      </Flex>     
      {
      (isLoading)
      ? <SpinnerLoader isLoading={isLoading} />
      : <BarList data={data} className="mt-2" dataBreakDownClick={updateDataBreakdown} />
      }
    </Card>
  );
}