import {
    Bold,
    Button,
    Callout,
    Card,
    Flex,
    List,
    ListItem,
    Metric,
    ProgressBar,
    Text,
} from "@tremor/react";
  //import { TextInput } from "@tremor/react";
import { ArrowNarrowRightIcon, TrendingUpIcon } from "@heroicons/react/solid";
import Avatar from '@mui/material/Avatar';
  //import { MarkerBar, DeltaBar, CategoryBar } from "@tremor/react";
  //import { Link } from "react-router-dom";
import { getInitialsFromName } from "../../functions/dataHandler";
import { ResidentLink } from "../../functions/links";
import { ResidentTags } from "../../ResidentTags";
import { SummaryCharts } from "./SummaryCharts";

  const PeopleResidentCard=({resident, siteID})=>{
    return (
      <Card className="max-w-md mx-auto">
        <div style={{"display":"flex", "flexDirection":"row"}}>
            <div>
              <ResidentLink id={resident.id}>
                <Avatar sx={{ width: 32, height: 32 }}>{getInitialsFromName(`${resident.firstName} ${resident.surname}`)}</Avatar>
              </ResidentLink>
            </div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <ResidentLink id={resident.id}>
              <Text>{resident.firstName} {resident.surname}</Text>
              <Text></Text>
            </ResidentLink>
        </div>
        
        {(resident.statusDescription)&&<TextDescription descriptions={resident.statusDescription} />}
        {//<ResidentTags resID={resident.id} />
  }
        {/*}
        <Flex className="mt-6">
          <Text>
            <Bold>Calls</Bold>
          </Text>
          <Text>
            <Bold>Number</Bold>
          </Text>
        </Flex>
<List className="mt-1">
          {issues.map((item) => (
            <ListItem key={item.issue}>
              <span>{item.issue}</span>
              <span>{item.topic}</span>
            </ListItem>
          ))}
        </List>   
          */}
        {/*<SummaryCharts />*/}
        <Flex className="mt-6 pt-4 border-t">
          <ResidentLink id={resident.id} residentObject={resident} siteID={siteID}>
            <Button size="xs" variant="light" icon={ArrowNarrowRightIcon} iconPosition="right">View more</Button>
          </ResidentLink>
          {
          ((resident.source!==undefined)&&(resident.source!=="Connect"))
          &&    <div class="tooltip-container">
                  <div class="tooltip-div"><LockedIcon /></div>
                  <div class="tooltip">Resident Integrated with {resident.source}</div>
                </div>
          }
        </Flex>
      </Card>
    );
  }

const LockedIcon=()=>{ return (
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
</svg>
); }

const issues = [
  {issue: "Emergency", topic: "1"},
  {issue: "Call", topic: "5"},
  {issue: "Sense", topic: "1"},
  {issue: "Attendance", topic: "3"}
]
const TextDescription=({descriptions})=>{
  
  if (descriptions.length===0) return<></>;

  return(
      <Callout
              title="Status"
              //icon={TrendingUpIcon}
              color="emerald"
              className="mt-6"
          ><ul>{descriptions.map(d=><li>{d}</li>)}</ul></Callout>
    

  );
}

const ResidenTags=()=>{
  return(
    <div></div>
  );
}

export default PeopleResidentCard;