import React from 'react';

class ExporterWord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        { name: 'John', age: 30, city: 'New York' },
        { name: 'Alice', age: 25, city: 'Los Angeles' },
        { name: 'Bob', age: 35, city: 'Chicago' },
      ],
    };
  }

  // Function to generate and export a Word document
  generateWordDoc = () => {
    const { data } = this.state;

    const docContent = `
    <!DOCTYPE html>
    <html>
      <head>
        <title>Exported Data</title>
      </head>
      <body>
        <h1>Data Exported to Word</h1>
        <table border="1">
          <thead>
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>City</th>
            </tr>
          </thead>
          <tbody>
            ${data.map((row) => `
              <tr>
                <td>${row.name}</td>
                <td>${row.age}</td>
                <td>${row.city}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
      </body>
    </html>
    `;

    const blob = new Blob([docContent], { type: 'application/msword' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'exported-data.docx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  render() {
    return (
      <div>
        <button onClick={this.generateWordDoc}>Export to Word</button>
      </div>
    );
  }
}

export default ExporterWord;
