
import { useEffect, useState } from "react";
import { Text, TextInput,  Tab,  TabList,  TabGroup,  TabPanel,  TabPanels,  Card } from "@tremor/react";
import Nourish from "./integrations/Nourish";
import { getOnceValue } from "../data/firebase";
import { groupBy } from "../functions/objects_arrays";
import RoomUnitConfig from "./RoomUnitConfig/RoomUnitConfig";
import { PanelSettings } from "./PanelSettings";
import NourishSite from "./integrations/nourish/NourishSite";
import { MoveSiteSettings } from "./MoveSiteSettings";
import CallMonitor from "./system/monitor/call_monitor";

export default function Settings({siteCode, site}) {
  console.log("settings refresh", site);
  const [siteConfig, setSiteConfig] = useState(null);
  
  useEffect(()=>{
    if (siteCode==="") return;
    getOnceValue(
      siteCode + "config",
      (d)=>setSiteConfig(d),
      (e)=>{console.log(e);}
    )
  },[siteCode]);

  return (
    <main className="px-12 py-12">
      <TabGroup className="mt-6">
        <TabList>
          <Tab>Room Units</Tab>
          <Tab>Live Calls</Tab>
          <Tab>Integrations</Tab>
          <Tab>Move</Tab>
        </TabList>
        <TabPanels>
          <TabPanel><PanelSettings siteCode={site.siteCode} /></TabPanel>
          <TabPanel><CallMonitor site={site.siteCode} /></TabPanel>
          <TabPanel><NourishSite siteID={site.id} siteCode={site.siteCode} site={site} /></TabPanel>
          <TabPanel><MoveSiteSettings siteID={site.id} siteCode={site.siteCode} /></TabPanel>
        </TabPanels>
      </TabGroup>
    </main>
  );
}

const SiteSettings=({config})=>{

  const [zones, setZones] = useState([]);

  useState(()=>{
    if ((config===undefined)||(config===null)){
      setZones([]);
      return;
    }
    const z = groupBy(config, "unitZone");
    console.log("zed", z);
    setZones(Object.keys(z));

  }, [config]);

  const RoomUnit=({data})=>{
    return(
      <Card>
        <TextInput placeholder="ID" value={data.unitId} />
        <TextInput placeholder="Name" value={data.unitName} />
        <TextInput placeholder="Zone" value={data.unitZone} />
        <TextInput placeholder="Linked Panel Code" value={data.linkID} />
        <TextInput placeholder="Move - Room Config" value={data.iiwari_room_id} />
        <button>Update</button>
        {//Object.keys(data).map(k=><div>{k}</div>)
        }
      </Card>
    )
  }
  
  return(
    <div>
      <br />
      <Text>Zones</Text>
      {zones.map(z=><div>{z}</div>)}
    </div>
  )
}

const PhoneSettings=()=>{
  return(<div>SiteSettings</div>)
}

const RoomUnitSettings=()=>{
  return <RoomUnitConfig />;
  return(<div>SiteSettings</div>)
}


const UserAccountSettings=()=>{
  return(<div>SiteSettings</div>)
}

const SenseSettings=()=>{
  return(<div>SiteSettings</div>)
}

const MoveSettings=()=>{
  return(<div>SiteSettings</div>)
}

