import { toast } from 'react-toastify';
import { apiPost } from '../data/apidata';

export function raiseError(user, location, title, description, showToast) {
    //recordError(user, location, title, description);
    console.log(description);
    return;
    if (showToast!==false) {
        toast.info("Info Notification !", {
            position: toast.POSITION.BOTTOM_RIGHT
        });
    }
}

function recordError(user, location, title, description){

    apiPost(
        "/events/raise",
        {
            "userID" : user,
            "userToken" : "",
            "eventType" : "Connect Error", 
            "eventTitle" : title, 
            "eventDescription" : description,
            "eventDateTime" : ""
        },
        (d)=>{ console.log(d);  },
        (e)=>{ console.log(e);  }
    );

}

   /*
    toast("Default Notification !");

    toast.success("Success Notification !", {
        position: toast.POSITION.BOTTOM_RIGHT
    });
    toast.error("Error Notification !", {
        position: toast.POSITION.BOTTOM_RIGHT
    });
  
    toast.warn("Warning Notification !", {
        position: toast.POSITION.BOTTOM_RIGHT
    });
  
    toast("Custom Style Notification with css class!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
    });
*/