import React from 'react';

class ExporterPDF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        { name: 'John', age: 30, city: 'New York' },
        { name: 'Alice', age: 25, city: 'Los Angeles' },
        { name: 'Bob', age: 35, city: 'Chicago' },
      ],
    };
  }

  // Function to generate a PDF from the data
  generatePDF = () => {
    const { data } = this.state;

    const content = `
    <html>
      <head>
        <title>Exported Data</title>
      </head>
      <body>
        <h1>Data Exported to PDF</h1>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Age</th>
              <th>City</th>
            </tr>
          </thead>
          <tbody>
            ${data.map((row) => `
              <tr>
                <td>${row.name}</td>
                <td>${row.age}</td>
                <td>${row.city}</td>
              </tr>
            `).join('')}
          </tbody>
        </table>
      </body>
    </html>
    `;

    const pdfBlob = new Blob([content], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Create an iframe to display and allow downloading the PDF
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = pdfUrl;
    document.body.appendChild(iframe);

    // Clean up after a short delay to allow the PDF to be fully loaded
    setTimeout(() => {
      document.body.removeChild(iframe);
      URL.revokeObjectURL(pdfUrl);
    }, 1000);
  };

  render() {
    return (
      <div>
        <button onClick={this.generatePDF}>Export to PDF</button>
      </div>
    );
  }
}

export default ExporterPDF;
