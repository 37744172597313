import React, { useEffect, useState } from 'react';
import { Flex, Grid, Icon } from '@tremor/react';
import { ResidentAvatar } from './components/ResidentAvatar';
import { FormInput } from './components/Input';
import { raiseError } from './functions/error_handling';
import { addEditResident, getResidentBasic } from './functions/apiActions';
import { LoadingWait } from './LoadingWait';
import { Icon_edit_circle } from './components/svgIcons';

export const BasicIntegrationDetails = ({ resID, callBack, nameCallBack, residentObject }) => {

  return (
    <>
      <Flex justifyContent='start' className='mr-5'>
        <div className='mr-5'>
          {(resID !== "-1") && <ResidentAvatar firstName={residentObject.firstName} surname={residentObject.surname} resID={resID} size="m" />}
        </div>

        <Flex
          flexDirection='col'
          justifyContent='between'
        >
            <Flex justifyContent='start'>
              <div className="text-center">
                <h1 className="text-2xl font-semibold mt-4">{residentObject.firstName} {residentObject.surname}</h1>
              </div>
            </Flex>
        </Flex>
      </Flex>
    </>
  );
};
