import Avatar from '@mui/material/Avatar';
import { ResidentLink } from '../functions/links';
import { getInitialsFromName } from '../functions/dataHandler';

export const ResidentAvatar=({resID, firstName, surname, size})=>{
    
    const width = (size==="s") ? 32 : 64;
    const height = (size==="s") ? 32 : 64;
    
    return(
        <ResidentLink id={resID}>
            <Avatar sx={{ width: width, height: height }}>{getInitialsFromName(`${firstName} ${surname}`)}</Avatar>
        </ResidentLink> 
    );
}