import React from 'react';
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { Icon_x_circle } from './components/svgIcons';

export const AQTag = (props) => {
  
  const RemoveButton=()=>{
    return(<button 
      icon={ArrowNarrowRightIcon} 
      iconPosition="right"
      className='ml-2'>
        <Icon_x_circle />
      </button>);

  }

  return (
    <span className={`inline-flex mr-1 justify-center items-center px-2.5 py-0.5 rounded-full ${(props.textSize)?props.textSize:"text-xs"} font-medium bg-blue-500 text-white`}>
      {props.children} 
      {(props.onCross)&&<RemoveButton />}
    </span>
  )
};
