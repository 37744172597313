import { useEffect, useState } from "react";
import { Grid, DateRangePicker } from "@tremor/react";
import { apiPost } from "../data/apidata";
import { groupBy } from "../functions/objects_arrays";
import { displayDateFromUTC, getComparison, getEndOfDay, getStartOfDay } from "../functions/dataHandler";
import { dataRefresh } from "../data/dbHelper";
import { AQTag } from "../AQTag";
import { Icon_refresh_circle } from "../components/svgIcons";
import PlaceChart from "./PlaceCharts";
import { MultiSelect, MultiSelectItem } from "@tremor/react";
import { getLastRefresh } from "../functions/apiActions";

export default function Place({siteID, siteCode}) {
  var d = new Date();
 
  const [value, setValue] = useState({
    from: getStartOfDay(new Date()),
    //from: getStartOfDay(new Date(d.setDate(d.getDate() - 30))),
    to: getEndOfDay(new Date()),
  });

  const [masterFilterData, setMasterFilterData] = useState([]);
  const [zonesList, setZonesList] = useState([]);
  const [zoneSelections, setZoneSelections] = useState([]);
  const [roomsList, setRoomsList] = useState([]);
  const [roomSelections, setRoomSelections] = useState([]);
  const [residentList, setResidentList] = useState([]);
  const [residentSelections, setResidentSelections] = useState([]);
  const [carerList, setCarerList] = useState([]);
  const [carerSelections, setCarerSelections] = useState([]);
  const [lastRefresh, setLastRefresh] = useState("");
  const [renderCount, setRenderCount] = useState(0);
  const [postFilters, setPostFilters] = useState(null);
  const [allSetup, setAllSetup] = useState(false);

  function getLastRefreshDate(){
    
    const data = {
      "userID": "",
      "siteID": siteCode,
    };

    getLastRefresh(data, (d)=>{ 
      try { setLastRefresh(displayDateFromUTC(d[0].lastRefresh)); }
      catch(e){ setLastRefresh(""); }
      setTimeout(()=>getLastRefreshDate(), 1200000);
    }, (e)=>{console.log(e)});

  }

  function processFilterData(d){ setMasterFilterData(d); }

  function createFilterListsAndSelections(){

    const z = getListFromGroupFilter(masterFilterData, "Zone");
      setZoneSelections(z);
      setZonesList(z);

    const rm = getListFromGroupFilter(masterFilterData, "Room");
      setRoomSelections(rm);
      setRoomsList(rm);

    const res = getListFromGroupFilter(masterFilterData, "Resident");
      setResidentSelections(res);
      setResidentList(res);
  }

  function dateToDoubleDigitString(d){
    const r = d.toString();
    return (r.length===1) ? ("0" + r) : r;
  }

  function getFirebaseDate(){
    let nowDate =  new Date();
    return (dateToDoubleDigitString(nowDate.getDate()) + dateToDoubleDigitString(nowDate.getMonth()+1) + dateToDoubleDigitString(nowDate.getFullYear()));
  }
  
  function refreshClick(){
    setLastRefresh("..updating");
    const data = {
      "userID": "",
      "siteID": siteCode,
      "runDate": getFirebaseDate()
    };
    dataRefresh(data, ()=>{}, ()=>{});
  }

  function getSiteMasterFilterData(){
    const data = {
      "userID": "",
      "siteID": siteID,
    };
    apiPost("/dashboard/site/filters", 
      data,
      (d)=>{
        processFilterData(d);
      },
      (e)=>{console.log(e);}
    );
  }

  
  function dateChange(vals){
    let start = (vals.from) ? vals.from : "";
    let end = (vals.to) ? vals.to : "";

    if (start.toString()!="") start = getStartOfDay(start);
    if (end.toString()!="") { end = getEndOfDay(end); }
      else { if (start.toString()!=="") end = getEndOfDay(start); }
    
    setValue({ from: start, to: end, });
  }

  function getListFromGroupFilter(fData, fField){
    let obj = Object.keys(groupBy(fData, fField));
    obj = obj.filter(o=>((o!==null)&&(o!=="null")));
    return (obj===null) ? [] : obj; 
  }

  function updatePostFilters(){
    const [compareStart, compareEnd] = getComparison(value.from, value.to);
    setPostFilters(new Object({
      "userID" : "",
      "siteID" : siteID,
      "startDate": value.from,
      "endDate": value.to,
      "compareStart": compareStart,
      "compareEnd": compareEnd,
      "zonesSelections": zoneSelections,
      "roomSelections": roomSelections,
      "residentSelections": residentSelections,
      "carerSelections": carerSelections
    }));
  }

  useEffect(()=>getSiteMasterFilterData(), [siteID]);
  useEffect(()=>getLastRefreshDate(), [siteID]);
  useEffect(()=>createFilterListsAndSelections(),[masterFilterData]);
  useEffect(()=>{ updatePostFilters() }, [zoneSelections, roomSelections, value.from, value.to, siteID]);
  useEffect(()=>setAllSetup(true),[residentList]);

  const AQMultiSelect=({placeholder, selections, list, onChange})=>{
    return (
      <MultiSelect placeholder={placeholder} value={selections} onChange={(d)=>onChange(d)}>
      {list.map((z)=><MultiSelectItem value={z} >{z}</MultiSelectItem>)}
      </MultiSelect>
    ) 
  }
  
  return (
    <main className="px-12 py-12">
      <AQTag><span onClick={()=>refreshClick()}><Icon_refresh_circle /></span>&nbsp;Last data capture : {lastRefresh}</AQTag>

      <Grid numItemsLg={5} 
      className="mt-6 gap-6"
      >
        
        <AQMultiSelect placeholder={"Zone"} selections={zoneSelections} list={zonesList} onChange={setZoneSelections} />
        <AQMultiSelect placeholder={"Room"} selections={roomSelections} list={roomsList} onChange={setRoomSelections} />
        {/*
        <AQMultiSelect placeholder={"Resident"} selections={residentSelections} list={residentList} onChange={setResidentSelections} />
        <AQMultiSelect placeholder={"Carer"} selections={carerSelections} list={carerList} onChange={setCarerSelections} />
  */}
        <div>
          <DateRangePicker
            className="max-w-md mx-auto"
            value={value}
            onValueChange={dateChange}
            selectPlaceholder="Selection"
          />
        </div>
        
      </Grid>


      {
      ((allSetup==true) && (postFilters!==null)&&(postFilters.startDate!=="")&&(postFilters.startDate!=="")) 
        && <PlaceChart postFilters={postFilters} />
      }
      
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />  
    </main>
  );


}

export function zeroIfNullEmpty(val){
  return ((val===null) || (val==="")) ? 0 : val;
}

/*
  useEffect(()=>{
    const list = getListFromGroupFilter(filterData, "Zone");
    setZonesList(list);
    setZoneSelections(list);    
  },[filterData]);

  useEffect(()=>{
    //const r = getListFromGroupFilter(filterData.filter(z=>(zoneSelections.includes(z.Zone))), "Room");
    const r = getListFromGroupFilter(filterData.filter(z=>(zoneSelections.includes(z.Zone))), "Room");
    setRoomSelections(r);
    setRoomsList(r);  
  },[zoneSelections])

  useEffect(()=>{
    const r = getListFromGroupFilter(filterData.filter(z=>(roomSelections.includes(z.Room))), "Resident");
    setResidentSelections(r);
    setResidentList(r);  

    const c = getListFromGroupFilter(filterData.filter(z=>(roomSelections.includes(z.Room))), "Resident");
    setCarerSelections(r);
    setCarerList(r);  
  },[roomSelections])

  


  useEffect(()=>{
    setRenderCount(renderCount + 1);
  },[value])

  console.log("RENDER");
*/
