import {
  //BarList,
  Card,
  Title,
  Bold,
  Flex,
  Text,
} from "@tremor/react";
import { apiPost } from "../../data/apidata";
import { useState, useEffect } from "react";
import IMG_HYDRATION from "../../images/care_clicks/nutrition_hydration.png";
import IMG_CONTINENCE from "../../images/care_clicks/continence.png";
import IMG_MOVING from "../../images/care_clicks/moving.png";
import IMG_PERSONAL from "../../images/care_clicks/personal_care.png";
import IMG_SLEEP from "../../images/care_clicks/sleep_rest.png";
import IMG_SOCIAL from "../../images/care_clicks/social.png";
import { placeCallsByCarer } from "../../functions/apiActions";
import SpinnerLoader from "../SpinnerLoader";
import BarList from "../charts/BarList";

export default function TotalCallsByCarer({ postFilters }) {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    if (Object.keys(postFilters).length === 0) return;
    placeCallsByCarer(postFilters, setChartData);
  }, [postFilters]);

  useEffect(() => {
    setIsLoading(false);
  }, [chartData]);

  return (
    <Card className="max-w-lg">
      <Title>Total Calls By Carer</Title>
      <Flex className="mt-4">
        <Text>
          <Bold>Source</Bold>
        </Text>
        <Text className="pr-6">
          <Bold>Calls</Bold>
        </Text>
      </Flex>
      {isLoading ? (
        <SpinnerLoader isLoading={isLoading} />
      ) : (
        <div className="h-80 overflow-y-auto scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-blue-200 scrollbar-thumb-rounded-full pr-6">
          <BarList data={chartData} className="mt-2" />
        </div>
      )}
    </Card>
  );
}