import { Card, Title, LineChart } from "@tremor/react";
import { apiPost } from "../../data/apidata";
import { useState, useEffect } from "react";

export default function TotalCallsPerDay({siteID}){

  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  
  useEffect(()=>{ 
    if (siteID==="") return;
    apiPost(
      "dashboard/chart/totalcallsbyday", 
      {
        "userID" : "",
        "siteID" : siteID
      }, 
      (d)=>{
        console.log("chartData", d);
        setData(d);
      }, 
      setError
    ); },[siteID]);

  return(
  <Card className="max-w-lg">
    <Title>Total Calls By Date</Title>
    {
    (data.length>0) &&
    <LineChart
      className="mt-6"
      data={data}
      //data={chartdata}
      index="year"
      //categories={["Export Growth Rate", "Import Growth Rate"]}
      categories={["Call", "Accessory", "Assistance", "Attendance", "CareCall", "Emergency", "Total"]}
      colors={["orange", "purple","yellow", "green", "pink", "red", "gray"]}
  //    valueFormatter={dataFormatter}
      yAxisWidth={40}
    />
    
    }
    
  </Card>
)};