import { Card, Title, DonutChart } from "@tremor/react";
import { apiPost } from "../../data/apidata";
import { useState, useEffect } from "react";
import SpinnerLoader from "../SpinnerLoader";
import { set } from "firebase/database";

export default function Emergencyvnon({postFilters}){

    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [error, setError] = useState("");
    const postUrl = "/dashboard/place/emergencyvnon";
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{ 
      if (!isLoading) setIsLoading(true);
      if (Object.keys(postFilters).length===0) return;
      apiPost(postUrl, postFilters, (d)=>{
        setData(d[0]);
      }, setError); 
      setIsLoading(true);
    },[postFilters]);

    useEffect(()=>{
      if (typeof(data)==="string") return; /// ADC return error later on when have error handler 
      const d = [];

      const totalcalls = parseInt(data["Emergency"]) + parseInt(data["Non Emergency"]);
      function calculatePercentage(val){
        return Math.round((val/totalcalls)*100);
      }
            const t = data;//[0];
            d.push({
                name: "Emergency",
                calls: calculatePercentage(t["Emergency"]),
              });
            d.push({
                name: "Non emergency",
                calls: calculatePercentage(t["Non Emergency"]),
            });

        setIsLoading(false);
        setChartData(d);
        
    },[data]);

    function valueFormatter(val){
      return val+"%";
    }

  return(
  <Card className="max-w-lg">
    <Title>Non Emergency v Emergency Calls</Title>
    {
    (isLoading) 
    ? <SpinnerLoader isLoading={true} />
    : <DonutChart
      className="mt-6"
      data={chartData}
      category="calls"
      index="name"
      valueFormatter={valueFormatter}
      colors={["red", "teal"]}
    />
}
  </Card>
)};