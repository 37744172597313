import { apiPost } from "../data/apidata";

export function raiseCall(siteCode, room, callCode, strCarer){
    
    if (room==="") { return; }
    if (siteCode==="") { return; }

    const callPath = "/call/live/raise";

    const data = {
        "userID":"",
        "siteID" : siteCode,
        "loginName" : "arquellacare@arquella.co.uk",
        "callType": callCode,
        "roomCode": room,
        "title": "",
        "comments": "",
        "carer": strCarer,
        "virtualCall": true
    };

    apiPost(callPath, data, (d)=>{console.log(d)}, (d)=>{console.log(d)});
}

