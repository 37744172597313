import { Card, Title, DonutChart } from "@tremor/react";
import { apiPost } from "../../data/apidata";
import { useState, useEffect } from "react";
import { getProactiveVReactive } from "../../functions/apiActions";
import SpinnerLoader from "../SpinnerLoader";

export default function ProactiveVReactive({postFilters}){

    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [error, setError] = useState("");
    const postUrl = "/dashboard/place/emergencyvnon";
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
      if (!isLoading) setIsLoading(true); 
      if (Object.keys(postFilters).length===0) return;
      getProactiveVReactive(postFilters,(d)=>setData(d));
    },[postFilters]);


    useEffect(()=>{
      if (typeof(data)==="string") return; /// ADC return error later on when have error handler 
      const d = [];

        const proactive = (data["proactive"]) ? data["proactive"].length : 0;
        const reactive = (data["reactive"]) ? data["reactive"].length : 0;
        const totalcalls = proactive + reactive;

      function calculatePercentage(val){  
        return Math.round((val/totalcalls)*100);
      }

            const t = data;
            d.push({
                name: "Proactive",
                calls: calculatePercentage(proactive),
              });
            d.push({
                name: "Reactive",
                calls: calculatePercentage(reactive),
            });

        setChartData(d);
        setIsLoading(false);
        
    },[data]);

    function valueFormatter(val){
      return val+"%";
    }
    
  return(
  <Card className="max-w-lg">
    <Title>Proactive v Reactive</Title>
    
    {
    (isLoading)
    ? <SpinnerLoader isLoading={true} />
    : <DonutChart
      className="mt-6"
      data={chartData}
      category="calls"
      index="name"
      valueFormatter={valueFormatter}
      colors={["red", "teal"]}
    />
  }
{//}    <div>Non Emergency calls include - Call, Assistance, Accessory</div>
}
  </Card>
)};