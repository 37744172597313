
import { useEffect, useState } from "react";
import { Card, Title, Text, Grid, Col, Divider } from "@tremor/react";
import { apiPost } from "../../../data/apidata";
import { TextInput } from "@tremor/react";
import NourishUsers from "./NourishUsers";

export default function NourishSite({siteID}) {

  const userID = "adc";
  const [site, setSite] = useState(null);
  const [apiKey, setAPIKey] = useState("");
  const [error, setError] = useState("");
  const [status, setStatus] = useState("Loading Nourish...");

  useEffect(()=>{
    if (site!==null) setSite(null);
    if ((siteID===null)||(siteID==="")) return;
    getSiteData();
  }, [siteID]);

  useEffect(()=>{
    //update api key
    console.log("apiKeyCHANGED: ", apiKey);
    if (apiKey==="") return;
    const postData = { 
      "userID": userID,
      "siteKey": apiKey,
      "siteID": `${siteID}`
    };
    console.log("apiKey: ", postData);
    const postUrl = "/integrations/nourish/update_site_key";
    apiPost( postUrl, postData, 
      (d)=>{
        console.log("APIKEY: ", d);
        getSiteData();
        /*
        console.log("d: ", d);
        fetch('http://localhost:8181/dev/startmeup')
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        */
      }, 
      (e)=>{
        
        console.log("Error: ", e);
        setError(e.toString())

      }
    );

  }, [apiKey]);

  function getSiteData(){
    apiPost(
      "/integrations/nourish/site_config",
      {
        userID: userID,
        siteID: siteID,
      },
      (d)=>{ try { 

        console.log("Nourishsss: ", d);
        setSite(d[0]);
        setStatus("");
      } catch(e) { setSite(null) } },
      (e)=>{ console.log(e) }
    );
  }


  function getKey(){
    apiPost(
      "/tools/generate_api_key",
      {"userID": userID}, 
      (d)=>{
        console.log("Generate API key: ", d);
        setAPIKey((d===null) ? "" : d)
      }, 
      (e)=>setError(e.toString())
    );
  }

  function addNourish(){
    setStatus("Adding Nourish...");
    getKey();
  }

  const AddNourishOption=()=>{
    return (
      <div className="flex-row align-items-center">
        <button className="button-3" onClick={()=>addNourish()} >+ Add Nourish</button>
        <Text className="margin-left-10">Setup configuration for your Nourish account.</Text>
      </div>
    )
  };

  const NourishKeySetup=()=> {
    
    const buttonText = "Generate new API Key";
    const KeyButton = ()=> <button className="button-3" onClick={()=>getKey()} >{buttonText}</button>;

    return (
      <div className="flex-column">
        <div>Nourish</div>
        <br />
        <div className="integration_infomation">
          <Card>
            <div className="card_title">API Details</div>
            <p>API key: &nbsp; &nbsp;  {site.nourishKey}</p>
            <p>Status: {(site.nourishStatus===1) ? "Enabled" : "Disabled"}</p>
            <br />
            {
            (site.nourishStatus===1) 
            ? <></> 
            : <><p>An API key has been generated and will need adding to the configuration in Nourish to complete the integration.  Once this is complete and we have confirmation from Nourish your integration will automatically be enabled.</p><br /></>
            }
            <br />
            <Divider />
            <p>Click below to generate a new API key and repeat the process.<br /><KeyButton /></p>
          </Card>
          <br />
          {/*
          <Card>
            <div className="card_title">Residents</div>
            {
            (site.nourishStatus===1) 
            ? <NourishUsers siteID={siteID} /> 
            : <><p>An API key has been generated and will need adding to the configuration in Nourish to complete the integration.  Once this is complete and we have confirmation from Nourish your integration will automatically be enabled.</p><br /></>
            }
          </Card>
          */}
        </div>
      </div>
    );
  }
  
  return (
    <main className="px-12 py-12">
      <main>
        <Card>
          {(status!=="") ? <div>{status}</div> :((site===null)||(site===undefined)) ? <AddNourishOption /> : <NourishKeySetup />}
        </Card>
      </main>      
    </main>
  );

  


/*


  function updateCallBack(){
    getSiteData(); 
  }

  if (site===null) return<></>;

*/
}

/*
const SiteAPI=({siteID, siteDetails, updatedCallBack, userID})=> {
  


 
  
  


  return(
    <>

      
        
      
      
    </>
  )

}
*/