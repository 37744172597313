import './careclicksfield.css';
import IMG_PCARE from '../../images/care_clicks/personal_care.png';
import IMG_CONT from '../../images/care_clicks/continence.png';
import IMG_NUT from '../../images/care_clicks/nutrition_hydration.png';
import IMG_SLP from '../../images/care_clicks/sleep_rest.png';
import IMG_SOC from '../../images/care_clicks/social.png';
import IMG_MOV from '../../images/care_clicks/moving.png';
import IMG_TOGGLE_NEG from '../../images/care_clicks/toggle_negative.png';


const CareClicksField = (props) => {

    console.log("CareClicksField props: ", props);

    const GetDisplayIcon=({arr})=>{
        //remove duplicates from arr
        arr = arr.filter(function(item, pos) {
            return arr.indexOf(item) == pos;
        })
        return arr.map(a=><GetRTNVal value={a} />);
    }

    const GetRTNVal=({value})=>{
        
        if (value==="personal") return <BuildCareIcon tooltip="Personal Care" alt="Personal Care" src={IMG_PCARE} />;
        if (value==="continence") return <BuildCareIcon tooltip="Continence" alt="Continence" src={IMG_CONT} />;
        if (value==="nutandhyd") return <BuildCareIcon tooltip="Nutrition and Hydration" alt="Nutrition and Hydration" src={IMG_NUT} />;
        if (value==="sleep") return <BuildCareIcon tooltip="Sleep" alt="Sleep" src={IMG_SLP} />;
        if (value==="social") return <BuildCareIcon tooltip="Social" alt="Social" src={IMG_SOC} />;
        if (value==="moving") return <BuildCareIcon tooltip="Moving" alt="Moving" src={IMG_MOV} />;
        
        return <CareComment 
            tooltip="Care Comment" 
            alt="Care Comment" 
            src={IMG_TOGGLE_NEG} 
            comment={value} />;
                
    }
    console.log("props.careClicks: ", props.careClicks);
    if(!props.careClicks) return null;
    
    return(
        <div style={{display:"flex", flexDirection: "row", flexWrap: "wrap"}}>
            {
            Object.keys(props.careClicks).map((c,i)=>{
                console.log("c: ", c);
                return <GetRTNVal value={(props.careClicks[c].CallType==="Comments")? props.careClicks[c].CallValue : props.careClicks[c].CallType} key={i} />
            })
            }
        </div>
    );
}


const BuildCareIcon = (props) => {
    
    return (
        <div className="caretooltip">
            <img className="care_selected" alt={props.tooltip} src={props.src} />
            <span className="caretooltiptext">{props.tooltip}</span>
        </div>
    );
}

const CareComment = (props) => {
    let comment = props;

    return (
        <div className="caretooltip_comment">
            <div className="dashboard_careclick_comment_icon">i</div>
            {
            //<img className="dashboard_careclick_comment_icon" height="25px" alt={props.tooltip} src={props.src} />
            }
            <div className="caretooltiptext_comment">
                <div className="caretooltiptext_comment_title">Comment</div>
                <div className="caretooltiptext_comment_text">{props.comment}</div>
            </div>
        </div>
    );
}

export default CareClicksField;
