import { useEffect, useState } from "react";
import { apiGet } from "../../data/apidata"
import MoveFloorPlan from "../../components/MoveFloorPlan/MoveFloorPlan";

export const IMAP=({siteCode, moveSiteID})=>{

    const [data, setData] = useState(null);
    
    function onData(site){
        setData(site);
    }

    useEffect(()=>{
        
        //const mainUrl = `https://dash.iiwari.cloud/api/v1/sites/${moveSiteID}?token=c78CVJQ2ahY0xTATR2TguFf5rJbHQGTQX21puxGLMIqy4XBjzq8bcmZr8U8y8fvzNda5FagohQQ6Yh9tbv`;
        const mainUrl = `https://app.iiwari.cloud/sites/${moveSiteID}?token=c78CVJQ2ahY0xTATR2TguFf5rJbHQGTQX21puxGLMIqy4XBjzq8bcmZr8U8y8fvzNda5FagohQQ6Yh9tbv`;
        apiGet(mainUrl, onData, ()=>{});

    }, [moveSiteID]);

    if (data===null) return <>Loading data...</>;
    return (
    <div>
        <MoveFloorPlan siteData={data} siteCode={siteCode} />
            <br />
            <br />
            <br />
            <br />
    </div>)
    
}

