import React, { useEffect, useState } from 'react';
import { select, drag, selectAll } from 'd3';
import { lCase } from '../../functions/string';
import './MoveFloorPlan.css';
import { AQModal } from '../Modal/Modal';
import RoomUnitSingle from '../../Pages/VirtualEnvironment/RoomUnit';
import { set } from 'firebase/database';
import MoveRoomConfig from './MoveRoomConfig';
import { getOnValue } from '../../data/firebase';
import { hasRoomUnitHasMoveConfig } from '../../functions/dataHandler';

const MoveFloorPlan=({siteCode, siteData})=>{
  console.log({siteData});
  const [units, setUnits] = useState([]);
  const [freeUnits, setFreeUnits] = useState([]);
  const size = 500;
  const [configureRoom, setConfigureRoom] = useState(null);
  const [floor, setFloor] = useState("");
  const [floorList, setFloorList] = useState([]);

  function resetConfigurationRoom(){ setConfigureRoom(null) }

  useEffect(()=>{
    if ((siteData===null)||(siteData===undefined)) return;
    setFloorList(siteData.floors.map(f=>f.name));
  },[siteData]);

  useEffect(()=>{
    if (floorList.length===0) return;
    if (floor==="") setFloor(floorList[0]);
  },[floorList]);

  useEffect(()=>{ if (floor!=="") buildRooms(floor); }, [floor, units]);
  
  function configData(data){ setUnits(data); }

  function configError(error){ console.log(error); }

  function buildConfig(){ 
    console.log("SIIIITECODE::" + siteCode);
    getOnValue(siteCode + "config", configData, configError); 
  }
  
  useEffect(()=>{ buildConfig(); },[siteCode, siteData])

  useEffect(()=>{ setFreeUnits(units.filter(u=>((u.move_id===undefined)||(u.move_id==="")))) },[units])
  
  function scaleValue(val){ return val/4; }
  
  function flipScale(val){ return 50 + scaleValue(val); /* return size - scaleValue(val); */ }
    
  function getFillColor(val){
    if (val===101) return "pink" 
    //"#4cc1bd"
    return "white";
  }

  const drawRoomObject=(svg, zone)=>{
    const polygonData = zone.corners;
    const g = svg.append("g")
    
    const polygon = g.append("polygon")
      .attr("points", polygonData.map(d => `${scaleValue(d.x)},${flipScale(d.y)}`).join(" "))
      //.attr("fill", (hasRoomUnitHasMoveConfig(units, zone.id)===true) ? "pink" : "#4cc1bd")
      .attr("fill", getFillColor(zone.type))
      .attr("pointer-events", "visible")
      .on("click", ()=>{setConfigureRoom(zone)})
      .attr("stroke", "black");
    
    const [x, y] = getCenter(polygonData);
    drawText(g, scaleValue(x) , flipScale(y), zone.name);

  }
  

  function getMiddle(arr){ return arr.reduce((a,b) => a + b, 0) / arr.length; }


  function getCenter(polyData){ return [getMiddle(polyData.map(p=>p.x)), getMiddle(polyData.map(p=>p.y))]; }


  const drawText=(obj, x, y, text, id)=>{
    let txt = obj.append("text")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", ".35em")
        .attr("id", id)
        .attr("text-anchor", "middle")
        .attr('pointer-events', 'none')
        .style("fill", "white")
        .text(text);
  }

  function buildRooms(selectedRoom){
    
    const svg = select('.move-floor-plan-svg');
    svg.selectAll('*').remove();
 
    const floor = siteData.floors.filter(f=>f.name===selectedRoom)[0];

    const rim = floor.zones.filter(z=>z.type===2);
    const rms = floor.zones.filter(z=>z.type!==2);

    rim.forEach((zone, count)=>{
      const polygonData = zone.corners;
      const g = svg.append("g")
      
      const polygon = g.append("polygon")
        .attr("points", polygonData.map(d => `${scaleValue(d.x)},${flipScale(d.y)}`).join(" "))
        .attr("fill", getFillColor(zone.type))
        .attr("pointer-events", "visible")
        .attr("stroke", "black");
      
      const [x, y] = getCenter(polygonData);
      drawText(g, scaleValue(x) , flipScale(y), zone.name);
    });

    rms.forEach((zone, count)=>{
      if ([3].includes(zone.type)) return;
      console.log({zone});
      drawRoomObject(svg, zone);
    });
  }
  

  return (
    <div className="move-floor-plan-container">
      {//(configureRoom!==null)&&<AQModal show={true} onClose={resetConfigurationRoom}><MoveRoomConfig siteID={siteCode} freeUnits={units} moveConfig={configureRoom} /></AQModal>
      }
      <div className='details'>
        {floorList.map(f=><button className='basic m-1' onClick={()=>setFloor(f)} >{f}</button>)}
      </div>
      <svg className="move-floor-plan-svg" />
    </div>
  );
}

export default MoveFloorPlan;



