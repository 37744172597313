import { apiPost } from "./apidata";
import { setValue } from "./firebase";

export function dataRefresh(data, onData, onError){
    apiPost("/datacollector/collect", data, (d)=>onData(d), (e)=>onError(e));
}

export function setRoomConfigMoveValue(site, recordID, value, onSucess, onError){
    if (site==="") onError("No Site ID set.");
    if (recordID==="") onError("No Record ID set.");
    console.log(`${site}config/${recordID}/move_id`);
    setValue(`${site}config/${recordID}/move_id`, value, onSucess, onError);
}



