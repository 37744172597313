import React from 'react';

class ExporterExcel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        { name: 'John', age: 30, city: 'New York' },
        { name: 'Alice', age: 25, city: 'Los Angeles' },
        { name: 'Bob', age: 35, city: 'Chicago' },
      ],
    };
  }

  // Function to generate and export an Excel file
  generateExcelFile = () => {
    const { data } = this.state;

    // Create a new Workbook
    const workbook = {
      Sheets: {
        'Sheet1': [
          { name: 'Name', age: 'Age', city: 'City' }, // Headers
          ...data, // Data
        ],
      },
      SheetNames: ['Sheet1'],
    };

    // Create a Blob from the workbook
    const excelBlob = this.workbookToBlob(workbook);

    // Create a URL for the Blob
    const url = URL.createObjectURL(excelBlob);

    // Create an anchor element to trigger the download
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'exported-data.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  // Helper function to convert a workbook object to a Blob
    workbookToBlob = (workbook) => {
        //const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'blob' });
        //return new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        return null;
    };

  render() {
    return (
      <div>
        <button onClick={this.generateExcelFile}>Export to Excel</button>
      </div>
    );
  }
}

export default ExporterExcel;
