import { useEffect, useState } from "react";
import DashboardCard from "./Card";
import { Grid } from "@tremor/react";
import { placeKPICards } from "../functions/apiActions";
import { set } from "firebase/database";
import SpinnerLoader from "./SpinnerLoader";

export const PlaceKPIData = ({ postFilters }) => {
  
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) setIsLoading(true);
    placeKPICards(postFilters, setData);
  }, [postFilters]);

  function generateKey(val){ return val+ new Date().getMilliseconds().toString(); }

  useEffect(() => {setIsLoading(false);}, [data]);
  
  return (
    <Grid numItemsLg={7} className="mt-6 gap-6">
      {
      (isLoading)
      ? <SpinnerLoader isLoading={isLoading} />
      : (data!==null) && Object.values(data).map((item, c) => <DashboardCard key={generateKey(`${c}placeitem`)} postFilters={postFilters} item={item} />)
      }
    </Grid>
  );
};


