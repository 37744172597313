
import { useEffect, useState } from "react";
import { Grid,  Tab,  TabList,  TabGroup,  TabPanel,  TabPanels, DateRangePicker, DateRangePickerValue } from "@tremor/react";
import { MultiSelect, MultiSelectItem, Flex } from "@tremor/react";
import ChartCard from "../components/ChartCart";
import EmergencyVNon from "../components/charts/EmergencyVNon";
import AverageResponseTime from "../components/charts/AverageResponseTime";
import AverageDurationTime from "../components/charts/AverageDurationTime";
import AverageDurationTimeByZone from "../components/charts/AverageDurationTimeByZone";
import AverageResponseTimeByZone from "../components/charts/AverageResponseTimeByZone";
import CareDeliveredByType from "../components/charts/CareDeliveredByType";
import TotalCallsByType from "../components/charts/TotalCallsByType";
import { apiPost } from "../data/apidata";
import { groupBy } from "../functions/objects_arrays";
import { displayDateFromUTC, getHoursBetweenDates, getEndOfDay, getStartOfDay } from "../functions/dataHandler";
import { PlaceKPIData } from "../components/PlaceKPIData";
import TotalCallsByRoom from "../components/charts/TotalCallsByRoom";
import TotalCallsByCarer from "../components/charts/TotalCallsByRoomCarer";
import { dataRefresh } from "../data/dbHelper";
import { AQTag } from "../AQTag";
import { Icon_refresh_circle } from "../components/svgIcons";
import ProactiveVReactive from "../components/charts/ProactiveVReactive";

export default function PlaceChart({postFilters}) {

  function getScaleType(postFilters){
    const diff = getHoursBetweenDates(postFilters.startDate, postFilters.endDate);
    try {
      return (diff.length>=72) ? "day" : "hour";
    } 
    catch (e) { return "day";}
  }

  const scaleType = getScaleType(postFilters);

  return (
    <>
    <PlaceKPIData postFilters={postFilters} />

        <Grid numItemsLg={1} className="mt-6 gap-6">
            <ChartCard postFilters={postFilters} scaleType={scaleType} />
        </Grid>
 
 
      <Grid numItemsLg={3} className="mt-6 gap-6">
        <AverageResponseTime postFilters={postFilters} />

        <AverageResponseTimeByZone postFilters={postFilters} />

        <CareDeliveredByType postFilters={postFilters} />

        <TotalCallsByType postFilters={postFilters} />
        <TotalCallsByRoom postFilters={postFilters} />
        <TotalCallsByCarer postFilters={postFilters} />
        <EmergencyVNon postFilters={postFilters} />
        <ProactiveVReactive postFilters={postFilters} />

      </Grid>

    </>
  );

}

/*
  component to show a horizontal bar chart of the values.
  this will be an array of objects with the attributes title and object and the bar chart will be animated.
*/
const HorizontalBarChart=({arr})=>{
  
  return(
    <div className="w-full h-96 bg-gray-100 rounded-md">
      <h1 className="text-center text-2xl font-bold">Horizontal Bar Chart</h1>
      {
        arr.map((item,i)=>{
          return(
            <div key={i} className="w-full h-20 bg-gray-200 rounded-md flex items-center justify-center">
              <h1 className="text-center text-2xl font-bold w-27">{item.title}</h1>
            </div>
          )
        })
      }
    </div>
  )
}