
import { useEffect, useState } from "react";
import { Grid} from "@tremor/react";
import LiveDashboardToday from "../components/charts/LiveDashboardToday";
import LiveDashboardLiveCalls from "../components/charts/LiveDashboardLiveCalls";
import SenseLive from "../components/charts/SenseLive";
import VirtualPanel from "./VirtualEnvironment/VirtualPanel";

export default function Live({siteCode, senseCode}) {

  return (
    <main className="px-3 py-3">
      
      <div
        className="main_demo_grid"
      >
        <LiveDashboardToday siteCode={siteCode} />
        <div
          className="split_handler"
        >
            <LiveDashboardLiveCalls sitesList={[siteCode]} />
            <SenseLive sitesList={[senseCode]} />
        </div>
        <VirtualPanel siteCode={siteCode} />
      </div>

    </main>
  );
}

