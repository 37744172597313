import {
    Button,
    Callout,
    Card,
    Flex,
  } from "@tremor/react";
import IMG_LOGO from '../../images/logo_white_background.jpg';
import { ArrowNarrowRightIcon, TrendingUpIcon } from "@heroicons/react/solid";
import { TextInput } from "@tremor/react";
import { useState } from "react";
import { ExclamationIcon } from "@heroicons/react/solid";
import { auth, login } from '../../data/firebase';
import useLocalStorage from '../../hooks/useLocalStorage';
import useInput from "../../hooks/useInput";
import useToggle from "../../hooks/useToggle";
import { useAuth } from "../../context/AuthProvider";
import { apiPost } from "../../data/apidata";
import { useUserContext } from "../../context/UserContext";
import getUserDetailsFromAPI from "./GetUserDetails";

  export default function LoginScreen() {

    const auth = useAuth();
    const { setUserSettings, setPermissions, setSites } = useUserContext();

    const [formData, setFormData] = useState({

        username: "",
        password: "",
        error: ""

    })

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const setError = (error) => {
        setFormData((prevFormData) => ({ ...prevFormData, ["error"]: error.toString() }));
    };

    function translateError(error){
        if (error==="FirebaseError: Firebase: Error (auth/network-request-failed).") return "Unable to Authenticate.  Please check you can access the Internet and report to Arquella if the problem continues.";
        if (error==="FirebaseError: Firebase: Error (auth/invalid-email).") return "The username is not a valid email address.";
        if (error==="FirebaseError: Firebase: Error (auth/user-not-found).") return "We cannot find a user with this username.";
        if (error==="FirebaseError: Firebase: Error (auth/wrong-password).") return "Invalid password.";
        return error;
    }

    const ErrorCard=({err})=>{
        return(
            <Callout
            id="login_error"
            title="Unable to login"
            icon={ExclamationIcon}
            color="rose"
            className="mt-6"
          >
            {translateError(formData.error)}
          </Callout>
        );
    }
    
    async function getUserDetails(u) {
      let token = null;
      try { token = u.user.accessToken;} catch(e){console.log(e)}
      if (token===null) try { token = u.user._tokenResponse;} catch(e){console.log(e)}
      if (token===null) return;
      
      await getUserDetailsFromAPI(formData.username, token)
              .then((d)=>{
                console.log("from api");
                auth.login(d);
                console.log("from api");
                console.log({d});
                setUserSettings({
                  "username" : d.username,
                  "displayName" : d.displayName,
                  "token" : token,
                  "auth_stamp": d.auth_stamp
                });

                setPermissions(d.permissions);
                setSites(d.sites);

              })
              .catch(e=>setError(e));

    }


    function loginClick(){

      setError("");

      login(formData.username, formData.password)
        .then((u)=>{ getUserDetails(u); }) 
        .catch(e=>{ setError(e.toString()); });
        
    }

    return (
    <>

      <br />
      <br />
      <br />

      <Card className="max-w-md mx-auto">
        <img src={IMG_LOGO} 
            alt="Arquella Connect Login Screen"
            className='login_logo' 
        />
        <TextInput 
        //    icon={SearchIcon} 
            id="username"
            name="username"
            type="email"
            onChange={handleChange}
            placeholder="Username..." 
        />
        <TextInput 
        //    icon={SearchIcon} 
            id="password"
            name="password"
            onChange={handleChange}
            type="password"
            placeholder="Password..." 
        />

        {(formData.error!="")&&<ErrorCard err={formData.error} />}
        
        <Flex className="mt-6 pt-4 border-t">
          <Button 
          size="xs" 
          variant="light" 
          onClick={()=>loginClick()}
          id="user_login_button"
          //icon={ArrowNarrowRightIcon} 
          //iconPosition="right"
          >Login</Button>
{/*
          <Button 
          size="xs" 
          variant="light" 
          //icon={ArrowNarrowRightIcon} 
          //iconPosition="right"
          >Signup</Button>
*/}
        </Flex>
      </Card>
    </>
  );
}