import React, { useEffect, useState } from 'react';
import { Card, Grid, Divider, Flex, Text } from '@tremor/react';
import { useParams } from 'react-router-dom';
import { BasicDetails } from './BasicDetails';
import { FormInput } from './components/Input';
import { Description } from './Description';
import { ResidentTags } from './ResidentTags';
import TagManager from './TagManager';
import SpinnerLoader from './components/SpinnerLoader';
import { set } from 'firebase/database';
import { BasicIntegrationDetails } from './BasicIntegrationDetails';
import { IntegrationDetails } from './IntegrationDetails';
import {addIntegrationUnit} from './functions/apiActions';
  

export const ResidentScreen=({id, residentObject, siteID})=> {
  
  const [resID, setResID] = useState(id);
  const [resName, setResName] = useState(id);

  useEffect(()=>{ 
    if (id!==resID) setResID(id); 
  }, [id]);
  
  console.log("RESIDENT SCREEN", resID);

  const isIntegrationUser = ((residentObject) && (residentObject.source!=="Connect"));

  function assignmentChange(e){
    console.log(residentObject);
    const data = {
      "id": residentObject.id,
      "siteID": siteID,
      "dob": residentObject.dob,
      "nhsNumber": residentObject.nhsNumber,
      "roomUnitID": e.target.value
    }

    addIntegrationUnit(data, (d)=>{
      console.log(d);
    }, (e)=>{
      console.log(e);  
    });

  }

  return (
    <div 
    //className="mt-1 p-8 bg-white shadow-md rounded-lg"
    >
      
      <Grid numItems={1} numItemsSm={1} numItemsLg={2} className="gap-1">
        {
        (isIntegrationUser)
        ? <BasicIntegrationDetails residentObject={residentObject} callBack={setResID} nameCallBack={setResName} />
        : <BasicDetails resID={resID} callBack={setResID} nameCallBack={setResName} />
        }
        <div></div>
      </Grid>
      
      <Divider />
      
      {(isIntegrationUser) && <IntegrationDetails residentObject={residentObject} />}  

      {//((resID!==undefined)&&(resID!="-1")) && <Description resID={resID} />
      }
      
      <Divider />

      {
        (isIntegrationUser) &&
        <div className='flex-row'>
          <div>Assign room unit &nbsp;&nbsp;&nbsp;&nbsp;</div>
          <select onChange={(e)=>assignmentChange(e)}>
            <option value="">Select a room unit</option>
            <option value="001">Bedroom 1</option>
            <option value="101">En Suite 1</option>
          </select>

        </div>


      }

      {//((resID!==undefined)&&(resID!="-1")) && <TagManager />
      }
  
      <Divider />
  
      {//((resID!==undefined)&&(resID!="-1"))  && <ResidentDetails residentID={resID} />
      }

    </div>
  
  );
}



const AssignRoomUnitsToResident=(residentID)=>{
  return(
    <div 
    //max-w-md mx-auto bg-white p-4 rounded shadow
    class="">
        <h2 class="text-lg font-semibold mb-4">Assigned Room Units</h2>

        
        <ul class="space-y-2">
            <li class="flex justify-between">
                <span>Bedroom 1</span>
                <button class="text-red-500 hover:text-red-700">Remove</button>
            </li>
            <li class="flex justify-between">
                <span>En-suite 1</span>
                <button class="text-red-500 hover:text-red-700">Remove</button>
            </li>
        </ul>
{/*}
        <div class="mt-4">
            <label for="newRoomUnit" class="block text-sm font-medium text-gray-700">Add Room Unit:</label>
            <Grid numItems={1} numItemsSm={1} numItemsLg={2} className="gap-1">
              <select id="newRoomUnit" class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                <option>Select a Room Unit</option>
                <option>Room Unit 3</option>
                <option>Room Unit 4</option>
              </select>
              <button 
              //class="mt-2 px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-700"
              >Add</button>
            </Grid>

        </div>
  */}
    </div>
  );
}


const ResidentDetails=({residentID})=>{

  const [details, setDetails] = useState(null);
  
  function getDetails(){
  
    setDetails({
      dob: "",
      nhsNumber: ""
    });
    
    
    
    /*
    console.log(site);
    if (site.id!==-1) return;
    addNewSite(site, (d)=>{
      console.log("Site return", d);
      if (parseInt(d).toString()==="NaN") {
        return;
      }
      else {
        setSite((prevState) => ({ ...prevState, id: d }));
      }
    }
    );
    */

  }
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDetails((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //addSite();
    //console.log(site);
  };
  
  useEffect(()=>{ getDetails(); },[residentID]  );

  if ((details===null)||(details===undefined)) return <SpinnerLoader isLoading={true} />;
 

  return (
    <div className="mt-6">
      <Card>
        <Text>Personal Details</Text>
        <form onSubmit={handleSubmit}>
        
          <Flex flexDirection="row" alignItems="end">
            <Text>Site Name:</Text>
            <FormInput id="dob" name="dob" value={details.dob} onChange={handleInputChange} placeholder={"Date of birth"} />
          </Flex>

          <Flex flexDirection="row" alignItems="end">
            <Text>Site Code:</Text>
            <FormInput id="nhsNumber" name="nhsNumber" value={details.nhsNumber} onChange={handleInputChange} placeholder={"NHS Number"} />
          </Flex>

          <button type="submit">Update</button>

        </form>

      </Card>

      

    </div>

  );

  return(
    <Grid numItems={1} numItemsSm={1} numItemsLg={2} className="gap-1">
        
        <div></div>
   
   
    </Grid>
   
  )

}