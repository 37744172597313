
import { useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import DashboardCard from "../components/Card";
import { Grid,  Tab,  TabList,  TabGroup,  TabPanel,  TabPanels, DateRangePicker, DateRangePickerValue } from "@tremor/react";
import ChartCard from "../components/ChartCart";
import TableDataCard from "../components/TableDataCard";
import BarChartCard from "../components/BarChart";
import LineChartCard from "../components/LineChartCard";
import TotalCallsPerDay from "../components/charts/TotalCallsByDay";
import EmergencyVNon from "../components/charts/EmergencyVNon";
import AverageResponseTime from "../components/charts/AverageResponseTime";
import AverageResponseTimeByZone from "../components/charts/AverageResponseTimeByZone";
import CareDeliveredByType from "../components/charts/CareDeliveredByType";
import CallsByType from "../components/charts/CallsByType";
import CallsByResident from "../components/charts/CallsByResident";
import CallJourneyList from "../components/charts/CallJourneyList";
import Residents from "./Residents";



export default function ShowRoom() {
  
  const [value, setValue] = useState({
    from: new Date(2023, 1, 1),
    to: new Date(),
  });


  return (
    <main className="px-12 py-12">

      <DateRangePicker
        className="max-w-md mx-auto"
        value={value}
        onValueChange={setValue}
        selectPlaceholder="Selection"
      />

      <TabGroup className="mt-6">
        <TabList>
          <Tab>Dashboard</Tab>
          <Tab>Carer</Tab>
          <Tab>Resident</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid numItemsLg={4} className="mt-6 gap-6">
              {kpiData.map((item) => <DashboardCard item={item} /> )}
            </Grid>
            <Grid numItemsLg={3} className="mt-6 gap-6">
              <ChartCard data={performance} />
              <CallsByType />
              <TotalCallsPerDay />
              <EmergencyVNon />
              <AverageResponseTime />
              <AverageResponseTimeByZone />
              <CareDeliveredByType />
            </Grid>

            <div className="mt-6 gap-6">
            </div>
  
          </TabPanel>


          <TabPanel>
            <div className="mt-6">
              Carer
            </div>
          </TabPanel>
          
          <TabPanel>
            <div className="mt-6">
              <Residents />
            </div>
          </TabPanel>

          <TabPanel>

          </TabPanel>
        </TabPanels>
      </TabGroup>
    </main>
  );
}

/*

              <ChartCard data={performance} />
              <BarChartCard />
              <LineChartCard />

*/

const kpiData = [
  {
    title: "Total Calls",
    metric: "67",
    progress: 15.9,
    target: "30",
    delta: "31",
    deltaType: "moderateIncrease",
  },
  {
    title: "Sense Calls",
    metric: "13",
    progress: 13,
    target: "100",
    delta: "4%",
    deltaType: "increase",
  },
  {
    title: "Move",
    metric: "1,072",
    progress: 53.6,
    target: "2,000",
    delta: "10.1%",
    deltaType: "moderateDecrease",
  },
  {
    title: "Care Hours",
    metric: "18",
    progress: 55,
    target: "30",
    delta: "55%",
    deltaType: "moderateDecrease",
  },
];


export const performance = [
  {
    date: "2023-05-01",
    Sales: 900.73,
    Profit: 173,
    Customers: 73,
  },
  {
    date: "2023-05-02",
    Sales: 1000.74,
    Profit: 174.6,
    Customers: 74,
  },
  {
    date: "2023-05-03",
    Sales: 1100.93,
    Profit: 293.1,
    Customers: 293,
  },
  {
    date: "2023-05-04",
    Sales: 1200.9,
    Profit: 290.2,
    Customers: 29,
  },
];