import { Card, Title, LineChart } from "@tremor/react";
import { Grid, Accordion, AccordionHeader, AccordionBody, AccordionList } from "@tremor/react";
import { CategoryBar, Flex, Text } from "@tremor/react";
import { useState, useEffect } from "react";
import { groupBy, groupKeysBySubKey }  from '../../functions/objects_arrays';
import { GetCallTypeImage } from "../GetImage";
import { getOnValue } from "../../data/firebase";
import { displayTimeFromUTC, getDifferenceInSeconds, getMinMax, orderArrayByDate, splitTXCode } from "../../functions/dataHandler";
import { JourneyBar } from "./JourneyBar/JourneyBar";
import CareClicksField from "../CareClicksField/CareClicksField";
//import { group } from "d3";

export default function LiveDashboardToday({siteCode}){

  const [data, setData] = useState([]);
  const callsListReactive = ["Emergency", "Sense", "Assistance", "Call"];
  const callsListProactive = [ "Attendance", "CareCall" ];
  const [error, setError] = useState({});
  const [allCallData, setAllCallData] = useState([]);
  const [journeyData, setJourneyData] = useState([]);

  useEffect(()=>{
    console.log("masterdata", data);
    setAllCallData(data.filter(d=>(d.aqRef!==undefined)));
  }, [data]);
  
  function dateBackwardsCompatibility(lst){
    lst = lst.map(d=>{
      if (Object.keys(d).includes("end_date_time_utc")) return d;
      return new Object({
        ...d,
        "end_date_time_utc": d["end_date_time"]
      })

    })
    return lst;
  }

  function processJourneyFromCallsList(journeyID, journeyList){
    
    let orderedCallList = orderArrayByDate(journeyList, "start");
    orderedCallList = dateBackwardsCompatibility(orderedCallList);
    const firstCall = orderedCallList[0];
    console.log("First call", firstCall);
    console.log("Ordered call list", orderedCallList);
      const [start, end] = getMinMax(orderedCallList);
      const [roomCode, callCode] = splitTXCode(firstCall.txCode)
      return {
        AccessoryType : "",
        duration: getDifferenceInSeconds(start, end),
        start: start,
        end: end,
        journeyRef: journeyID,
        room: firstCall.room,
        roomCode: roomCode,
        callType: firstCall.callType,
        callCode: callCode,
        zone: firstCall.zone,
        calls: orderedCallList
      };
  }

  function processCallsList(cData){
    //groups them by Call types and creates a summary for the journey
    const nJourney = groupBy(cData, "journeyRef");
    const journeys = [];
    for (const [key, callList] of Object.entries(nJourney)) {
      journeys.push(processJourneyFromCallsList(key, callList));
    }
    return orderArrayByDate(journeys,"start", true);
  }

  useEffect(()=>{
    console.log("allCallData", allCallData);
    setJourneyData(processCallsList(allCallData));
  }, [allCallData]);

  function doubleFigure(val){
    if (val<10) return "0" + val;
    return val;
  }

  function getDatePathFromDate(d){
    return `${doubleFigure(d.getDate())}${doubleFigure(d.getMonth() + 1)}${doubleFigure(d.getFullYear())}`;
  }

  useEffect(()=>{
    if ((siteCode===undefined)||(siteCode===null)||(siteCode === "")) return "";
    console.log("siteCode", siteCode + "_site/calls/" + getDatePathFromDate(new Date()));
    getOnValue(siteCode + "_site/calls/" + getDatePathFromDate(new Date()), 
    (d)=>{
      try { setData(Object.values(d)); }
        catch(e){ 
          console.log("Error setting records");
          setData([]);
        }
    }, setError);
  },[siteCode])


  function getCallData(key){
    //let rtn = callData[key];
    let rtn = journeyData.filter(j=>j.callType===key);
    if (rtn===undefined) rtn = [];
    return rtn;
  }

  function translateCallTypes(ct){
    if (ct === "Sense") return "Accessory";
    return ct;
  }

  return(

  <Card 
    //className="w-2xl"
  >
    <Title className="upper_case">Today's activity</Title>
    <br />
    <AccordionList className="max-w-md mx-auto">
      <GetTileAccordion title="All Calls" data={journeyData} classVal="all" />
    </AccordionList>

    <Text className="mt-4 mb-1 uppercase">Reactive</Text>
    <AccordionList className="max-w-md mx-auto">
    {
    callsListReactive.map((c)=><GetTileAccordion 
                                title={c} 
                                data={getCallData(translateCallTypes(c))} 
                                classVal={cleanCallTypeText(c)} />)
    }
    </AccordionList>

    <Text className="mt-4 mb-1 uppercase">Proactive</Text>

    <AccordionList className="max-w-md mx-auto">
    {
      callsListProactive.map((c)=><GetTileAccordion 
                                title={c} 
                                data={getCallData(translateCallTypes(c))} 
                                classVal={cleanCallTypeText(c)} />)
    }
    </AccordionList>
  
  </Card>

)};


const GetTileAccordion=({title, data, classVal })=>{
  return (
    <Accordion className={`overflow-visible bg_${classVal}`}>
    <AccordionHeader>
      <DashboardHeader value={title} data={data} />
    </AccordionHeader>
    <AccordionBody>
      <ShowDataTable data={data} />
    </AccordionBody>
  </Accordion>
  );
}


function dateDiffDuration(start, end){
  return new Date(end) - new Date(start);
}

function calculateAverage(count, value){
  const seconds = Math.floor(value/count);
  const result = new Date(seconds).toISOString().slice(11, 19);
  return result;
}
function getAverageOfArray(arr){
  let tot = 0;
  let count = 0;
  arr.forEach((e)=>{ 
    if (!isNaN(e)) {
      tot += e;
      count++;
    } 
  })
  return calculateAverage(count, tot);
}

function getAverageDuration(data, startField, endField){
  if (!data) return "00:00:00";
  if (data.length===undefined) return "00:00:00";
  if (data.length===0) return "00:00:00";
  if (data===null) return "00:00:00";
  const durationList = data.map(d=>(dateDiffDuration(d[startField], d[endField])));
  return getAverageOfArray(durationList);
}



function DashboardHeader({data, value}){

return (
  <div className="dashboard_calls_header">
    <GetCallTypeImage imgString={value} />
    <div>{value}</div>
    <div>{getAverageDuration(data, "start", "end")}</div>
    <div>{data.length}</div>
  </div>
);

}

function cleanCallTypeText(ccString){
  ccString = ccString.toLowerCase();
  ccString = ccString.replace(" ", "");
  return ccString;
}


function ShowDataTable({data}){
  if (data===undefined) return (<>No data</>);
  return <ShowJourneyGraphs data={data} />;
}


function ShowJourneyGraphs({data}){
  return (
    <div>
      {data.map(d=><JourneyCard data={d} />)}
    </div>
  );
}


function JourneyCard({data}){
  const careData = data.calls.filter(d=>Object.keys(d).includes("Care Delivered"));
  return (
    <Card className="max-w-sm mx-auto">
      <Flex>
      
        <Text>{data.room} ({data.zone})</Text>
        <Text>{displayTimeFromUTC(data.start)}</Text>
      
      </Flex>
      <div className="flex">
        <JourneyBar journey={data} />
        <div style={{maxWidth:"110px", minWidth:"100px"}}>
          {(careData.length>0)&&<CareClicksField careClicks={careData[0]["Care Delivered"]} />}
        </div>
      </div>
      

    </Card>
  );
}


